import universalCookies, { Cookie, CookieSetOptions } from 'universal-cookie';
import { isElectron, electronCookies, isProd } from './electron';
import { CookiesSetDetails } from 'electron';
import { server as url } from './requests';
import { StringDictionary } from '../interfaces';

const windowCookies = new universalCookies();

export default class Cookies {

	getAll(): Promise<StringDictionary> {
		if (electronCookies && (isElectron || isProd)) {
			return (electronCookies.get({}) as Promise<Electron.Cookie[]>)
				.then((arr: Cookie[]) => arr.reduce((acc: StringDictionary, curr: Cookie) => {
					acc[curr.name] = curr.value;
					return acc;
				}, {}))
				.catch((e: Error) => {
					console.log(e);
					return {};
				});
		} else return Promise.resolve(windowCookies.getAll() || {});
	}

	get(name: string): Promise<string> {
		if (electronCookies && (isElectron || isProd)) {
			return (electronCookies.get({ name }) as Promise<Electron.Cookie[]>)
				.then((arr: Cookie[]) => arr.map(cookie => cookie.value))
				.then((arr: Cookie[]) => arr[0] || '')
				.catch((e: Error) => {
					console.log(e);
					return '';
				});
		} else return Promise.resolve(windowCookies.get(name) || '');
	}

	set(name: string, value: any, options: CookieSetOptions = {}): Promise<void> {
		let details = Object.assign({}, options) as CookiesSetDetails;
		if (electronCookies && (isElectron || isProd)) {
			let date = new Date(Date.now());	
			details = Object.assign(details, {
				name,
				value,
				url,
				expirationDate: date.setFullYear(date.getFullYear() + 1)
			});
			return (electronCookies.set(details) as any)
				.then(() => electronCookies.get({}))
				.then((arr: Cookie[]) => arr.map(cookie => {
					return {
						name: cookie.name,
						value: cookie.value
					};
				}))
				.catch(console.error);
		} else {
			return Promise.resolve(windowCookies.set(name, value, options));
		}
	}

	remove(name: string, options: CookieSetOptions = {}): Promise<void> {
		if (electronCookies && (isElectron || isProd)) {
			return electronCookies.remove(url, name);			
		} else {
			if (windowCookies.get(name)) {
				return Promise.resolve(windowCookies.remove(name, options));
			} else {
				return Promise.reject('Couldn\'t remove cookie');
			}
		}
	}
}