import React, { useEffect, useState, ReactElement, useCallback, ReactNode, ReactNodeArray, RefObject, useMemo, useReducer, useContext } from 'react';
import cx from 'classnames';
import { FORM, POST, server } from 'utils/requests';
import styles from 'css/components.module.css';
import profileStyles from 'css/profile.module.css';
import { FaTimes, FaCheck, FaCaretDown, FaCaretUp, FaHistory, FaQuestionCircle, FaUndo, FaShare, FaTrashRestore, FaTrashAlt } from 'react-icons/fa';
import Search from './Search';
import url from 'url';
import { defaultSettings, TournamentSettings, TournamentSetting } from 'resources/settings';
import { TournamentStatus } from '../interfaces';
import Player from 'models/player';
import { AlertContext } from '../Contexts';
import useDescription from 'tournament/Rightbar/hooks/useDescription';

interface EditableFieldProps {
    content: string
    defaultEditContent?: string
    defaultContent?: string
    inputType?: 'button' | 'checkbox' | 'color' | 'date' | 'datetime-local' | 'email' | 'file' | 'hidden' | 'image' | 'month' | 'number' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text' | 'textarea' | 'time' | 'url' | 'week',

    url: string, //url.resolve(server, ['tournament', props.id, 'editTournamentName'].join('/'))
    handleSubmit?: (k: string, v: any) => Promise<void>
    onSubmitSuccess?: (e: React.FormEvent<HTMLFormElement>) => void
    handleClear?: () => Promise<void>
	useHandleSubmit?: boolean
    classNames?: { [key: string]: string }
    additionalInputProps?: { [key: string]: any }
    name?: string
    alwaysEnabled?: boolean
    show?: {
        submit?: boolean
        infinity?: boolean
        increment?: boolean
        any?: boolean
		clear?: boolean
		reset?: boolean
    }
    formRef?: RefObject<HTMLFormElement>
	children?: ReactNode | ReactNodeArray
	
	search?: {
		options: { id: string }[]
		getName: (p: { id: string }) => string
	}
}

function roundUpDate(date: Date): Date {
	let interDate = new Date(date.setUTCHours(date.getUTCHours() + 1));
	let nextDate: Date;
	if (interDate.getUTCMinutes() < 30) nextDate = new Date(interDate.setUTCMinutes(30));
	else nextDate = new Date(new Date(interDate.setUTCMinutes(0)).setUTCHours(interDate.getUTCHours() + 1));
	return nextDate;
}

function toTimeDate(date: Date): string {
	if (isNaN(date.valueOf())) return '';
	return date.toLocaleString('en-GB').slice(0, -3);
	//return date.toTimeString().slice(0, 8) + ' ' + date.toDateString().slice(0, 10);
}

function toDatetimeLocal(date: Date) {
	if (isNaN(date.valueOf())) return '';
	let tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
	let localDate = new Date(date.valueOf() - tzoffset);
	return localDate.toISOString().slice(0, 16);
}

export function fromDatetimeLocal(str: string) {
	let [date, time] = str.split(',');
	return [date.split('/').reverse().join('/'), time].join(',');
}

export default function EditableField(props: EditableFieldProps): ReactElement {

	const [content, setContent] = useState(props.content);
	const [editContent, setEditContent] = useState(props.alwaysEnabled || false);

	useEffect(() => {
		if (typeof props.content === undefined || props.content === null) setContent('');
		setContent(props.content);
	}, [props, props.content, setContent]);

	const decrement = useCallback((e: React.MouseEvent<HTMLButtonElement>): void => {
		e.preventDefault();
		let button = e.target as HTMLButtonElement;
		if (button.constructor.name !== 'HTMLButtonElement') return;
		let form = button.parentNode as HTMLFormElement;
		let input = form[props.name || 'name'] as HTMLInputElement;
		input.stepDown();
	}, [props.name]);

	const increment = useCallback((e: React.MouseEvent<HTMLButtonElement>): void => {
		e.preventDefault();
		let button = e.target as HTMLButtonElement;
		if (button.constructor.name !== 'HTMLButtonElement') return;
		let form = button.parentNode as HTMLFormElement;
		let input = form[props.name || 'name'] as HTMLInputElement;
		input.stepUp();
	}, [props.name]);

	const handleClear = useCallback((e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
		e.preventDefault();
		if (!props.handleClear) {
			let button = e.target as HTMLButtonElement;
			let form = button.parentElement as HTMLFormElement;
			if (form.constructor.name !== 'HTMLFormElement') return Promise.resolve(setEditContent(props.alwaysEnabled || false));
			let input = form[props.name || 'name'] as HTMLInputElement;
			if (input.constructor.name !== 'HTMLInputElement') return Promise.resolve(setEditContent(props.alwaysEnabled || false));
			setContent(null);
			input.value = null;
			form[props.name || 'name'] = null;
			e.target = form;
			handleSubmit({ target: form } as any as React.MouseEvent<HTMLFormElement>);
		} else props.handleClear()
			.then(() => {
				setEditContent(props.alwaysEnabled || false);
				setContent('');
			});
	}, [props.handleClear, setEditContent, setContent, props.alwaysEnabled]);

	const handleAny = useCallback((e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
		e.preventDefault();
		return props.handleSubmit(props.name || 'name', 0)
			.then(() => {
				setEditContent(props.alwaysEnabled || false);
				setContent('0');
			});
	}, [props.handleSubmit, setEditContent, setContent, props.name]);

	const handleInfinity = useCallback((e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
		e.preventDefault();
		return props.handleSubmit(props.name || 'name', 'Infinity')
			.then(() => {
				setEditContent(props.alwaysEnabled || false);
				setContent('∞');
			});
	}, [props.handleSubmit, setEditContent, setContent, props.alwaysEnabled, props.name]);

	const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>): Promise<void> => {
		if (e.preventDefault) e.preventDefault();
		if (props.useHandleSubmit) {
			let form = e.target as HTMLFormElement;
			let input: HTMLInputElement;
			if (form.constructor.name === 'HTMLFormElement') {
				input = form[props.name || 'name'] as HTMLInputElement;
			} else
			if (form.constructor.name === 'HTMLInputElement') {
				input = form as any as  HTMLInputElement;
			} else return Promise.resolve(setEditContent(props.alwaysEnabled || false));
			return props.handleSubmit(props.name || 'name', input.value)
				.then(() => {
					setEditContent(props.alwaysEnabled || false);
					return e;
				})
				.then(props.onSubmitSuccess ?? (() => {}))
				.then(() => setContent(input.value))
				.catch((e) => {
					if (e) console.error(e);
				});
		} else return FORM(e)
			.then(() => {
				setEditContent(props.alwaysEnabled || false);
				return e;
			})
			.then((e) => {
				if (props.onSubmitSuccess) props.onSubmitSuccess(e);
				return e;
			})
			.then((e) => {
				let form = e.target as HTMLFormElement;
				while (form.constructor.name !== 'HTMLFormElement' && form.parentElement) form = form.parentElement as HTMLFormElement;
				if (form.constructor.name !== 'HTMLFormElement') throw new Error('Couldn\'t find valid form');
				let input = form.firstChild as HTMLInputElement;
				let content = input.value;
				setContent(content);
			})
			.catch((e) => {
				if (e) console.error(e);
			});
	}, [props.useHandleSubmit, props.handleSubmit, props.name, setEditContent, props.onSubmitSuccess, props.alwaysEnabled, setEditContent, setContent]);

	const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLFormElement>): React.KeyboardEvent<HTMLFormElement> => {
		switch (e.keyCode) {
		case 27: {
			setEditContent(props.alwaysEnabled || false);
			return e;
		}
		case 13: {
			handleSubmit(e);
			return e;
		}
		default: {
			return e;
		}
		}
	}, [setEditContent, handleSubmit, props.alwaysEnabled]);

	function Form ({ name }: { name: string }): JSX.Element {
		let defaultValue: string;
		if (props.inputType === 'datetime-local') {
			if (name) {
				defaultValue = toDatetimeLocal(new Date(name));
			} else {
				defaultValue = roundUpDate(new Date(props.defaultEditContent)).toString();
			}
		} else defaultValue = name;
		if (props.show?.any && defaultValue === '0') defaultValue = '';

		let k = props.name || 'name';

		const buttons = (() => {
			return <>
				<button
					className={cx(styles.button, styles.cancel, {
						[styles.submitHidden]: (() => {
							if (!props.show) return false;
							if (!props.show.submit) return false;
							if (props.show.infinity) return false;
							if (props.show.any) return false;
							if (props.show.clear) return false;
							if (props.show.reset) return false;
							return true;
						})()
					})}
					onClick={() => setEditContent(props.alwaysEnabled || false)}
					title='Cancel'
				>
					<FaTimes />
				</button>
				<button
					className={cx(styles.button, styles.clear, {
						[styles.submitHidden]: !props.show?.clear
					})}
					onClick={handleClear}
					title='Clear'
				>
					<FaTrashAlt />
				</button>
				<button
					className={cx(styles.button, styles.any, {
						[styles.submitHidden]: !props.show?.any
					})}
					onClick={handleAny}
					title='Any'
				>Any</button>
				<button
					className={cx(styles.button, styles.infinity, {
						[styles.submitHidden]: !props.show?.infinity
					})}
					onClick={handleInfinity}
					title='Infinity'
				>∞</button>
				<button
					type='button'
					value='▼'
					className={cx(styles.button, styles.increment, {
						[styles.submitHidden]: !props.show?.increment
					})}
					onClick={decrement}
					title='Decrement'
				>
					<FaCaretDown />
				</button>
				<button
					className={cx(styles.button, styles.increment, {
						[styles.submitHidden]: !props.show?.increment
					})}
					onClick={increment}
					title='Increment'
				>
					<FaCaretUp />
				</button>
				<button
					type='submit'
					className={cx(styles.button, styles.submit, {
						[styles.submitHidden]: !props.show?.reset,
						reset: props.inputType === 'textarea'
					})}
					title='History'
				>
					<FaHistory />
				</button>
				<button
					type='submit'
					className={cx(styles.button, styles.submit, {
						[styles.submitHidden]: !props.show?.submit,
						submit: props.inputType === 'textarea'
					})}
					title='Submit'
				>
					<FaCheck />
				</button>
			</>;
		})();

		if (props.search) return <>
			<div className={styles.shroud} onClick={() => setEditContent(false)}/>
			<div className={styles.searchForm}>
				<Search
					onSubmit={(id: string) => POST({ url: props.url, data: {
						[k]: id,
						...(props.additionalInputProps || {})
					}}).then(() => {
						setEditContent(props.alwaysEnabled || false);
						setContent(id);
					})}
					options={props.search.options}
					getName={props.search.getName}
					placeholder={defaultValue ?? ''}
					showChoices={false}
					showIcon={false}
					styles={styles}
					alwaysHover
				/>
				{buttons}
			</div>
		</>;

		return <>
			<div className={styles.shroud} onClick={() => setEditContent(false)}/>
			<form
				ref={props.formRef}
				className={[styles.form, props.classNames?.form].join(' ')}
				target='_self'
				onKeyDown={handleKeyDown}
				onSubmit={handleSubmit}
				method='POST'
				action={props.url}
			>
				{props.inputType !== 'textarea' ?
					<input
						type={props.inputType}
						className={[styles.input, props.classNames?.input].join(' ')}
						name={props.name || 'name'}
						defaultValue={defaultValue}
						placeholder=' '
						required
						autoFocus
						{...props.additionalInputProps || {}}
					/> :
					<textarea
						className={[styles.input, props.classNames?.input].join(' ')}
						name={props.name || 'name'}
						defaultValue={defaultValue}
						placeholder={'No ' + props.name + ' set'}
						required
						{...props.additionalInputProps || {}}
					/>
				}
				{buttons}
				{props.children}
			</form>
		</>;
	}

	let displayContent = content;
	if (displayContent && props.inputType === 'datetime-local') displayContent = toTimeDate(new Date(content));
	else if (props.show?.any && displayContent === '0') displayContent = 'Any';
	
	return (
		<div className={[styles.container, props.classNames?.container].join(' ')}>
			{editContent ?
				<Form name={content} /> : <>
					<div
						className={[styles.editableField, props.classNames?.editableField || ''].join(' ')}
						onClick={() => setEditContent(true)}
					>
						{displayContent || props.defaultContent || '\u200b'}
					</div>
					{props.children}
				</>
			}
		</div>
	);

}

/* Hook */

const watchProps = Object.values(defaultSettings).reduce((acc, curr) => {
	for (let def of Object.values(curr)) {
		if (def.show) acc.push(...Object.keys(def.show));
	}
	return acc;
}, []) as (keyof TournamentSettings)[];

function modifyHeightValues (): void {
	let headers = Array.from(document.querySelectorAll('.' + [profileStyles.sectionListWrapper].join('.'))) as HTMLDivElement[];
	for (let h of headers) {
		let list = h.firstElementChild as HTMLDivElement;
		let height = h.className.includes(profileStyles.expanded) ? list.getBoundingClientRect().height + 'px' : '0px';
		h.style.height = height;
	}
}

interface HookProps {
	updatePlayerDict?: () => Promise<void>
	updateTournamentSettings: () => Promise<void>
	updateSetting: (k: string, v: any, def?: TournamentSetting<string | number | boolean>) => Promise<void>
	status: TournamentStatus
	settings: TournamentSettings
	triggerError: (message: string) => void
	players: Player[],
	resize?: boolean,
	mount?: boolean,
	isIntro?: boolean
}

export function useEditable(props: HookProps) {

	const handleToggle = useCallback((k: string, v: any, def?: TournamentSetting<string | number | boolean>) => {
		try {
			let value: any;
			if (def?.enum) {
				let index = (def.enum as string[]).indexOf(v);
				if (index === -1) console.error('Invalid setting value ' + k + ': ' + v);
				let setIndex = (index + 1) % def.enum.length;
				value = def.enum[setIndex];
			} else
			if (def?.type === 'boolean') {
				value = !v;
			} else
			if (def?.type === 'number') {

				if (v === 'Infinity') value = Infinity as number;
				else if (v === '∞') value = Infinity as number;
				else v = Number(v) as number;

				if (k === 'totalRounds') {
					if (v <= props.status.round) throw new Error('Can\'t make total number of rounds less than current round number');
					if (v !== Infinity && v >= 100) throw new Error('Can\'t make total number of rounds more than 100');
				}
				
				return v;
			} else value = v;
			props.updateSetting(k, value, def);
		} catch (e) {
			if (e && e.message) props.triggerError(e.message);
		}
	}, [props.status.round, props.updateSetting, props.triggerError]);
	
	const watchedSettings = useMemo(() => Object.entries(props.settings).filter(([k]) => watchProps.includes(k as keyof TournamentSettings)), [props.settings]);
    
	useEffect(() => {
		modifyHeightValues();
	}, [JSON.stringify(watchedSettings)]);

	const { setAlert, closeAlert } = useContext(AlertContext);
	const randomlyPool = useCallback(() => {
		setAlert({
			message: <>
				How many pools should there be? <br />
				<div style={{height: 20, margin: '1em 0'}}>
					<EditableField
						content={'2'}
						url={server + ['tournament', props.status.id, 'randomlyPoolAll'].join('/')}
						name='pools'
						alwaysEnabled
						inputType='number'
						show={{
							increment: true,
							submit: true,
							clear: false,
							reset: false
						}}
						additionalInputProps={{
							min: 1,
							max: props.players.length
						}}
						onSubmitSuccess={() => {
							closeAlert();
							props.updatePlayerDict();
							if (!props.settings.usingPools) props.updateTournamentSettings();
						}}
					/>
				</div>
			</>,
			title: 'Randomly assign pools',
			type: 'action'
		});
	}, [setAlert, props.updatePlayerDict, props.status, closeAlert, props.players]);

	const SettingsRow = useCallback(function SettingsRow ({k, def, i}: {k: keyof TournamentSettings | 'name', def: TournamentSetting<string | number | boolean>, i: number}): ReactElement {

		const { toggleHeight, description } = useDescription({
			content: def.description,
			handleToggle: (id: string) => handleToggle(k, id),
			className: cx(profileStyles.desc, 'scrollable')
		});

		useEffect(() => {
			let poolButton = document.getElementById('poolButton');
			if (!poolButton) return;
			poolButton.innerHTML = 'Randomly assign pools';
			poolButton.addEventListener('click', randomlyPool);
			return () => poolButton.removeEventListener('click', randomlyPool);
		});

		let hidden = false;
		if (def.hidden) hidden = true;
		else if (def.show) {
			for (let [k, v] of Object.entries(def.show)) {
				let value = props.settings[k as keyof TournamentSettings];
				if (Array.isArray(v)) {
					if (v.every(entry => entry !== value)) {
						hidden = true;
						break;
					}
				} else {
					if (value !== v) {
						hidden = true;
						break;
					}
				}
			}
		}
		if (hidden) return null;

		if (props.mount === false) return (
			<div className={profileStyles.row} key={['row', i].join('.')}> 
				<div className={['fieldBox', 'keyField'].join(' ')}>
					{def.name}
					{def.name.endsWith('?') ? '' : ':'}
				</div>
			</div>
		);
        
		let locked = false;
		if (def.locked) locked = true;
		else if (def.lockOnInit && props.status.round) locked = true;
		else if (def.lockWhen) {
			for (let [k, v] of Object.entries(def.lockWhen)) {
				let value = props.settings[k as keyof TournamentSettings];
				if (value === v) {
					locked = true;
					break;
				}
			}
		}

		let v = props.settings[k as keyof TournamentSettings] ?? def.defaultValue;
		if (def.value) v = def.value(props.status, props.players, props.settings);
		let content = def.convert ?
			def.convert(v) :
			def.type === 'number' ?
				v.toString() :
				v as string;

		return <>
			<div className={profileStyles.row} key={['row', i].join('.')}> 
				<div className={[
					'fieldBox',
					'keyField',
					def.description ? profileStyles.toggleKey : '',
					props.status.active && def.highlight ? profileStyles.semiPresent : ''
				].join(' ')} onClick={toggleHeight}
				>
					{def.name}
					{def.name.endsWith('?') ? '' : ':'}
					{def.description ?
						<div className={['triangle', profileStyles.help].join(' ')}>
							<FaQuestionCircle />
						</div> :
						null
					}
				</div>
				{(() => {
					if (locked || !props.status.active || def.value) {
						return <div
							style={{
								cursor: def.type === 'link' ? 'copy' : 'default'
							}}
							className={['fieldBox', profileStyles.locked].join(' ')}
							onClick={def.type !== 'link' ? null : () => navigator.clipboard.writeText(content)}
						>
							{typeof content !== 'undefined' ? content.toString() : '\u200b'}
						</div>;
					} else
					if (!def.enum && def.type === 'string') {
						return <EditableField
							handleSubmit={(k, v) => props.updateSetting(k, v, def)}
							content={content}
							url={url.resolve(server, ['tournament', props.status.id, def.action || 'updateSetting'].join('/'))}
							inputType='text'
							classNames={{
								editableField: profileStyles.editableField,
								input: profileStyles.input
							}}
							name={k}
							additionalInputProps={{}}
							show={{
								submit: true,
								...def.allow
							}}
							useHandleSubmit
						/>;
					} else
					if (!def.enum && def.type === 'number') {
						return <EditableField
							handleSubmit={(k, v) => props.updateSetting(k, v, def)}
							content={content}
							url={['tournament', props.status.id, 'updateSetting'].join('/')}
							inputType='number'
							classNames={{
								editableField: profileStyles.editableField,
								input: profileStyles.input
							}}
							name={k}
							additionalInputProps={def.nb}
							show={{
								submit: true,
								increment: true,
								...def.allow
							}}
							useHandleSubmit
						/>;
					} else {
						return <div
							className={['fieldBox', profileStyles.editable, def.type === 'boolean' ? v ? profileStyles.true : profileStyles.false : ''].join(' ')}
							onClick={() => handleToggle(k, v, def)}
						>
							{typeof content !== 'undefined' ? content.toString() : '\u200b'}
						</div>;
					}
				})()}
			</div>
			{description}
		</>;
	}, [props.settings, props.status, props.players, props.updateSetting, props.status.id, props.updateTournamentSettings, randomlyPool, props.resize]);

	const renderEntry = SettingsRow;

	return { handleToggle, renderEntry, SettingsRow, watchedSettings };

}