import React, { ReactElement, useState, useReducer, useCallback, useEffect, useMemo } from 'react';
import cx from 'classnames';
import './App.css';
import Login from './tournament/Login';
import StatusBar from './StatusBar';
import Tournament from './Tournament';
import { POST, cookies, setErrorCallback, GET } from './utils/requests';

import Menu from './tournament/Menu';
import Alert, { AlertProps } from './tournament/Alert';
import * as prototype from './utils/prototype';
import { useStateWithLabel, getOS } from './utils/prototype';
import Contexts, { AlertContext } from './Contexts';
import { User, Modes, Platform, TournamentSettings } from './interfaces';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Intro } from './tournament/';
import { getUpdates, Version } from './utils/updates';
import { Link } from './utils/link';
import { isElectron, Package } from './utils/electron';

const startMode = 'menu' as Modes;
window.document.title = ['ScorchApp', 'v' + Package.version].join(' ');
Object.assign(window, prototype);

export default function App(): ReactElement {

	const [sessionID, setSession] = useState('');
	const [tournamentID, setTournament] = useState('');
	const [mode, setMode] = useStateWithLabel('' as Modes, 'mode');
	useEffect(() => {
		(window as any).setMode = setMode;
	}, [setMode]);
	const [alertBox, setAlert] = useReducer((state: AlertProps, action: AlertProps & { stopLoading?: boolean }) => {
		if (action.stopLoading && state.type !== 'loading') return state;
		return Object.assign({}, state, {wide: false, tall: false, resolve: null}, action);
	}, { message: '', title: '', type: '' });

	const closeAlert = useCallback((): void => setAlert({ message: '', title: '' }), [setAlert]);
	const triggerConfirm = useCallback((message: string, resolve: () => void, reject: () => void) => setAlert({
		message,
		title: 'CONFIRM ACTION',
		type: 'confirm',
		resolve,
		reject
	}), [setAlert]);
	const triggerError = useCallback((message: string) => setAlert({message, title: 'ERROR', type: 'error' }), [setAlert]);

	const setTournamentID = useCallback((tournamentID: string): void => {
		setTournament(tournamentID);
		setMode(tournamentID ? 'tournament' : 'menu');
	}, [setTournament, setMode]);

	const hasLoggedIn = useCallback(async (s: string): Promise<void> => {
		await cookies.set('sessionID', s, { path: '/' });
		setSession(s);
		setMode(startMode);
	}, [setSession, setMode]);

	const closeTournament = useCallback(async (): Promise<void> => {
		setTournament('');
		await cookies.set('tournamentID', '', { path: '/' });
		setMode('menu');
	}, [setTournament, setMode]);

	const endSession = useCallback(async (): Promise<void> => {
		try {
			await POST({ url: '/endSession' });
		} catch (e) {
			console.error(e);
		} finally {
			await cookies.set('sessionID', '', { path: '/' });
			document.cookie = 'sessionID=;';
			setSession('');
			setMode('login');
		}
	}, [setSession, setMode]);
	
	useEffect(() => {
		setErrorCallback((header: string, e: string) => setAlert({
			title: <><FaExclamationTriangle />{header}</>,
			type: 'error',
			message: typeof e === 'string' ? e.startsWith('<!DOCTYPE html>') ? <div dangerouslySetInnerHTML={{ __html: e }} /> : <>{e.split('\n').map((line, i) => <p key={['e', i].join('.')}>{line}</p>)}</> : JSON.stringify(e, null, 4)
		}));
		return () => setErrorCallback(() => {});
	}, [triggerError]);

	const checkForUpdates = useCallback(async () => {
		let v = await getUpdates();
		let os = getOS() as Platform;
		if (!os) return;
		let versionString = v[os].Key[0].split('_')[1];
		if (Version(versionString) <= Version(Package.version)) return;
		setAlert({
			type: 'action',
			title: 'Update available!',
			message: <>
				A new version of <strong>ScorchApp</strong> v{versionString} is available!<br />
				<Link className={cx('button')} to={`https://repo.scorchapp.co.uk/latest/${os}.html`}>
					Download it here
				</Link>
			</>
		});
	}, [setAlert]);
	useEffect(() => {
		if (!isElectron) return;
		checkForUpdates();
	}, [checkForUpdates]);

	const pageLoad = useCallback(async () => {
		let sessionID = await cookies.get('sessionID');
		if (!sessionID) return;
		setSession(sessionID);
		setMode(startMode);
	}, [setSession, setMode]);

	useEffect(() => {
		pageLoad();
	}, [pageLoad]);

	const [user, setUser] = useStateWithLabel({} as User, 'user');
	const updateUser = useCallback((): Promise<void> => !sessionID ? null : GET({ url: '/profile'})
		.then(setUser), [setUser, sessionID]);

	useEffect(() => {
		updateUser();
	}, [updateUser]);

	const [defaultSettings, setDefaultSettings] = useState({} as Partial<TournamentSettings>);

	const Mode: ReactElement = useMemo(() => {
		switch (mode) {
		case 'login': {
			return <Login login={hasLoggedIn} mode='login' />;
		}
		case 'menu': {
			return <Menu {...{ endSession, setTournamentID, triggerConfirm, user, updateUser, setMode, setDefaultSettings }}/>;
		}
		case 'intro': {
			return <Intro {...{ endSession, setTournamentID, setMode, defaultSettings }} />;
		}
		case 'tournament': {
			return null;
		}
		default: {
			setMode('login');
			return null;
		}
		}
	}, [user, updateUser, mode, hasLoggedIn, sessionID, endSession, setTournamentID, triggerConfirm, tournamentID, closeTournament, triggerError, setMode, defaultSettings]);

	return (
		<Contexts values={[
			[AlertContext, {
				setAlert,
				closeAlert,
				triggerConfirm,
				startLoading: () => setAlert({ message: true, type: 'loading' }),
				stopLoading: () => setAlert({ message: '', type: '', stopLoading: true })
			}]
		]}>
			{isElectron ? <StatusBar /> : null}
			<div className={cx('stage', { electron: isElectron })}>
				{mode === 'tournament' ? null : <div className={['shade', 'blurred'].join(' ')}>
					{Mode}
				</div>}
				<Tournament
					id={tournamentID}
					{...{ closeTournament, triggerError, triggerConfirm, endSession, setTournamentID, setMode, checkForUpdates }}
				/>
				{alertBox.message && (typeof alertBox.message === 'string' ? alertBox.message.length > 10 : true) ? <Alert
					{...alertBox}
					close={closeAlert}
				/> : null}
			</div>
		</Contexts>
	);

}