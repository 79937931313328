import { useState, useMemo, useReducer, useCallback } from 'react';

export default function usePages(maxPages: number = Infinity) {

	const [usingPages, setPages] = useState(false);
	const reducePages = useCallback((state: number, action: number | 'increment' | 'decrement') => {
		if (state < maxPages && action === 'increment') return state + 1;
		if (state > 0 && action === 'decrement') return state - 1;
		if (typeof action === 'number') return action;
		return state;
	}, [maxPages]);
	const [page, setPage] = useReducer(reducePages, 0);

	return { usingPages, page, setPage, setPages, maxPages };
}