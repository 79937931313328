import React, { useState, useCallback, useEffect, useRef, RefObject } from 'react';
import url from 'url';
import { ProfileProps } from '.';
import { Player, Bonus } from '../../../interfaces';
import { GET, POST, server, FORM } from 'utils/requests';
import { AiOutlineCheck, AiFillEdit, AiOutlineClose } from 'react-icons/ai';

import styles from 'css/profile.module.css';
import sidebarStyles from 'css/sidebar.module.css';

export default function Bonuses(props: ProfileProps & { player: Player, players: Player[], update: (fetch: boolean) => void}) {

	const [bonuses, setBonuses] = useState(props.player.bonuses || []);

	const updateBonuses = useCallback(async () => {
		if (!props.id || !props.player || !props.player.id) return;
		GET({
			url: '/tournament/' + props.id + '/fetchBonuses',
			params: { id: props.player.id }
		})
			.then(setBonuses);
	}, [props.id, setBonuses, props.player]);

	const clearBonus = useCallback(async (round: number) => POST({
		url: '/tournament/' + props.id + '/clearBonus',
		data: {
			id: props.player.id,
			round
		}
	})
		.then(() => {
			updateBonuses();
			props.update(true);
		}), [props.id, props.player, updateBonuses, props.update]);

	const Entry = React.useCallback(function Entry({ bonus, i }: { bonus: Bonus, i: number }) {

		const [edit, setEdit] = useState(false);
		const formRef = useRef(null) as RefObject<HTMLFormElement>;
		const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLFormElement>) => {
			switch (e.keyCode) {
			case 27: {
				return setEdit(false);
			}
			case 13: {
				let form = formRef.current as HTMLFormElement;
				if (!form) return;
				form.requestSubmit();
				e.preventDefault();
			}
			}
		}, [setEdit]);

		if (edit) return (
			<form
				ref={formRef}
				target='_self'
				onSubmit={(e) => {
					e.persist();
					e.preventDefault();
					FORM(e).then(() => {
						(e.target as HTMLFormElement).reset();
					}).then(() => {
						updateBonuses();
						props.update(true);
					});
				}}
				onKeyDown={handleKeyDown}
				method='POST'
				action={url.resolve(server, ['tournament', props.id, 'updateBonus'].join('/'))}
			>
				<div className={[styles.scoreSection, props.settings.displayPoints === 'match' ? styles.matchOnly : ''].join(' ')}>
					<input type='hidden' name='id' value={props.player.id} />
					<input type='hidden' name='round' value={bonus.round} />
					<div className={['fieldBox'].join(' ')}
						style={{gridArea: 'round'}}
					>
						Round: {bonus.round}
					</div>
					<input type='number' className={[sidebarStyles.input, styles.input].join(' ')}
						name='MP'
						placeholder='MP'
						defaultValue={bonus.MP}
						style={{gridArea: 'MP'}}
						step={0.25}
						autoFocus
					/>
					{props.settings.displayPoints === 'game' ? <input type='number' className={[sidebarStyles.input, styles.input].join(' ')}
						name='GP'
						placeholder='GP'
						defaultValue={bonus.GP}
						style={{gridArea: 'GP'}}
						step={0.25}
					/> : null}
					<button tabIndex={-1} className={sidebarStyles.submit}
						style={{gridArea: 'submit'}}
					>
						<AiOutlineCheck />
					</button>
				</div>
			</form>
		);

		return <div id={props.player.id} className={[styles.resultsRow, styles.bonusRow, props.settings.displayPoints === 'match' ? styles.matchOnly : ''].join(' ')} key={['row', 'bonus', i].join('.')}>
			<div className={['fieldBox'].join(' ')}
				style={{gridArea: 'round'}}
			>
				Round: {bonus.round}
			</div>
			<div className={['fieldBox'].join(' ')}
				style={{gridArea: 'MP'}}
			>
				MP: {bonus.MP !== null ? bonus.MP.toFixed(1) : null}
			</div>
			{props.settings.displayPoints === 'game' ? <div className={['fieldBox'].join(' ')}
				style={{gridArea: 'GP'}}
			>
				GP: {bonus.GP !== null ? bonus.GP.toFixed(1) : null}
			</div> : null}
			<button name='edit' className={sidebarStyles.edit} onClick={() => setEdit(true)}
				style={{gridArea: 'edit'}}
			>
				<AiFillEdit />
			</button>				
			<button name='cancel' className={sidebarStyles.cancel} onClick={() => clearBonus(Number(bonus.round))}
				style={{gridArea: 'submit'}}
			>
				<AiOutlineClose />
			</button>
		</div>;
	}, [bonuses, clearBonus, props.settings.displayPoints, updateBonuses]);

	return <>
		{bonuses.map((bonus, i) => <Entry key={['bonus', i].join(' ')} bonus={bonus} i={i} />)}
		<form
			target='_self'
			onSubmit={(e) => {
				e.persist();
				e.preventDefault();
				FORM(e).then(() => {
					(e.target as HTMLFormElement).reset();
				}).then(() => {
					updateBonuses();
					props.update(true);
				});
			}}
			method='POST'
			action={url.resolve(server, ['tournament', props.id, 'createBonus'].join('/'))}
		>
			<div className={[styles.scoreSection, props.settings.displayPoints === 'match' ? styles.matchOnly : ''].join(' ')}>
				<input type='hidden' name='id' value={props.player.id} />
				<input type='number' className={[sidebarStyles.input, styles.input].join(' ')} name='round' required
					placeholder='Round'
					autoComplete={'off'}
					min={1}
					max={props.settings.totalRounds}
					style={{gridArea: 'round'}}
				/>
				<input type='number' className={[sidebarStyles.input, styles.input].join(' ')}
					name='MP'
					placeholder='MP'
					style={{gridArea: 'MP'}}
					step={0.25}
					autoFocus
				/>
				{props.settings.displayPoints === 'game' ? <input type='number' className={[sidebarStyles.input, styles.input].join(' ')}
					name='GP'
					placeholder='GP'
					style={{gridArea: 'GP'}}
					step={0.25}
				/> : null}
				<button tabIndex={-1} className={sidebarStyles.submit}
					style={{gridArea: 'submit'}}
				>
					<AiOutlineCheck />
				</button>
			</div>
		</form>
	</>;

}