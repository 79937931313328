import { History } from '../interfaces';

export function convertToFIDE(ECF: number): number {
	return ECF * 8 + 650;
}

export function convertToECF(ECF: number): number {
	return Math.round((ECF - 650) / 8);
}

export function convertHistoryToFIDE(history: History[]) {
	for (let i = 1; i < history.length; i++) {
		history[i].oppRating = convertToFIDE(history[i].oppRating);
	}
	return history;
}