import React, { ReactElement, useCallback, useContext } from 'react';
import cx from 'classnames';

import styles from './css/pairings.module.css';
import { AlertContext, NextButtonContext, SettingsContext } from '../../Contexts';
import { pl, px } from 'utils/prototype';
import { POST } from 'utils/requests';
import Player from 'models/player';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Result } from 'resources/result';
import { TournamentModes, TournamentStatus } from '../../interfaces';

interface RemainingProps {
	id: string
	updatePlayerDict: () => Promise<void>
	unPaired: Player[]
}
export function PairRemaining(props: RemainingProps): ReactElement {

	const { triggerConfirm } = useContext(AlertContext);
			
	const pairRemaining = useCallback((): Promise<void> => {
		return POST({ url: px('tournament', props.id, 'getRestPairings') })
			.then(props.updatePlayerDict);
	}, [props.id, props.updatePlayerDict]);

	return (
		<div
			className={cx(styles.nextButton, 'button')}
			onClick={() => triggerConfirm('Pair remaining players for this round?', pairRemaining)}
		>
			<div className={styles.remainingDropWrapper}>
				<div className={cx(styles.remainingDrop, 'scrollable')}>
					<b>The following teams have not been paired:</b>
					{props.unPaired.map((p, i) => <div key={cx('pending', i)}>
						{p.name}
					</div>)}
				</div>
			</div>
			Pair Remaining
		</div>
	);
}

interface NextProps {
	id: string
	status: TournamentStatus
	pending: Result[]
	updateSetting: (s: string, v?: boolean) => void
	updateStatus: () => void
	setMode: (m: TournamentModes) => void
	nextOutstanding: () => void
	triggerNextRound: () => void
	triggerPairingSwap: () => void
	left?: number
}
export function NextButton(props: NextProps): ReactElement {

	const settings = useContext(SettingsContext);
	const { nextButton } = useContext(NextButtonContext);

	const setStatus = useCallback((set: boolean) => {
		POST({ url: px('tournament', props.id, set ? 'unpublish' : 'publish') })
			.then(props.updateStatus);
		if (!set && settings.allowNewPlayers) props.updateSetting('allowNewPlayers', false);
	}, [props.id, settings.allowNewPlayers, props.updateSetting]);

	switch (nextButton) {
	case 'skeleton':
		return <div className={styles.waitButton} />;
	case 'loading':
		return <div className={cx(styles.nextButton, 'button')}>
			<AiOutlineLoading3Quarters />
		</div>;
	case 'none':
		return <div className={cx(styles.waitButton)} />;
	case 'switch-more':
		return <div className={[styles.waitButton].join(' ')}>
			Select {props.left} more pairing{pl(props.left)}
		</div>;
	case 'switch':
		return <div className={styles.nextButton} onClick={props.triggerPairingSwap}>
			Switch pairings
		</div>;
	case 'inactive':
		return <div className={cx(styles.waitButton)}>
			Must have at least 2 active players
		</div>;
	case 'publish':
		return <div className={cx(styles.nextButton, 'button')} onClick={() => props.updateSetting('isPublished', true)}>
			Publish draw
		</div>;
	case 'wait':
		return <div className={cx(styles.waitButton, 'button')} onClick={props.nextOutstanding} title='Click to jump to next incomplete result'>
			Awaiting {props.pending.length} result{pl(props.pending)}
		</div>;
	case 'publish-final':
		return <div className={cx(styles.nextButton, 'button')} onClick={() => {
			setStatus(false);
			if (!settings.isPublic) props.setMode('crossTable');
		}}>
			{settings.isPublic ? 'Publish results' : 'Final Standings'}
		</div>;
	case 'last':
		return <div className={cx(styles.finishButton, 'button')} onClick={() => {	
			setStatus(true);
		}}>
			{settings.isPublic ? 'Unpublish results' : 'Resume tournament'}
		</div>;
	case 'next':
		return <div className={cx(styles.nextButton, 'button')} onClick={props.triggerNextRound}>
			{props.status.round ? 'Next Round' : 'First round pairings'}
		</div>;
	}
}