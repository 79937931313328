const codes = `
ALA
ALA
ASM
ASM
AIA
AIA
ATA
ATA
ATG
ATG
ABW
ABW
BHR
BHR
BRB
BRB
BEN
BEN
BTN
BTN
BWA
BWA
BVT
BVT
VGB
VGB
IOT
IOT
BFA
BFA
KHM
KHM
CYM
CYM
CAF
CAF
TCD
TCD
CXR
CXR
CCK
CCK
COG
COG
COK
COK
CRI
CRI
HRV
HRV
DNK
DNK
DMA
DMA
SLV
SLV
GNQ
GNQ
FLK
FLK
GUF
GUF
PYF
PYF
ATF
ATF
GMB
GMB
GRL
GRL
GRD
GRD
GLP
GLP
GTM
GTM
GGY
GGY
GIN
GIN
GNB
GNB
HTI
HTI
HMD
HMD
VAT
VAT
HND
HND
IDN
IDN
IRN
IRN
IMN
IMN
JEY
JEY
KIR
KIR
PRK
PRK
KWT
KWT
LVA
LVA
LSO
LSO
LBY
LBY
MDG
MDG
MWI
MWI
MHL
MHL
MTQ
MTQ
MRT
MRT
MUS
MUS
MYT
MYT
FSM
FSM
MCO
MCO
MNG
MNG
MSR
MSR
MMR
MMR
NPL
NPL
NLD
NLD
NCL
NCL
NIC
NIC
NER
NER
NGA
NGA
NIU
NIU
NFK
NFK
MNP
MNP
OMN
OMN
PSE
PSE
PRY
PRY
PCN
PCN
PRI
PRI
REU
REU
BLM
BLM
SHN
SHN
MAF
MAF
KNA
KNA
SPM
SPM
VCT
VCT
SAU
SAU
WSM
WSM
SLB
SLB
SYC
SYC
SGS
SGS
LKA
LKA
SDN
SDN
SJM
SJM
CHE
CHE
TWN
TWN
TZA
TZA
TGO
TGO
TKL
TKL
TON
TON
TCA
TCA
TUV
TUV
ARE
ARE
UMI
UMI
URY
URY
VUT
VUT
VNM
VNM
VIR
VIR
WLF
WLF
ESH
`;

export default codes;