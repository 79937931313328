import React, { ReactElement, useCallback, useState, useReducer, useEffect, useMemo, useRef, RefObject } from 'react';
import cx from 'classnames';
import { FaSearch, FaSpinner } from 'react-icons/fa';
import componentStyles from 'css/components.module.css';
import { cookies } from 'utils/requests';

interface Player {
	id: string
}

/**
 * Warning: If you pass options in as something that has to be calculated on each render,
 * it WILL cause an infinite loop.
 */
export interface SearchProps {
	onInput?: (id: string) => Promise<Player[]>
	onChange?: (choices: string[]) => void
	onSubmit: (id: string) => void
	onCancel?: () => void
	options?: Player[] 								//[{ id: 'bye'} as any as Player, ...Array.from(props.playerDict.values())]
	getName: (id: Player) => string
	showHistory?: boolean
	showChoices?: boolean
	showIcon?: boolean
	styles: {[key: string]: string}
	placeholder?: string
	limit?: number
	timeout?: number
	cacheHistory?: boolean
	preserveLastSearch?: boolean
	thin?: boolean
	className?: string
	alwaysHover?: boolean
}

export default function Search(props: SearchProps): ReactElement {
	
	const [loading, setLoading] = useState(false);
	const [searchPlayer, setSearchPlayer] = useState(null as Player);
	const [choices, setChoices] = useState([] as Player[]);
	const [currInput, setInput] = useState([null, '', ''] as [HTMLInputElement, string, string]);
	const searchBar = useRef(null) as RefObject<HTMLInputElement>;
	const limit = props.limit || 5;
	const timeout = props.timeout || 500;

	const reduceHistory = useCallback((state: Player[], action: Player) => {
		let history = state.slice(0);
		if (!action) return history;
		let exists = history.findIndex(entry => entry.id === action.id);
		if (exists >= 0) history.splice(exists, 1);
		history.unshift(action);
		props.onSubmit(action.id);
		setSearchPlayer(null);
		setChoices([]);
		searchBar.current.value = '';
		setInput([null, '', '']);
		if (props.cacheHistory) cookies.set('searchHistory', JSON.stringify(history.map(p => p.id)));
		return history;
	}, [props.onSubmit, setSearchPlayer, setChoices, searchBar, props.cacheHistory]);
	const [searchHistory, search] = useReducer(reduceHistory, []);
	
	useEffect(() => {
		if (!props.cacheHistory) return;
		(async () => {
			let history = await cookies.get('searchHistory');
			for (let id of history) {
				let player = props.options.find(p => p.id === id);
				if (!player) continue;
				searchHistory.push(player);
			}
			search(null as Player);
		})();
	}, [props.cacheHistory]);

	const handleInput = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
		if (!(e.nativeEvent as any as InputEvent).data) {
			setSearchPlayer(null);
		}
		setInput([e.target as HTMLInputElement, (e.target as HTMLInputElement).value, (e.nativeEvent as any as InputEvent).data]);
	}, [setInput, setSearchPlayer]);

	const dealWithInput = useCallback(async (inputArr: [HTMLInputElement, string, string]) => {
		let [input, total, change] = inputArr;
		let text = input.value;
		let length = text.length;
		let choices: Player[];
		if (props.onInput) {
			setLoading(true);
			choices = await props.onInput(text).finally(() => setLoading(false));
		} else {		
			choices = (props.options || []).filter(p => props.getName(p).toLowerCase().startsWith(text.toLowerCase()));
		}
		if (props.onChange) props.onChange(choices.map(p => p.id));
		setChoices(choices);
		let firstChoice = choices[0];
		if (!firstChoice || !change) return;
		let autofill = props.getName(firstChoice);
		input.value += autofill.slice(length);
		input.select();
		input.setSelectionRange(length, autofill.length, 'forward');
		setSearchPlayer(firstChoice);
	}, [props.options, props.getName, props.onChange, props.onInput, setLoading, setChoices, setSearchPlayer]);

	useEffect(() => {
		if (!currInput[0]) return;
		if (!props.onInput) {
			dealWithInput(currInput);
			return;
		}
		let x = setTimeout(() => dealWithInput(currInput), timeout);
		return () => clearTimeout(x);
	}, [currInput, props.onInput, dealWithInput]);

	const handleReset = useCallback((e?:  React.KeyboardEvent<HTMLInputElement>) => {
		if (e?.target) (e.target as HTMLInputElement).value = '';
		if (searchBar.current) searchBar.current.value = '';
		setSearchPlayer(null);
		setChoices([]);
	}, [setSearchPlayer, setChoices, searchBar]);
	useEffect(() => {
		handleReset();
	}, [handleReset, props.onInput, props.onChange, props.getName]);
	const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
		switch (e.keyCode) {
		case 27: {
			handleReset();
			if (props.onCancel) props.onCancel();
			break;
		}
		case 13: {
			if (searchPlayer) {
				(e.target as HTMLInputElement).value = '';
				search(searchPlayer);
			}
			break;
		}
		}
	}, [handleReset, searchPlayer, props.onCancel]);

	const styles = useMemo(() => {
		let styles = Object.assign({}, props.styles);
		for (let [k, v] of Object.entries(Object.assign({}, componentStyles))) {
			if (styles[k]) styles[k] += ' ' + v;
			else styles[k] = v;
		}
		return styles;
	}, [props.styles]);

	return (
		<div className={cx(styles.infoBox, props.className)}>
			<div className={cx('button', styles.searchBar, {
				[styles.thin]: props.thin,
				[styles.alwaysHover]: props.alwaysHover
			})} tabIndex={1}>
				{props.showIcon !== false ? <FaSearch /> : null}
				<input
					onKeyDown={handleKeyDown}
					onInput={handleInput}
					placeholder={props.placeholder ?? 'Search'}
					ref={searchBar}
				/>
			</div>
			{props.showChoices !== false ? 
				<div className={[styles.searchResults, 'scrollable'].join(' ')}>
					{loading ?
						<div className={'loading'}>
							<FaSpinner />
						</div> :
						choices.length || !currInput[1] ?
							choices.slice(0, limit).concat(props.showHistory !== false ? searchHistory : []).map((p, i) => {
								return <div
									tabIndex={0}
									onKeyDown={handleKeyDown}
									onFocus={() => setSearchPlayer(p)}
									onClick={() => search(p)}
									key={['history', i].join('.')}
								>
									{props.getName(p)}
								</div>;
							}) :
							<div>No players found.</div>
					}
				</div>
				: null}
		</div>
	);

}