import { useEffect, useReducer, useMemo, useCallback, Dispatch } from 'react';
import Player from 'models/player';
import { isDate } from 'utils/prototype';

export default function usePlayers(props: {
	handleChange?: (changedPlayers: Partial<Player>[]) => void
	players: Player[]
	sortFunc?: (a: Player, b: Player) => number
} = { players: [] }) {

	const reduceChanged = useCallback((state: {[key: string]: Partial<Player>}, action: Pick<Player, 'id'> | null) => {
		if (!action) return {};
		let x = Object.assign({}, state);
		x[action.id] = action;
		let player = props.players.find(p => p.id === action.id);
		if (JSON.stringify(action) === JSON.stringify(player)) delete x[action.id];
		return x;
	}, [props.players]);
	const [changedPlayers, changePlayer] = useReducer(reduceChanged, {}) as [{[key: string]: Partial<Player>}, (action: Partial<Player> | null) => void];
	const hasChanged = useMemo(() => Object.keys(changedPlayers).length, [changedPlayers]);

	// Function that takes in a single player or a Player[] and updates the player Array
	// We get a new sliced array out every time
	const reducePlayers = useCallback((state: Player[], action: Player | Player[] | {
		index: number,
		updates: Partial<Player>
	}) => {
		if (Array.isArray(action)) {
			if (props.sortFunc) action = action.sort(props.sortFunc);
			return action.slice(0);
		}

		let x = state.slice(0);
		let index: number = -1;
		let hChanged = false;
		let player: Partial<Player>;

		if ('index' in action) {
			index = action.index;
			player = action.updates;
		} else {
			player = action;
			index = x.findIndex(p => p.id === player.id);
		}
		
		// I still don't really understand how JavaScript object memory works.
		// I need to somehow create a new one otherwise it overwrites the one in props.players
		for (let [key, value] of Object.entries(player)) {
			let k = key as keyof Player;			
			if (typeof value !== 'string' && checkTypes(x[index][k], value)) {
				x[index] = {
					...x[index],
					[key]: value
				};
				hChanged = true;
			} else {
				let v = unConvert(value as any, x[index][k]);
				if (checkTypes(x[index][k], v)) {
					x[index] = {
						...x[index],
						[key]: v
					};
					hChanged = true;
				}
			}
		}

		player.id = x[index].id;
		changePlayer(player);
		return hChanged ? x : state;
		
	}, [changePlayer, props.sortFunc]);
	const [players, setPlayers] = useReducer(reducePlayers, props.players.slice(0) ?? []);

	useEffect(() => {
		if (!props.handleChange) return;
		props.handleChange(Object.values(changedPlayers));
	}, [props.handleChange, changedPlayers]);

	const handleReset = useCallback(() => {
		setPlayers(props.players);
		changePlayer(null);
	}, [setPlayers, props.players]);

	// This is a shortcut to tell us if props.players updates, then reset the stack
	useEffect(() => {
		handleReset();
		(window as any).handleReset = handleReset;
	}, [handleReset]);

	return { hasChanged, players, setPlayers, changedPlayers, handleReset };

}

function unConvert(value: string, old?: any): any | string {
	switch (typeof old) {
	case 'boolean':
		if (value === 'Y') return true;
		if (value === 'N') return false;
		break;
	case 'number':
		let n = Number(value);
		if (!isNaN(n)) return n;
		break;
	case 'string':
		if (isDate(value)) return new Date(value).toISOString();		
	}
	return value;
}

function checkTypes(to: any, from: any) {
	if (to === null) return true;
	if (from == null) return true;
	if (typeof to !== typeof from) return false;
	return true;
}