import React, { useCallback, useMemo, useState } from 'react';
import hx from 'classnames';
import Core from './Core';
import styles from './css/robin.module.css';

interface RobinProps {
	className?: string
}

const colours = [
	'red',
	'blue',
	'green',
	'pink',
	'yellow',
	'orange',
	'white',
	'purple',
	'cyan',
	'lime'
];

class Point {

	public size = 5;

	constructor(
		private nodes: number,
		public diameter: number,
		private centreX: number,
		private centreY: number,
		public iteration: number
	) {
	}

	public get colour() {
		return colours[this.iteration];
	}

	public get deg() {
		return Math.PI * 2 / this.nodes;
	}

	public get cx() {
		return this.diameter * Math.sin(this.iteration * this.deg) + this.centreX;
	}

	public get cy() {
		return - this.diameter * Math.cos(this.iteration * this.deg) + this.centreY;
	}

	public get element() {
		return (
			<circle
				style={{
					fill: this.colour
				}}
				cx={this.cx}
				cy={this.cy}
				r={this.size}
				pathLength={10}
			/>
		);
	}
}

export default function RobinPairings(props: RobinProps) {

	const padding = 20;

	const [stages, setStages] = useState(8);
	const [nodes, setNodes] = useState(10);
	const [big, setBig] = useState(100);
	const [small, setSmall] = useState(5);

	const buildPoints = useCallback((centre: [number, number]) => {
		let arr = [];
		for (let i = 0; i < nodes; i++) {
			let point = new Point(nodes, big, centre[0], centre[1], i);
			arr.push(point.element);
		}
		return arr;
	}, [nodes, small, big]);

	const generateStages = useMemo(() => {
		let perRow = Math.floor(window.innerWidth / (2 * big + padding));
		let arr = [];
		for (let i = 0; i < stages; i++) {
			let row = Math.floor(i / perRow) + 0.5;
			let column = (i % perRow) + 0.5;
			let cx = column * 2 * (big + padding);
			let cy = row * (2 * big + padding);
			arr.push(<React.Fragment key={hx('stage', i)}>			
				<circle
					cx={cx}
					cy={cy}
					r={big}
					pathLength={10}
					style={{
						fill: 'none',
						stroke: 'black',
						strokeWidth: '4px'
					}}
				/>
				{buildPoints([cx, cy])}
			</React.Fragment>);
		}
		return arr;
	}, [stages, buildPoints, big, padding]);


	return <Core id='pairings' className={props.className}>
		<svg className={styles.svg} width={'100%'} height={'100%'}>
			{generateStages}
		</svg>
	</Core>;

}