import React, { ReactElement, useState, useCallback, useMemo, useContext, CSSProperties } from 'react';
import url from 'url';
import { server, FORM, POST } from 'utils/requests';
import * as regexes from 'utils/regexes';

import appStyles from 'css/app.module.css';
import styles from 'css/login.module.css';
import { StringDictionary } from '../interfaces';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { AlertContext } from '../Contexts';
import Name from 'components/Name';
import { Full, Outline, Base, CrownGif } from 'assets/crown';

export function Crown(props: {
	loading: boolean,
	setLoading: (loading: boolean) => void
	classNames?: StringDictionary
}) {

	return (
		<div
			className={[appStyles.logo, props.classNames?.logo].join(' ')}
		>
			<img style={{ visibility: props.loading ? 'hidden' : 'visible' }} className={appStyles.staticLogo} src={Full} alt='swiss-logo.png' />

			<img style={{ visibility: props.loading ? 'visible' : 'hidden' }} className={appStyles.staticLogo} src={Base} alt='swiss-logo.png' />
			<img style={{ visibility: props.loading ? 'visible' : 'hidden' }} className={appStyles.crownOutline} src={Outline} alt='outline-gif' />
			<img style={{ visibility: props.loading ? 'visible' : 'hidden' }} className={appStyles.loadingGif} src={CrownGif} alt='loading-gif' />
		</div>
	);
}

interface LoginProps {
	login: (sessionID: string) => void
	mode: 'login' | 'register'
}
export default function Login(props: LoginProps) {

	const [mode, setMode] = useState(props.mode as string);
	const [loading, setLoading] = useState(false);
	const [register, setRegister] = useState(false);
	const [errorMessage, triggerError] = useState('');

	const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>): void => {
		setLoading(false);
		FORM(e)
			.then((sessionID: string) => props.login(sessionID))
			.catch((e) => {
				if (e && e.response && e.response.data) triggerError(e.response.data);
				console.error(e);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [setLoading, triggerError, props.login]);
	
	const { setAlert } = useContext(AlertContext);

	const ForgottenContainer = useCallback(function ForgottenContainer({ style }: { style?: CSSProperties }) {
		return (
			<div className={styles.forgottenContainer} style={style}>
				<div className={[styles.forgotten].join(' ')} onClick={() => setMode('reminder')}>username reminder</div>
				<div className={[styles.forgotten].join(' ')} onClick={() => setMode('reset')}>password reset</div>
			</div>
		);
	}, [setMode]);

	const resetForm = useMemo((): ReactElement => {
		return (
			<form
				className={styles.form}
				method='POST'
				action={url.resolve(server, 'requestPasswordReset')}
				onSubmit={(e) => FORM(e).then(() => setAlert({
					title: 'Password reset',
					message: 'Successfully sent a password reset link to the email on file. Check your inbox!'
				}))}
			>
				<div className={[styles.input, styles.back].join(' ')} onClick={() => setMode('login')}>BACK</div>
				<input type='text' name='username' autoComplete='username' required placeholder='username' className={[styles.input].join(' ')} />
				<ForgottenContainer />
				<button type='submit'className={[styles.submit].join(' ')}>
					REQUEST PASSWORD RESET
				</button>
			</form>
		);
	}, [setMode, setAlert]);

	const reminderForm = useMemo((): ReactElement => {
		return (
			<form
				className={styles.form}
				method='POST'
				action={url.resolve(server, 'requestUsernameReminder')}
				onSubmit={(e) => FORM(e).then(() => setAlert({
					title: 'Username reminder',
					message: 'Successfully sent a username reminder. Check your inbox!'
				}))}
			>
				<div className={[styles.input, styles.back].join(' ')} onClick={() => setMode('login')}>BACK</div>
				<input type='email' name='email' autoComplete='email' required placeholder='email' className={[styles.input].join(' ')} />
				<ForgottenContainer />
				<button type='submit' className={[styles.submit].join(' ')}>
					REQUEST USERNAME REMINDER
				</button>
			</form>
		);
	}, [setMode, setAlert]);

	const loginAsGuest = useCallback(() => {
		POST({
			url: '/login',
			data: {
				username: 'guest'
			}
		}).then(handleSubmit);
	}, [handleSubmit]);

	const loginForm = useMemo((): ReactElement => {
		return (
			<form
				className={styles.form}
				method='POST'
				onSubmit={handleSubmit}
				action={url.resolve(server, 'login')}
			>
				<input type='text' name='username' required placeholder='username' className={[styles.input].join(' ')}></input>
				<input type='password' name='password' required placeholder='password' className={[styles.input].join(' ')}></input>
				<ForgottenContainer />
				<input type='submit' value='LOGIN' className={[styles.submit].join(' ')}></input>
			</form>
		);
	}, [handleSubmit]);

	const registerForm = useMemo((): ReactElement => {
		return (
			<form
				className={styles.form}
				method='POST'
				onSubmit={handleSubmit}
				action={url.resolve(server, 'register')}
			>
				<input type='text' name='username' required placeholder='username' className={[styles.input].join(' ')}></input>
				<input type='password' name='password' required placeholder='password' minLength={8} className={[styles.input].join(' ')}></input>
				<input type='email' name='email' required placeholder='email' className={[styles.input].join(' ')} pattern={regexes.email.source}></input>
				<input type='submit' value='REGISTER' className={[styles.submit].join(' ')}></input>
			</form>
		);
	}, [handleSubmit]);

	const crownBuild = useMemo(() => <Crown setLoading={(l: boolean) => setLoading(l)} loading={loading} />, [setLoading, loading]);
	
	const loginSection = useMemo((): ReactElement => {
		return (
			<div className={[styles.loginSection, styles.initialiseButton].join(' ')}>
				<div className={styles.loginBackground}>
					<div className={[styles.flip, 'button', styles.loginButton, mode === 'login' ? styles.hidden : ''].join(' ')} onClick={() => {
						setMode('login');
						triggerError('');
						setTimeout(() => setLoading(false), 500);
					}}>
						<div>LOGIN</div>
						<div>
							<FaAngleLeft />
						</div>
					</div>
					<div />
					<div className={[styles.flip, 'button', styles.registerButton, mode === 'register' ? styles.hidden : ''].join(' ')} onClick={() => {
						setMode('register');
						triggerError('');
						setTimeout(() => setLoading(false), 500);
					}}>
						<div>REGISTER</div>
						<div>
							<FaAngleRight />
						</div>
						<div className={[styles.guest, 'button'].join(' ')} onClick={(e) => {
							loginAsGuest();
							e.stopPropagation();
						}}>
							USE AS GUEST
						</div>
					</div>
				</div>
				<div className={['container', styles.foreground, mode === 'register' ? styles.registerForeground : styles.loginForeground].join(' ')}>
					{
						mode === 'register' ?
							registerForm :
							mode === 'login' ?
								loginForm :
								mode === 'reminder' ?
									reminderForm :
									mode === 'reset' ?
										resetForm :
										null
					}
				</div>
			</div>
		);
	}, [mode, setMode, triggerError, loginForm, registerForm, reminderForm, resetForm, crownBuild, props.mode]);

	return (
		<div className={appStyles.canvas}>
			<div className={appStyles.column} />
			<div className={[appStyles.column, styles.column].join(' ')}>
				{crownBuild}
				<div className={styles.nameContainer}>
					<Name />
				</div>
				<div className={['container', styles.container].join(' ')}>
					{loginSection}
				</div>
				<div className={styles.messageBar}>
					{errorMessage || '\u200b'}
				</div>
			</div>
			<div className={appStyles.column} />
		</div>
	);


}