import React, { useCallback } from 'react';
import { SidebarProps } from '.';
import { Bottom } from 'components/Buttons';
import { FaPaste, FaSyncAlt, FaTimes, FaEdit, FaFileImport } from 'react-icons/fa';
import { POST } from 'utils/requests';
import styles from 'css/sidebar.module.css';

export default function Banner(props: SidebarProps & {
	round: number
}) {

	/**
	 * Clears all players. Called when the 'X' button is pressed at the bottom of the sidebar
	 */
	const clearPlayerList = useCallback(async (): Promise<void> => {
		await POST({ url: '/tournament/' + props.id + '/clearPlayers' });
		await props.updatePlayerDict();
		props.checkIfEnoughPlayers();
	}, [props.id, props.checkIfEnoughPlayers, props.updatePlayerDict]);

	const handleClear = useCallback(async (): Promise<void> => {
		/*
		for (let form of Object.values(this.refs)) {
			if (form.constructor.name !== 'HTMLFormElement') continue;
			(form as HTMLFormElement).reset();
		}*/
		if (props.round !== 0) return;
		await clearPlayerList();
		props.updatePlayerDict();
	}, [clearPlayerList, props.updatePlayerDict, props.round]);

	return (
		<div className={['bottomBannerBackground', styles.bottomBanner].join(' ')}>
			<div className='bottomBanner'>
				{Bottom([
					{
						onClick: () => props.updateSetting('allowNewPlayers', !props.settings.allowNewPlayers),
						icon: <FaEdit key='edit' />,
						name: props.round ?
							props.settings.allowNewPlayers ? 'Stop Editing Players' : 'Edit' :
							'New'
					},
					{
						onClick: handleClear,
						icon: <FaTimes key='close' />,
						name: 'Clear all',
						hide: !!props.round || !Array.from(props.playerDict.keys()).length
					}
				])}
			</div>
		</div>
	);
}