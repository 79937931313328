import React, { useState, useCallback } from 'react';
import { TournamentStatus, TournamentSettings } from '../../interfaces';
import { cookies, GET, POST } from 'utils/requests';
import { TournamentSetting } from 'resources/settings';

export function useLoad(props: {
	id: string
	updatePlayerDict: (force?: boolean) => Promise<void>
}) {
	
	const shouldRun = !!props.id;
	const { updatePlayerDict } = props;

	const [status, setStatus] = useState({} as TournamentStatus);
	const [settings, setSettings] = useState({} as TournamentSettings);
	const [announcements, setAnnouncements] = useState([] as {id: string, announcement: string}[]);
	const [rounds, setRounds] = useState([] as Date[]);

	const updateTournamentStatus = useCallback((): Promise<void> => GET({ url: '/tournament/' + props.id + '/getStatus'})
		.then((status: TournamentStatus) => {
			setStatus(status);
			cacheLast(status);
		}), [props.id, setStatus]);

	const updateTournamentSettings = useCallback((): Promise<void> => GET({ url: '/tournament/' + props.id + '/getSettings'})
		.then(setSettings), [props.id, setSettings]);
	const updateAnnouncements = useCallback((): Promise<void> => GET({ url: '/tournament/' + props.id + '/getAnnouncements'})
		.then((a) => setAnnouncements([undefined, ...a])), [props.id, setAnnouncements]);

	const updateSetting = useCallback((k: string, v: any, def?: TournamentSetting<string | number | boolean>): Promise<void> => POST({
		url: 'tournament/' + props.id + '/updateSetting',
		data: {
			[k]: v,
			[def?.linked]: def?.linked ? v : undefined
		}
	}).then(updateTournamentSettings), [props.id, updateTournamentSettings]);

	const updateRounds = useCallback((): Promise<void> => GET({ url: '/tournament/' + props.id + '/fetchRounds'})
		.then(setRounds), [props.id, setRounds]);
        
	const load = useCallback((): Promise<void> => {
		if (!shouldRun) return;
		return Promise.all([
			updateRounds(),
			updateTournamentStatus(),
			updateTournamentSettings(),
			updateAnnouncements(),
			updatePlayerDict(true)
		]).then(() => {});
	}, [shouldRun, updateRounds, updateTournamentStatus, updateTournamentSettings, updateTournamentStatus, updateRounds]);//updatePlayerDict]);

	return {
		status,
		settings,
		announcements,
		rounds,
		updateTournamentStatus,
		updateTournamentSettings,
		updateAnnouncements,
		updateSetting,
		updateRounds,
		load
	};

}


async function cacheLast(status: TournamentStatus) {
	let cachedString = JSON.stringify({
		id: status.id,
		name: status.name
	});
	await cookies.set('lastOpened', cachedString);
}