import React, { ReactElement, FormEvent, useState, useCallback, useMemo, useEffect, useContext } from 'react';
import url from 'url';
import { Player, TournamentPlayers, ID } from '../../interfaces';
import { GET, POST, server, FORM} from 'utils/requests';

import styles from 'css/sidebar.module.css';
import CollapsableSection, { SectionBreak } from 'components/CollapsableSection';
import TabSection from 'components/TabSection';
import { ActivePlayer, EditableForm, SidebarProps } from './';
import { LoadedContext } from '../../Contexts';

export default function Players(props: SidebarProps & {
	round: number
	setPlayers: (refresh?: boolean) => Promise<void>
	players: TournamentPlayers
}): ReactElement {

	const { round, setPlayers, players } = props;

	/** CALLBACK SECTION */

	const activatePlayer = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		let button = e.target as HTMLButtonElement;
		if (button.constructor.name !== 'HTMLButtonElement') return;
		let id = (button.parentNode as HTMLDivElement).id;
		POST({
			url: '/tournament/' + props.id + '/activatePlayer',
			data: { id }
		})
			.then(player => ({ player }))
			.then(props.setPlayerDict)
			.catch(console.error);
	}, [props.id, props.setPlayerDict]);

	/**
	 * Clears a single page. Called when the 'X' button is hit next to a player
	 */
	const clearPlayer = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		let button = e.target as HTMLButtonElement;
		if (button.constructor.name !== 'HTMLButtonElement') return;
		let id = (button.parentNode as HTMLDivElement).id;
		POST({
			url: '/tournament/' + props.id + '/clearPlayer',
			data: { id }
		})
			.then((player: Player) => {
				if (player) props.setPlayerDict({ player });
				else props.setPlayerDict({ id });
			})
			.catch(console.error);
	}, [props.setPlayerDict, props.id]);
	
	const requireLastName = useMemo(() => props.settings.competitors === 'individual', [props.settings.competitors]);

	function Entry(entryProps: { player: Player }): ReactElement {

		const [edit, setEdit] = useState(false);
		const [player, setPlayer] = useState(entryProps.player);

		if (edit) return <EditableForm
			id={props.id}
			i={0}
			settings={props.settings}
			player={player}
			close={() => setEdit(false)}
			onSubmit={async (e: FormEvent<HTMLFormElement>): Promise<void> => FORM(e)
				.then((newPlayer: Player) => {
					props.setPlayerDict({ player: newPlayer });
					setPlayer(newPlayer);
					setEdit(false);
				})
			}
			action={url.resolve(server, ['tournament', props.id, 'editPlayer'].join('/'))}
			isActive
		/>;

		return <ActivePlayer
			player={player}
			round={round}
			requireLastName={requireLastName}
			editPlayer={() => setEdit(true)}
			activatePlayer={activatePlayer}
			clearPlayer={clearPlayer}
			allowNewPlayers={props.settings.allowNewPlayers}
			setDisplayPlayer={props.setDisplayPlayer}
		/>;
	}

	const defaultExpand = useMemo(() => {
		return Object.values(players).every(score => score.length < 30);
	}, [players]);

	const List = useMemo((): ReactElement[] => {
		let list = [] as ReactElement[];
		let max = Object.values(players).reduce((acc, curr) => Math.max(acc, Math.max(...curr.map(p => p.score))), 0);
		for (let i = max; i >= 0; i-= 0.5) {
			let score = i.toString();
			if (!players[score] || !players[score].length) continue;
			let section = <CollapsableSection
				id={['container', score].join('.')}
				key={['sidebar', 'round', score].join('.')}
				header={score + ' points'}
				useInternalState
				expanded
				withLengths
				mustExpand={players[score].some(p => p.id === props.jumpedTo)}
			>
				{players[score].map((p) => <Entry key={p.id} player={p} />)}	
			</CollapsableSection>;
			list.push(section);
		}
		return list;
	}, [round, players, props.settings.allowNewPlayers, props.settings, props.jumpedTo]);

	useEffect(() => {
		if (!props.playerDict.size) return;
		setPlayers(false);
	}, [props.playerDict, setPlayers]);

	useEffect(() => {
		if (!props.playerDict.size) return;
		setPlayers();
	}, [props.playerDict, props.stateKey]);

	const [currJump, setJump] = useState('');
	useEffect(() => {
		if (!props.jumpedTo) return;
		setJump(props.jumpedTo);
	}, [setJump, props.jumpedTo]);
	useEffect(() => {
		if (!currJump) return;
		let arr = document.querySelectorAll('#' + currJump);
		for (let div of Array.from(arr).reverse()) {
			div.scrollIntoView({
				behavior: 'smooth'
			});
		}
		if (arr.length) setJump('');
	}, [currJump, setJump]);

	const hasLoaded = useContext(LoadedContext);

	return (
		<>	
			<div className={[styles.sidebar, styles.players].join(' ')}>	
				<div className={styles.stage}>
					<TabSection
						name={'tournamentPlayers'}
						setName={() => {}}
						nameDict={{
							tournamentPlayers: {
								name: 'Tournament Players'
							}
						}}
					/>
					<div className={['scrollable', styles.playersSection].join(' ')}>
						{!hasLoaded ?
							<div className={styles.skelWrapper}>
								{['', '', ''].map((_k, i) => {
									return <div className={[styles.playerLine, 'hover', styles.skel].join(' ')} key={['skel', i].join('.')}>
										<div className={['playerInfo', styles.playerInfo, 'hover'].join(' ')}>
											{'\u200b'}
										</div>
										<>
											<button name='edit' className={styles.edit}>
												{'\u200b'}
											</button>
											<button name='cancel' className={styles.cancel}>
												{'\u200b'}
											</button>
										</>
									</div>;
								})}
							</div> :
							!List.length ? <div className={styles.scoreSection}>
								<div className={styles.playerLine}>
									<div className={styles.sectionHeader} style={{gridArea: 'firstName / firstName / submit / submit'}}>
										{List.length ? 'Player List' :'No players added yet'}
									</div>
								</div>
							</div> :
								null
						}
						{List}
					</div>
				</div>
			</div>
		</>
	);
}