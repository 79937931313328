import { useState, useEffect, useMemo, useCallback , ReactElement } from 'react';
import countries from 'assets/countries.json';
import { GET } from 'utils/requests';

const flags = 'https://ratings.fide.com/svg/|.svg';

let cacheFlags = null as null | typeof countries;

export default function useFlags() {

	const [currFlags, setFlags] = useState(cacheFlags);
	useEffect(() => {
		if (currFlags) return;
		GET({ url: '/countries.json' })
			.then(v => v ? setFlags(v) : null)
			.catch(console.error);
	}, []);

	const vFlags = useMemo(() => currFlags ?? countries, [currFlags]);

	/**
	 * Get react component flag from country code
	 */
	const getFlag = useCallback((v: string, width?: number, height?: number): ReactElement | null => {
		const { dict, specify } = vFlags;
		if (!v) return null;
		let title = dict[v as keyof typeof dict];
		let src = flags.replace('|', v);
		if (specify.includes(v)) src = '/images/|.svg'.replace('|', v);
		return <img src={src} alt={v} title={title} width={width || 26} height={height || 19.5} />;
	}, [vFlags]);

	return { getFlag, ...vFlags };
}