import React, { ReactElement, useState, useEffect, useCallback, useMemo, RefObject, useRef, useContext } from 'react';
import cx from 'classnames';
import { FaBars } from 'react-icons/fa';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import { TournamentStatus, ID, Player, Result, TournamentModes, NextButtonStates } from '../../interfaces';
import { DisplayNameContext, NextButtonContext, PlayerDictContext, SettingsContext } from '../../Contexts';
import CollapsableSection from 'components/CollapsableSection';
import SinglePairing, { PairingDetails } from './SinglePairing';
import { Knockout, CrossTable } from 'screen/';
import { filterPairingOptions } from '../Dock';

import { TournamentSettings } from 'resources/settings';
import { POST } from 'utils/requests';
import { convertPoolDisplay, px } from 'utils/prototype';

import styles from './css/pairings.module.css';
import usePairings from './hooks/useFetch';
import { NextButton, PairRemaining } from './Buttons';
import ManualPairing from './Manual';
import useSwitch from './hooks/useSwitch';

interface PairingsProps {
	switchMode: boolean
	setMode: (m: TournamentModes) => void
	userRound: number
	status: TournamentStatus
	playerDict: Map<ID, Player>
	updatePlayerDict: () => Promise<void>
	settings: TournamentSettings
	updateStatus: () => void
	updateTournamentSettings: () => void
	updateSetting: (k: string, v: any) => Promise<void>
	id: string
	canTriggerNextRound: boolean
	setDisplayPlayer: (id: string) => void
	toggleSidebar: (set?: boolean) => void
	toggleRightTab: (set?: boolean) => void

	updateRound: () => void
	setLastRound: (override: boolean) => void
	lastRoundOverride: boolean
	triggerConfirm: (message: string, resolve: () => void, reject: () => void) => void
	filtered: string[]
	filter: (f: string[]) => void
	filters: {[key: string]: boolean | undefined}
	jumpedTo: string
	outstandingIndex: number
	nextOutstanding: () => void
	setOutstanding: (v: boolean) => void
}

export default function Pairings(props: PairingsProps): ReactElement {

	const round = useMemo(() => props.userRound || props.status.round, [props.userRound, props.status.round]);
	const { pairingData, results, resultsState, getPairings } = usePairings({ id: props.id, round });
	const [loading, setLoading] = useState(false);

	const settings = useContext(SettingsContext);
	const playerDict = useContext(PlayerDictContext);
	const getDisplayName = useContext(DisplayNameContext);
	const validateForm = useValidate(settings);
	
	const pending = useMemo(() => {
		return pairingData.filter((r) => {
			let arr = results.get(r.id);
			if (!arr) return false;
			let scores = arr.slice(0, 2);
			return scores.every(v => v === null);
		});
	}, [results, resultsState, pairingData]);

	const { select, selected, triggerPairingSwap, SingleSwitch } = useSwitch({ ...props, round });
			
	const triggerNextRound = useCallback((): Promise<void> => {
		setLoading(true);
		return POST({
			url: '/tournament/' + props.id + '/getNextPairings'
		})
			.then(settings.publishPairings ? props.updateTournamentSettings : () => {})
			.then(props.updatePlayerDict)
			.then(props.updateRound)
			.finally(() => setLoading(false));
	}, [setLoading, props.id, props.updateRound, setLoading]);

	const unPaired = useMemo((): Player[] => {
		return Array.from(playerDict.values()).filter(p => p.active !== false && p.histories && !p.histories[round]);
	}, [playerDict, round]);

	const canPairMore = useMemo((): boolean => {
		let length = unPaired.length;
		return !!length;
	}, [unPaired, settings.pairingSystem]);

	const { setNextButton } = useContext(NextButtonContext);
	const nextButtonState: NextButtonStates = useMemo(() => {
		if (!settings?.pairingSystem) return 'skeleton';
		if (props.switchMode) {
			let left = 2 - selected.length;
			if (left) return 'switch-more';
			return 'switch';
		}

		let lastRound: boolean;
		if (round >= settings.totalRounds) lastRound = true;
		else if (typeof props.lastRoundOverride !== 'undefined') lastRound = props.lastRoundOverride;
		else lastRound = false;
		if (loading) return 'loading';
		if (round < props.status.round) return 'none';
		if (!settings.isPublished) return 'publish';
		if (!props.canTriggerNextRound) return 'inactive';
		if (pending.length > 0 && settings.pairingSystem !== 'round-robin') return 'wait';
		if (lastRound) {
			if (props.status.active) return 'publish-final';
			return 'last';
		}
		return 'next';
	}, [round, props.status.active, settings, props.lastRoundOverride,
		loading, props.status.round, props.canTriggerNextRound, pending.length,
		selected, props.switchMode
	]);
	useEffect(() => {
		setNextButton(nextButtonState);
	}, [setNextButton, nextButtonState]);
    
	const [expanded, setExpanded] = useState('');

	const handleSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>, id: string) => {
		let form = e.target as HTMLFormElement & {resultWhite: HTMLInputElement, resultBlack: HTMLInputElement};
		results.set(id, [Number(form.resultWhite.value), Number(form.resultBlack.value), {}]);
		if (form) {
			let parent = form.parentElement as HTMLDivElement;
			if (parent) {
				let nextSibling: HTMLDivElement = parent;
				do {
					nextSibling = nextSibling.nextSibling as HTMLDivElement;
				} while (nextSibling && !nextSibling.getElementsByTagName('form')[0]);
				if (nextSibling) {
					let newForm = nextSibling.getElementsByTagName('form')[0] as HTMLFormElement;
					let newInput = newForm.getElementsByTagName('input')[0] as HTMLInputElement;
					if (newInput) newInput.focus();
				}
			}
		}
		props.updatePlayerDict();
		return e;
	}, [results, props.updatePlayerDict]);

	const Listing = useCallback(function Listing(): ReactElement[] {
		let pairings = [] as ReactElement[];
		let i = 1;
		let pools = [] as Result[][];
		for (let r of pairingData) {
			let pool = r.pool || 0;
			if (!settings.usingPools) pool = 0;
			if (!pools[pool]) pools[pool] = [] as Result[];
			pools[pool].push(r);
		}
		for (let j = 0; j < pools.length; j++) {
			if (!pools[j]) continue;
			if (j) pairings.push(
				<div id={['pool', j].join('.')} key={['pool', j].join('.')} className={styles.pool}>
					Pool {convertPoolDisplay(j, settings.poolDisplay)}
				</div>
			);
			for (let r of pools[j]) {
				let { id, idW, idB } = r;
				let result = results.get(r.id);
				if (!result) continue;
				let gameLinks = { link: r.link, source: r.linkSource, type: r.type };
				
				let hidden = true;
				// Hide based on search function. If filtered arr exists and an element matches then don't hide
				if (!props.filtered) hidden = false;
				else if (!props.filtered.length) hidden = false;
				else if (props.filtered.some(p => idW === p || idB === p)) hidden = false;
				// Or show based on props.filters
				if (props.filters && Object.entries(props.filters).some(([k, v]) => {
					if (v === undefined) return false;
					let option = filterPairingOptions.find(o => o.key === k);
					if (!option) return false;
					let x = option.func(r, result);
					if (v) return !x;
					else return x;
				})) hidden = true;

				let handleClick: (e: React.MouseEvent<HTMLDivElement>) => void;
				if (!props.switchMode) handleClick = (e) => {
					let id = (e.target as HTMLDivElement).id;
					if (id === 'bye') return null;
					props.setDisplayPlayer(id);
				};
				else handleClick = (e) => {
					let id = (e.target as HTMLDivElement).id;
					select(id);
				};

				pairings.push(
					<div id={id} className={styles.pairing} key={['pairings', id].join('.')} style={hidden ? {display: 'none'} : {}}>
						<div
							className={cx('playerInfo', styles.playerInfo, {
								[styles.bye]: idW === 'bye',
								[styles.selected]: selected.includes(idW)
							})}
							id={idW}
							onClick={handleClick}
						>{getDisplayName(idW, ['score'])}</div>
						{props.switchMode ? <SingleSwitch {...{ id, idW, idB }} setDisplayPlayer={props.setDisplayPlayer} /> : <SinglePairing
							expand={(set: boolean) => setExpanded(set ? id : '')}
							expanded={expanded === id}
							result={result}
							gameLinks={gameLinks}
							tournamentID={props.id}
							inputClassName={styles.inputResult}
							hidden={{
								W: idW,
								B: idB,
								round,
								override: true
							}}
							step={settings.pairingSystem === 'knockout' && settings.displayPoints === 'match' ? 1 : undefined}
							validate={validateForm}
							handleSubmit={e => handleSubmit(e, id)}
						/>}
						<div
							className={cx('playerInfo', styles.playerInfo, {
								[styles.bye]: idB === 'bye',
								[styles.selected]: selected.includes(idB)
							})}
							id={idB}
							onClick={handleClick}
						>{getDisplayName(idB, ['score'])}</div>
					</div>,
					<PairingDetails
						key={['pairingDetails', id].join('.')}
						tournamentID={props.status.id}
						id={id}
						board={i}
						round={round}
						className={cx('dropdown', styles.pairing)}
						style={hidden ? {display: 'none'} : {}}
						result={result}
						expanded={expanded === id}
						gameLinks={gameLinks}
						individual={settings.competitors === 'individual'}
						update={getPairings}
						updatePlayerDict={props.updatePlayerDict}
						triggerConfirm={props.triggerConfirm}
						settings={settings}
					/>
				);
				i++;
			}
		}
		return pairings;
	}, [setExpanded, expanded, playerDict, pairingData, getDisplayName, results, resultsState,
		props.updatePlayerDict, getPairings, props.setDisplayPlayer,
		props.filter, props.filtered, props.filters, settings, props.switchMode, selected]);

	const pairings = useRef() as RefObject<HTMLDivElement>;

	const [unfinishedPairings, nextOutstanding] = useMemo(() => {

		// Filter results from map based on if either side is 
		let u = pairingData
			.filter((pairing) => {
				let r = results.get(pairing.id);
				return r[0] == null && r[1] == null;
			})
			.map(({ id }) => id);

		// Find the next oustanding pairing by using the oustandingIndex
		let n = u[props.outstandingIndex % u.length];
		if (props.outstandingIndex === -1) n = null;
		return [u, n];
	}, [results, resultsState, props.outstandingIndex]);
	
	useEffect(() => {
		props.setOutstanding(!!unfinishedPairings.length);
	}, [props.setOutstanding, unfinishedPairings]);
	useEffect(() => {
		if (!nextOutstanding) return;
		if (!pairings.current) return;
		let pairing = document.getElementById(nextOutstanding);
		if (!pairing) return;
		pairing.scrollIntoView({ behavior: 'smooth' });
		pairing.classList.add('tempHighlight');
		let x = setTimeout(() => pairing.classList.remove('tempHighlight'), 5000);
	}, [pairings, nextOutstanding]);

	const listing = useMemo(Listing, [Listing]);
	return (
		<>
			<div className={styles.pairings} ref={pairings}>
				<div className={styles.stage}>
					<div className={cx(styles.knockoutWrapper, 'scrollable')}>
						<CollapsableSection
							header={props.switchMode ? 'Switch Pairings Mode' : 'Pairings'}
							value='pairings'
							scrollable
							useInternalState
						>
							<div className={cx(styles.pairingContainer)}>
								<div className={cx(styles.pairingWrapper)}>
									{listing}
									{canPairMore && round && !props.switchMode ? <ManualPairing
										id={props.id}
										round={round}
										handleSubmit={async (e) => {
											props.updatePlayerDict();
											return e;
										}}
									/> : null}
								</div>
							</div>
						</CollapsableSection>						
						{props.switchMode ? null : settings.pairingSystem === 'knockout' ?
							<CollapsableSection
								header='Bracket'
								value='standings'
								expanded={false}
								scrollable
								useInternalState
							>
								<Knockout {...props} resultKey={resultsState} className={styles.knockout} scrollable={false} />
							</CollapsableSection> :
							<CollapsableSection
								header='Standings'
								value='standings'
								expanded={false}
								scrollable
								useInternalState
							>
								<CrossTable {...props} className={styles.knockout} players={Array.from(playerDict.values())} rightTab={false} />
							</CollapsableSection>
						}
						{props.filtered.length ?
							<div onClick={() => props.filter([])} className={cx('button', styles.clearFiltered)}>
								Clear search parameters
							</div> :
							null
						}
					</div>
				</div>
			</div>
			<div className={cx('bottomBannerBackground', styles.statusBarBackground)}>
				<div className={cx('bottomBanner', styles.statusBar)}>
					<div className={cx('button')} onClick={() => props.toggleSidebar()}>
						{nextButtonState === 'skeleton' ? null : <FaBars />}
					</div>
					<div style={{display: 'grid', gridAutoFlow: 'column', gridAutoColumns: '1fr', width: '100%', columnGap: '5px'}}>
						{!props.switchMode && canPairMore && props.status.round ?
							<PairRemaining {...{ ...props, unPaired }} /> :
							null
						}
						<NextButton {...{ ...props, pending, triggerNextRound, triggerPairingSwap,
							left: 2 - selected.length }} />
					</div>
					<div className={cx('button')} onClick={() => props.toggleRightTab()}>
						{nextButtonState === 'skeleton' ? null : <FaBars />}
					</div>
				</div>
			</div>
		</>
	);

}

export function useValidate(settings: TournamentSettings) {
	
	function validateForm(form: HTMLFormElement): boolean | string | void {
		if (form.resultWhite.value === '' && form.resultBlack.value === '') return undefined;
		if (!Array.from(form).every((input) => (input as HTMLInputElement).validity.valid)) return false;
		let nW = Number(form.resultWhite.value);
		let nB = Number(form.resultBlack.value);
		if (nW + nB === 0) return 'Cannot have a 0-0 result';
		if (!settings.gamePointTotal) return true;
		let total = settings.displayPoints === 'game' ? settings.gamePointTotal : 1;
		if (nW + nB === total) return true;
		return 'Setting: sum results to ' + settings.gamePointTotal;
	}

	return validateForm;
}