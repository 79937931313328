import { Remote } from 'electron';

const isElectron = navigator.userAgent.toLowerCase().includes('electron');
const preload = (window as any).api;

let isDev: boolean, isProd: boolean, Package: { version: string }, remote: Remote;
if ((isElectron || isProd) && preload) {
	isDev = preload.isDev;
	isProd = preload.isProd;
	Package = preload.Package;
	remote = preload.remote;
} else {
	remote = window?.require ?
		window.require('electron').remote :
		null;
	isDev = process.env.NODE_ENV === 'development';
	isProd = remote?.process.argv[2] !== '--dev';
	Package = require('../../package.json');
}

const shouldListen = (channel: string, func: (...args: any[]) => void) => {
	if (!window?.require) return;
	const { ipcRenderer } = window.require('electron');
	const validChannels = ['toggleMax'];
	if (validChannels.includes(channel)) {
		ipcRenderer.on(channel, (_event, ...args) => func(...args));
	}
};

const shouldSend = (channel: string, data?: any) => {
	if (!window?.require) return;
	const { ipcRenderer } = window.require('electron');
	console.log('send', channel, data);
	const validChannels = ['toggleMax', 'min', 'close'];
	if (validChannels.includes(channel)) {
		ipcRenderer.send(channel, data);
	}
};

let listen: typeof shouldListen;
let send: typeof shouldSend;

if (preload) {
	listen = preload.listen;
	send = preload.send;
} else {
	listen = shouldListen;
	send = shouldSend;
}

export { isElectron, isDev, isProd, Package, listen, send };

// export const renderedArguments = !window.process.argv.includes('--production');
/**
 * 				|		isDev						|					!isDev	
 * _________________________________________________________________________________________________
 * !isElectron	|		yarn start (in-browser)		|		yarn deploy (gh-pages build)
 *  isElectron	|		yarn gui (linked electron)	|		yarn gui-prod (headless electron build)
 * 
 * isProd: yarn exe (OS-specific executable)
 */

export const session = remote?.session;

export const electronCookies = isElectron && remote ?
	remote.getCurrentWebContents().session.cookies :
	null;

console.log('isDev', isDev);
console.log('isElectron', isElectron);
console.log('isProd', isProd);