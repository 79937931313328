import React, { ReactElement, useCallback, useState, useEffect, useMemo, useContext } from 'react';
import cx from 'classnames';
import QR from 'react-qr-code';
import { GET, server, POST, resultsServer } from 'utils/requests';
import { Player, TournamentSettings, ID, History, TournamentStatus } from '../../../interfaces';

import styles from 'css/profile.module.css';
import CollapsableSection from 'components/CollapsableSection';
import { DisplayNameContext } from '../../../Contexts';
import Clashes from './Clashes';
import Bonuses from './Bonuses';
import Search from 'components/Search';
import { dict as countries } from 'assets/countries.json';
import { EditableField } from 'components';
import { capitalise, px } from 'utils/prototype';
import useDescription from '../hooks/useDescription';
import { FaCaretDown, FaSyncAlt } from 'react-icons/fa';
import { Link } from 'utils/link';
import useFlags from 'hooks/useFlag';

export interface ProfileProps {
	id: string
	status: TournamentStatus
	displayPlayer: string
	setDisplayPlayer: (id: string) => void
	playerDict: Map<ID, Player>
	setPlayerDict: ({ player, hash }: { player: Player, hash: string }) => void
	settings: TournamentSettings
}
export function booleanConvert(v: boolean): 'Yes' | 'No' | '' {
	if (v) return 'Yes';
	if (v === false) return 'No';
	return '';
}

let prev = {} as any;
export default function Profile(props: ProfileProps): ReactElement {

	const [player, setPlayer] = useState(null as Player);
	const players = useMemo(() => Array.from(props.playerDict.values()), [props.playerDict]);
	const getDisplayName = useContext(DisplayNameContext);

	const useVFlags = useFlags();
	const nationalities = useMemo(() => {
		return Object.entries(useVFlags.dict).map(([k, v]) => {
			return {
				name: v,
				id: k
			};
		});
	}, [useVFlags.dict]);

	const [mounted, setMounted] = useState(false);
	const [code, setCode] = useState('');
	const updateCode = useCallback((refresh?: boolean) => {
		if (!props.id) return;
		POST({
			url: px('tournament', props.id, 'createPlayerCode'),
			data: {
				id: props.displayPlayer,
				refresh
			}
		}).then(setCode);
	}, [props.id, props.displayPlayer, setCode]);
	useEffect(() => {
		if (!mounted) return;
		updateCode();
	}, [updateCode]);
	const codeComponent = useMemo(() => {
		if (!mounted) return <span>{'\u200b'}</span>;

		const type = props.settings.competitors === 'individual' ? 'player' : 'team';
		const claimant = type === 'team' ? 'team captain' : type;
		const href = `${resultsServer}${type}/${props.displayPlayer}?claim=${code}`;
		return <>
			<p>Please tell your {claimant} to claim using the following code:</p>
			<div className={styles.li}>
				<span className='code'>
					{code}
				</span>
				<button
					type='button'
					className={cx(styles.button, styles.increment)}
					onClick={() => updateCode(true)}
					title='Refresh'
				>
					<FaSyncAlt />
				</button> 
			</div>
			<p>
				Alernatively, click the following link:<br />
				<Link to={href}>{href}</Link><br />
				Do not share this link with anyone else!
			</p>
			<QR value={href} size={150} />
		</>;
	}, [code, props.displayPlayer, mounted, updateCode]);
	const { toggleHeight, description } = useDescription({
		content: codeComponent,
		onMount: () => setMounted(true),
		className: cx(styles.desc, 'scrollable')
	});

	const updatePlayerFromProps = useCallback((refetch?: boolean) => {
		if (!props.displayPlayer) {
			setPlayer(null as Player);
			return;
		}
		if (refetch === false) {
			setPlayer(props.playerDict.get(props.displayPlayer));
		} else {
			return GET({
				url: '/tournament/' + props.id + '/fetchPlayer',
				params: {
					id: props.displayPlayer,
					withClashes: true,
					withBonuses: true
				},
				noCatch: true
			})
				.then((p: Player) => {
					if (!p.histories[0]) p.histories[0] = null;
					setPlayer(p);
					if (refetch) props.setPlayerDict({ player: p, hash: Math.random().toString().slice(2, 9) });
				})
				.catch(() => props.setDisplayPlayer(''));
		}
	}, [props.displayPlayer, setPlayer, props.playerDict, props.id, props.setPlayerDict, props.setDisplayPlayer]);
    
	useEffect(() => {
		updatePlayerFromProps();
	}, [updatePlayerFromProps]);
    
	const renderEntry = useCallback(([k, v]: [string, boolean | string | number | undefined | ReactElement | [boolean | string | ReactElement, ...ReactElement[]]], i: number, arr: any[], canEdit?: boolean): ReactElement => {
		if (k.startsWith('__')) return null;
		if (k === 'Playing' && typeof v === 'string') {
			return (
				<div className={styles.row} key={['row', i].join('.')}>
					<div className='fieldBox'>{k + ':'}</div>
					<div
						className='playerInfo'
						id={v as string}
						onClick={(e: React.MouseEvent<HTMLDivElement>) => props.setDisplayPlayer((e.target as HTMLDivElement).id)}
					>{getDisplayName(v)}</div>
				</div>
			);
		} else {
			let remainder = [] as ReactElement[];
			if (Array.isArray(v)) {
				remainder = v.slice(1) as ReactElement[];
				v = v[0];
			}
			return <>
				<div className={styles.row} key={['row', i].join('.')}>
					<div className='fieldBox'>
						{capitalise(k === 'chessCom' ? 'Chess.com' : k) + ':'}
					</div>
					{React.isValidElement(v) ?
						v :
						k.toLowerCase() === 'rating' || canEdit ?
							<EditableField
								content={typeof v === 'boolean' ? booleanConvert(v) : v as string}
								name={k}
								inputType={typeof v === 'number' ? 'number' : 'text'}
								url={server + ['tournament', props.status.id, 'updatePlayer'].join('/')}
								onSubmitSuccess={() => updatePlayerFromProps(true)}
								classNames={{ editableField: 'fieldBox' }}
								show={{ submit: true, clear: true }}
								additionalInputProps={{ id: player.id }}
							>
								<input type='hidden' name='id' value={player.id} />
							</EditableField> :
							<div className='fieldBox'>
								{v !== null && v!== '' ? v : '\u200b'}
							</div>
					}
					{remainder.length && remainder.some(v => v !== undefined) ?
						<button
							type='button'
							value='▼'
							className={cx(styles.button, styles.increment)}
							onClick={toggleHeight}
							title='Get Code'
						>
							<FaCaretDown />
						</button> :
						null
					}
				</div>
				{remainder}
			</>;
		}
	}, [props.setDisplayPlayer, getDisplayName, player]);

	const maxPool = useMemo(() => {
		return players.reduce((acc, curr) => {
			if (!player) return acc;
			if (curr.id === player.id) return acc;
			if (curr.pool && curr.pool > acc) return curr.pool;
			return acc;
		}, 0);
	}, [players, player]);
	
	const profile = !player ? {} : {
		'Unique ID': player.id,
		Status: player.active !== false ? 'Active' : 'Inactive',
		Rating: player.rating,
		Nationality: <EditableField
			content={player.nationality}
			url={server + ['tournament', props.status.id, 'updatePlayer'].join('/')}
			inputType='text'
			onSubmitSuccess={() => updatePlayerFromProps(true)}
			handleClear={() => POST({
				url: px('tournament', props.status.id, 'updatePlayer'),
				data: {
					id: player.id,
					nationality: null
				}
			})}
			classNames={{ editableField: 'fieldBox' }}
			name='nationality'
			additionalInputProps={{ id: player.id }}
			show={{
				submit: true,
				clear: true
			}}
			search={{
				options: nationalities,
				getName: (p) => (p as {id: string, name: string}).name
			}}
		/>,
		Claimed: [
			booleanConvert(!!player.teamId) as string,
			player.teamId ? undefined : description
		] as [string, ...ReactElement[]],
		Pool: props.settings.usingPools ? <EditableField
			content={player.pool?.toString() || '0'}
			url={server + ['tournament', props.status.id, 'updatePlayer'].join('/')}
			inputType='number'
			onSubmitSuccess={() => updatePlayerFromProps(true)}
			classNames={{ editableField: 'fieldBox' }}
			name='pool'
			show={{
				any: true,
				increment: true,
				clear: true,
				submit: true
			}}
			additionalInputProps={{
				id: player.id,
				min: 1,
				max: maxPool + 1
			}}
		>
			<input type='hidden' name='id' value={player.id} />
		</EditableField> : undefined,
		'Performance rating': props.settings.showRatings ? player.performanceRating : undefined,
		Playing: player.isPlaying
	};

	let history = player?.histories;
	const total = !player ? -1 : (Math.max(player.histories.length, 1) - 1 - (player.isPlaying ? 1 : 0)).toString();
	const totalGames = !player ? -1 : player.histories.reduce((acc, curr) => {
		if (!curr) return acc;
		acc += curr.played;
		return acc;
	}, 0);
	const scores = !player ? {} : {
		Score: player.score.toString() + ' / ' + total,
		'Game score': player.gamePoints.toString() + ' / ' + totalGames.toString(),
		'Expected score': player.expectedScore.toFixed(2),
		'Expected game score': player.expectedGameScore.toFixed(2) + ' / ' + totalGames,
	};
	if (!props.settings.displayPoints) {
		delete scores['Game score'];
		delete scores['Expected game score'];
	}

	return (
		<>
			<div className={styles.profileContainer} style={{ display: player ? 'none' : undefined }}>
				<Search
					onSubmit={props.setDisplayPlayer}
					options={Array.from(props.playerDict.values())}
					getName={(id) => getDisplayName(id as any as string | Player)}
					styles={styles}
					showHistory
					cacheHistory
				/>
			</div>
			{!player ? null : <div className={[styles.profileContainer, 'scrollable'].join(' ')}>
				<div className='sectionContainer'>
					<div className={styles.infoSection}>
						<div className={styles.row}>
							<EditableField
								content={getDisplayName(player)}
								name='name'
								inputType='text'
								url={server + ['tournament', props.status.id, 'updatePlayer'].join('/')}
								onSubmitSuccess={() => updatePlayerFromProps(true)}
								additionalInputProps={{ id: player.id }}
								classNames={{ container: ['playerInfo', styles.name].join(' ') }}
								show={{ submit: true }}
							>
								<input type='hidden' name='id' value={player.id} />
							</EditableField> 
						</div>						
						{Object.entries(profile).map(renderEntry)}
					</div>
				</div>
				<CollapsableSection
					header='Contact'
					useInternalState={true}
					style={{ gridColumn: '1 / span 2' }}
				>
					{Object.entries(Object.assign({
						lichess: player.lichess,
						chessCom: player.chessCom,
						facebook: player.contact?.facebook,
						twitter: player.contact?.twitter,
						phone: player.contact?.phone,
						email: player.contact?.email,
					}, player.contact || {})).map((e, i, arr) => renderEntry(e as [string, string], i, arr, true))}
				</CollapsableSection>
				{player.meta ? <CollapsableSection
					header='Information'
					useInternalState={true}
					style={{ gridColumn: '1 / span 2' }}
				>
					{Object.entries(player.meta).concat([['notes', undefined]]).map((e, i, arr) => renderEntry(e, i, arr, true))}
				</CollapsableSection> : null}
				<CollapsableSection
					header='Score'
					useInternalState={true}
					style={{ gridColumn: '1 / span 2' }}
				>
					{Object.entries(scores).map(renderEntry)}
				</CollapsableSection>
				{props.settings.allowClashes ? <CollapsableSection
					header='Forbidden Pairings'
					useInternalState={true}
					style={{ gridColumn: '1 / span 2' }}
				>
					<Clashes {...props} players={players} player={player} />
				</CollapsableSection> : null}
				<CollapsableSection
					header='Results'
					useInternalState={true}
					style={{ gridColumn: '1 / span 2' }}
				>
					{history.map((minPlayer: History, i: number): ReactElement => {
						if (i === 0 || !minPlayer) return null;
						let { id, colour } = minPlayer;
						let result = minPlayer[props.settings.displayPoints];
						return (
							<div className={styles.resultsRow} key={['row', 'history', i].join('.')}>
								<div className='fieldBox'>{i}</div>
								<div
									className='playerInfo'
									id={id}
									onClick={(e: React.MouseEvent<HTMLDivElement>) => props.setDisplayPlayer((e.target as HTMLDivElement).id)}
								>{getDisplayName(id)}</div>
								<div className='fieldBox'>{colour || '\u200b'}</div>
								<div className='fieldBox'>{typeof result !== 'undefined' && result !== null ? result : '\u200b'}</div>
							</div>
						);
					})}
				</CollapsableSection>
				{props.settings.allowBonuses ? <CollapsableSection
					header='Bonus points'
					useInternalState={true}
					style={{ gridColumn: '1 / span 2' }}
				>
					<Bonuses {...props} players={players} player={player} update={updatePlayerFromProps}/>
				</CollapsableSection> : null}
			</div>}
		</>
	);
}

Profile.whyDidYouRender = true;