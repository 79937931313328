import React, { ReactElement, useState } from 'react';
import { TournamentSettings, TournamentStatus, Player } from '../../interfaces';

import styles from 'css/profile.module.css';
import { defaultSettings, TournamentSetting } from 'resources/settings';
import { capitalise } from 'utils/prototype';
import CollapsableSection, { SectionBreak } from 'components/CollapsableSection';
import { useEditable } from 'components/EditableField';

export interface SettingsProps {
	updateTournamentSettings: () => Promise<void>
	updateState: () => void
	updateSetting: (k: string, v: any, def?: TournamentSetting<string | number | boolean>) => Promise<void>
	updateRounds: () => Promise<void>
	status: TournamentStatus
	settings: TournamentSettings
	setLastRound: (override: boolean) => void // need to include a method to cancel a last round token when the total rounds is incremented
	triggerError: (message: string) => void
	players: Player[]
	rounds: Date[]
}
export default function Automation(props: SettingsProps): ReactElement {

	const [sectionExpando, setSection] = useState('automation');
	const { SettingsRow } = useEditable(props);

	const section = 'automation';
	return (
		<>
			<div className={[styles.profileContainer, 'scrollable'].join(' ')}>
				<CollapsableSection
					key={section}
					value={section}
					header={capitalise(section)}
					expanded={sectionExpando === section}
					setExpanded={(state: boolean, action: string) => setSection(state ? action : '')}
				>
					{(Object.entries(defaultSettings.automation) as [keyof TournamentSettings, TournamentSetting<string | number | boolean>][])
						.map(([k, v], i) => <SettingsRow key={['k', i].join('.')} k={k} def={v} i={i} />)}
				</CollapsableSection>
				<SectionBreak />
			</div>
		</>
	);
}