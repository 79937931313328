import React, { ReactElement, useState, useEffect, CSSProperties, useCallback, useReducer, useMemo } from 'react';
import cx from 'classnames';
import { InfoBar, Pairings, Sidebar, Profile, Switch, Dock } from './tournament/';
import { usePassable, useCacheColumns, useMouseEvents, useModes, usePlayerDict, useLoad } from './tournament/hooks/';
import { Settings, Automation, Public } from './tournament/Rightbar';
import { Player, Modes, NextButtonStates } from './interfaces';

import styles from './css/tournament.module.css';
import TabSection from './components/TabSection';
import Contexts, { DisplayNameContext, LoadedContext, SetModeContext, SettingsContext, NextButtonContext, PlayerDictContext } from './Contexts';
import { FaUserCircle, FaGlobeEurope, FaCog, FaRobot } from 'react-icons/fa';
import Banner from './tournament/Rightbar/Banner';
import { FullScreen, PasteInput, Announcement, CrossTable, Export as ExportComponent, Knockout } from './screen/';
import Sort from './screen/Sort';
import RobinPairings from './screen/RobinPairings';

export const defaultColumns = [30.8, 38.4, 30.8];

interface TournamentProps {
	id: string
	closeTournament: () => void
	triggerError: (message: string) => void
	triggerConfirm: (message: string, resolve: () => void, reject: () => void) => void
	endSession: () => void
	setTournamentID: (id: string) => void
	setMode: (mode: Modes) => void
	checkForUpdates: () => Promise<void>
}

export default function Tournament(props: TournamentProps): ReactElement {

	const shouldRun = useMemo(() => !!props.id, [props.id]);

	const {
		columns, setColumns, boundaries, loadColumns, cacheColumns		
	} = useCacheColumns(shouldRun);
	useEffect(() => {
		loadColumns();
	}, [loadColumns]);
	useEffect(() => {
		cacheColumns();
	}, [cacheColumns]);

	const {
		mode, setMode, switchMode, switchPairings,
		stateKey, setStateKey, rightTab, setRightTab, showRightTab, toggleRightTab,
		sidebar, toggleSidebar, displayPlayer, setDisplayPlayer
	} = useModes({
		columns, setColumns, boundaries
	});

	useMouseEvents({
		columns, boundaries, setColumns, toggleSidebar, toggleRightTab, shouldRun: shouldRun && !mode
	});

	const {
		players, setDictTimeout, playerDict, setPlayerDict, updatePlayerDict
	} = usePlayerDict({
		id: props.id, displayPlayer, setDisplayPlayer
	});

	const {
		status, settings, announcements, rounds, load,
		updateTournamentStatus, updateTournamentSettings, updateSetting, updateRounds, updateAnnouncements
	} = useLoad({
		id: props.id, updatePlayerDict
	});

	const {
		nextRound, checkIfEnoughPlayers, lastRoundOverride, setLastRound, getDisplayName, search, currSearch,
		filteredPairings, filterPairings, userRound, setRound, pairingFilters, setFilters,
		outstandingIndex, nextOutstanding, canSetOutstanding, setOutstanding
	} = usePassable({
		players, playerDict
	});
    
	useEffect(() => {
		if (!setDictTimeout) return;
		let x = setTimeout(updatePlayerDict, 5000);
		return () => clearTimeout(x);
	}, [setDictTimeout, updatePlayerDict]);
	useEffect(() => {
		load();
	}, [load]);

	const style: CSSProperties = useMemo(() => {
		let gridTemplateColumns = `70px ${columns[0]}fr 0.5px ${columns[1]}fr 0.5px ${columns[2]}fr`;
		let s = { gridTemplateColumns };
		return s;
	}, [columns]);

	const [nextButton, setNextButton] = useState('skeleton' as NextButtonStates);

	if (mode) {
		const id = props.id;
		const leave = () => setMode();
		return (
			<Contexts values={[
				[SettingsContext, settings],
				[PlayerDictContext, playerDict],
				[SetModeContext, setMode],
				[DisplayNameContext, getDisplayName]
			]}>
				<div className={styles.background}>
					<div className={styles.tournament}>
						{(() => {
							switch (mode) {
							case 'settings':
								return <FullScreen {...{leave, updateSetting, status, settings, setLastRound, players, updateRounds, rounds, updateTournamentSettings}}
									triggerError={props.triggerError}
									updateState={() => setStateKey(Math.random().toString(16).slice(2, 9))}	
								/>;
							case 'crossTable':
								return <>
									<CrossTable {...{leave, status, players, settings, setDisplayPlayer}}
										rightTab={showRightTab}
										toggleRightTab={toggleRightTab}
										closeTabOnLoad={false}
									/>
									{showRightTab ? 
										<div className={styles.profile}>
											<TabSection
												name={rightTab}
												setName={(name: string) => setRightTab(name as any as 'settings' | 'player' | 'public')}
												nameDict={{
													player: {
														icon: FaUserCircle,
														name: 'Profile'
													}
												}}
											/>
											<Profile {...{id, displayPlayer, status, setDisplayPlayer, playerDict, settings, setPlayerDict}}/>
										</div> :
										null}
								</>;
							case 'pasteInput':
								return <PasteInput {...{
									id, leave, status, settings, updatePlayerDict, players}} />;
							case 'announcements':
								return <Announcement
									id={id}
									initialA={announcements.length - 1}
									prev={announcements}
									leave={() => {
										updateAnnouncements();
										setMode('');
									}}
								/>;
							case 'pairings':
								return <RobinPairings />;
							case 'export':
								return <ExportComponent {...{players, status}} />;
							case 'knockout':
								return <Knockout {...{id, status, settings, setDisplayPlayer, leave}} />;
							case 'sort':
								return <Sort {...{players, updatePlayerDict}} />;
							default:
								return null;
							}
						})()}
					</div>
				</div>
			</Contexts>
		);
	}

	return (
		<Contexts values={[
			[SettingsContext, settings],
			[PlayerDictContext, playerDict],
			[SetModeContext, setMode],
			[LoadedContext, !!settings.pairingSystem],
			[DisplayNameContext, getDisplayName],
			[NextButtonContext, { nextButton, setNextButton }],
		]}>
			<div className={cx(styles.tournament)} style={style}>
				<div id={styles.left} className={styles.resizer} />
				<div id={styles.right} className={styles.resizer} />
				{!sidebar ? null :
					<Sidebar
						id={props.id}
						jumpedTo={currSearch}
						{...{playerDict, updatePlayerDict, setPlayerDict,
							settings, setDisplayPlayer, checkIfEnoughPlayers, updateSetting, stateKey}}							
					/>}
				<Dock
					{...{setMode, userRound, load, status, settings, setLastRound, lastRoundOverride, switchMode,
						switchPairings, setFilters, pairingFilters,
						players, updatePlayerDict }}
					closeTournament={props.closeTournament}
					checkForUpdates={props.checkForUpdates}						
					triggerError={props.triggerError}
					canReset={!columns.every((c, i) => defaultColumns[i] === c)}
					resetColumns={() => {
						setColumns(defaultColumns);
						toggleRightTab(true);
						toggleSidebar(true);
					}}
				/>
				<InfoBar
					{...{userRound, setRound, filterPairings, status, settings, updateTournamentStatus,
						lastRoundOverride, players, switchPairings}}
					totalRounds={settings.totalRounds}
					search={(id: string) => {
						search(id);
						setDisplayPlayer(id);
					}}
				/>
				<Pairings
					{...{ switchMode, setMode, userRound, status, playerDict, updatePlayerDict, settings, toggleSidebar,
						toggleRightTab, setDisplayPlayer, updateTournamentSettings, updateSetting, setLastRound,
						lastRoundOverride, outstandingIndex, setOutstanding, nextOutstanding}}
					updateStatus={updateTournamentStatus}
					id={props.id}
					canTriggerNextRound={nextRound}
					updateRound={updateTournamentStatus}
					triggerConfirm={props.triggerConfirm}
					filtered={filteredPairings}
					filter={filterPairings}
					filters={pairingFilters}
					jumpedTo={currSearch}
				/>
				{showRightTab ? <>
					<div className={styles.profile}>
						<div className={styles.profileStage}>
							<TabSection
								name={rightTab}
								setName={(name: string) => setRightTab(name as any as 'settings' | 'player' | 'public')}
								nameDict={{
									settings: {
										icon: FaCog,
										color: 'lightgrey',
										name: 'Settings'
									},
									player: {
										icon: FaUserCircle,
										color: '#385898',
										name: 'Profile'
									},
									automation: {
										icon: FaRobot,
										color: 'gold'
									},
									public: {
										icon: FaGlobeEurope,
										color: 'green'
									}
								}}
							/>
							{
								rightTab === 'settings' ?
									<Settings
										{...{updateTournamentSettings, updateSetting, status, settings, setLastRound,
											players, updateRounds, rounds}}
										triggerError={props.triggerError}
										updateState={() => setStateKey(Math.random().toString(16).slice(2, 9))}	
									/> :
									rightTab === 'player' ?
										<Profile
											{...{status, setDisplayPlayer, playerDict, settings}}
											id={props.id}
											displayPlayer={displayPlayer}
											setPlayerDict={setPlayerDict}
										/>
										:
										rightTab === 'public' ?
											<Public
												{...{updateTournamentSettings, updateSetting, status, settings, setLastRound,
													players, updateRounds, rounds}}
												triggerError={props.triggerError}
											/> :
											rightTab === 'automation' ?
												<Automation
													{...{updateTournamentSettings, updateSetting, status, settings,
														setLastRound, players, updateRounds, rounds}}
													triggerError={props.triggerError}
													updateState={() => setStateKey(Math.random().toString(16).slice(2, 9))}	
												/> :
												null
							}
						</div>
					</div>
					<Banner
						id={props.id}
						displayPlayer={rightTab === 'player' ? displayPlayer : ''}
						clearDisplayPlayer={() => setDisplayPlayer('')}
						modifyPlayerDict={(player: Player, hash: string) => setPlayerDict({ player, hash })}
					/>
				</>: null}
			</div>
		</Contexts>
	);

}

//Tournament.whyDidYouRender = true;