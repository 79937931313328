import React, { Dispatch, SetStateAction, useState, useCallback, useReducer } from 'react';
import Player from 'models/player';
import { TournamentSettings, DockOption } from '../../interfaces';
import { filterPairingOptions } from '../Dock';

/**
 * Here's a custom hook for all of the 'passable' variables,
 * i.e. the stuff that shold be stored in state on Tournament
 * in order to pass between LO components
 * but Tournament is getting too crampt
 */
export function usePassable({ 
	players,
	playerDict,
}: {
	players: Player[]
	playerDict: Map<string, Player>
}) {
	
	const [userRound, setRound] = useState(0);

	const [nextRound, toggleNextRound] = useState(false);
	const checkIfEnoughPlayers = useCallback(async (): Promise<void> => {
		toggleNextRound(players.filter(p => p.active !== false).length >= 2);
	}, [players, toggleNextRound]);

	
	const [currSearch, search] = useState('');
	const [filteredPairings, filterPairings] = useState([] as string[]);

	const [lastRoundOverride, setLastRound] = useState(undefined) as [boolean | undefined, Dispatch<SetStateAction<boolean | undefined>>];

	const getDisplayName = useCallback((id: string | Player, options: string[] = []): string => {

		if (!id) return '\u200b';
		if (id === 'bye') return 'BYE';

		let name: string;
		let opp: Player;

		if (typeof id !== 'string') {
			opp = id as Player;
			if (opp.id === 'bye' || opp.id === 'BYE') return 'BYE';
		} else {
			opp = playerDict.get(id);
			if (!opp) return id;
		}
		name = opp.name.trim();
		
		const addOns = {
			rating: `(${opp.rating})`,
			score: `[${opp.score}]`
		};
		return [
			name,
			Object.entries(addOns).filter(([k]) => options.includes(k)).map((arr) => arr[1])
		].join(' ');
	}, [playerDict]);

	const [pairingFilters, setFilters] = useReducer((state: { [key: string]: boolean | undefined }, curr: { [key: string]: boolean | undefined }) => {
		let r = Object.assign({}, state, curr);
		return r;
	}, filterPairingOptions.reduce((acc: { [key: string]: boolean | undefined }, curr: DockOption) => {
		if ('defaultValue' in curr) acc[curr.key] = curr.defaultValue;
		else acc[curr.key] = undefined;
		return acc;
	}, {}));

	const [canSetOutstanding, setOutstanding] = useState(true);
	const [outstandingIndex, nextOutstanding] = useReducer(state => state + 1, -1);

	return {
		nextRound,
		checkIfEnoughPlayers,
		lastRoundOverride,
		setLastRound,
		getDisplayName,
		search,
		currSearch,
		filteredPairings,
		filterPairings,
		userRound,
		setRound,
		pairingFilters,
		setFilters,
		outstandingIndex,
		nextOutstanding,
		canSetOutstanding,
		setOutstanding
	};
}