import React, { ReactElement, useState, useMemo, useContext } from 'react';
import cx from 'classnames';
import { TournamentSettings, TournamentStatus, Player } from '../../interfaces';

import styles from 'css/profile.module.css';
import { defaultSettings, TournamentSetting } from 'resources/settings';
import { capitalise } from 'utils/prototype';
import CollapsableSection, { SectionBreak } from 'components/CollapsableSection';
import Draggable from 'components/DraggableRows';
import { TieBreaks } from 'resources/tiebreaks';
import { useEditable } from 'components/EditableField';
import { LoadedContext } from '../../Contexts';

export interface SettingsProps {
	updateTournamentSettings: () => Promise<void>
	updateState: () => void
	updateSetting: (k: string, v: any, def?: TournamentSetting<string | number | boolean>) => Promise<void>
	updateRounds: () => Promise<void>
	status: TournamentStatus
	settings: TournamentSettings
	setLastRound: (override: boolean) => void // need to include a method to cancel a last round token when the total rounds is incremented
	triggerError: (message: string) => void
	players: Player[]
	rounds: Date[]
}
export default function Settings(props: SettingsProps): ReactElement {

	const [sectionExpando, setSection] = useState('core');
	const { SettingsRow } = useEditable(Object.assign({}, props, {mount: !!props.status.id}));
	const hasLoaded = useContext(LoadedContext);

	const tieBreaks = useMemo(() => {
		let entries = Object.entries(TieBreaks);
		if (props.settings.tieBreaks) {
			let arr = props.settings.tieBreaks.split(',');
			entries = entries.sort((a, b) => {
				if (!arr.includes(a[0])) return 1;
				if (!arr.includes(b[0])) return -1;
				return arr.indexOf(a[0]) - arr.indexOf(b[0]);
			});
		}
		return entries;
	}, [props.settings.tieBreaks]);

	return (
		<>
			<div className={[styles.profileContainer, 'scrollable', !hasLoaded ? styles.skel : ''].join(' ')}>
				{Object.entries(defaultSettings).map(([section, data]): ReactElement => {
					if (section.startsWith('public')) return null;
					if (section.startsWith('automation')) return null;
					return (
						<CollapsableSection
							key={section}
							value={section}
							header={capitalise(section)}
							expanded={sectionExpando === section || !props.status.id}
							setExpanded={(state: boolean, action: string) => setSection(state ? action : '')}
						>
							{(Object.entries(data) as [keyof TournamentSettings, TournamentSetting<string | number | boolean>][])
								.map(([k, v], i) => {
									if (!props.status.id && i > 3) return null;
									return <SettingsRow key={['k', i].join('.')} k={k} def={v} i={i} />;
								})}
						</CollapsableSection>
					);
				})}
				<CollapsableSection
					value='tiebreaks'
					header='Tie Breaks'
					expanded={sectionExpando === 'tiebreaks'}
					setExpanded={(state: boolean, action: string) => setSection(state ? action : '')}
				>
					<Draggable
						className={styles.tieBreaks}
						onSubmit={(v: string[]) => props.updateSetting('tieBreaks', v.join(',')).then(props.updateState)}
						numbers
					>
						{tieBreaks.map(([k, v]) => {
							if (!v) return <div key={k} id={k} className={cx('fieldBox', styles.divider)}>
								--- IGNORE ALL BELOW TIEBREAKS ---
							</div>;
							return <div key={k} id={k} className={cx('fieldBox', styles.tb)}>
								{v.name}
							</div>;
						})}
					</Draggable>
				</CollapsableSection>
				<SectionBreak />
			</div>
		</>
	);
}