import React, { useReducer, useRef, useEffect, useCallback, useState, ReactElement, DispatchWithoutAction, Dispatch, useMemo } from 'react';
import cx from 'classnames';
import styles from 'screen/css/dropdown.module.css';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { capitalise } from 'utils/prototype';

export default function Dropdown<T extends string>(props: {
	current: T
	options: {[key in T]: string} | {[key in T]: { name: string, hide?: string }} | (T | null)[]
	resolve: (v: T) => void
	title?: string
	headerIcon?: ReactElement | string
	expanded?: boolean
	className?: string
}) {

	const [expanded, setExpanded] = useReducer((state, action?: boolean) => action ?? !state, !!props.expanded) as [boolean, (v?: boolean) => void];
	const [height, setHeight] = useState(0);
	const list = useRef() as React.RefObject<HTMLDivElement>;
	const ul = useRef() as React.RefObject<HTMLUListElement>;
	
	const updateHeight = useCallback(() => {
		if (!ul.current) return;
		setHeight(expanded ? ul.current.getBoundingClientRect().height : 0);
	}, [setHeight, expanded, ul]);
	useEffect((): void => {
		updateHeight();
	}, [updateHeight]);

	const handleClick  = useCallback((e: MouseEvent) => {
		if (!list.current) return;
		if (list.current !== e.target && !list.current.contains(e.target as Node)) setExpanded(false);
	}, [list, setExpanded]);
	useEffect(() => {
		document.addEventListener('click', handleClick);
		return () => document.removeEventListener('click', handleClick);
	}, [handleClick]);

	const curr: { name: string, hide?: boolean } = useMemo(() => {
		if (Array.isArray(props.options)) return {
			name: capitalise(props.current)
		};
		let x =	props.options[props.current] as string | { name: string, hide?: boolean };
		if (typeof x === 'string') return {
			name: x
		};
		return x as { name: string, hide?: boolean };
	}, [props.options, props.current]);
	return (
		<div className={cx(styles.container)} ref={list}>
			<div className={cx('button', styles.header, props.className)} onClick={() => setExpanded()}>
				<FaAngleRight className={styles.toggle} style={{
					transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)'
				}}/>
				{props.headerIcon}
				{props.title || curr?.name}
			</div>
			<div className={styles.wrapper} style={{ height }}>
				<ul className={styles.list} ref={ul}>
					{Object.entries(props.options).map(([k, v], i) => {
						if (!v) return null;
						let l = v as string | { name: string, hide: boolean };
						if (typeof l === 'object' && l.hide) return null;
						return <li key={[l, i].join('.')} onClick={(e) => {
							if (Array.isArray(props.options)) props.resolve(v);
							else props.resolve(k as T);
							setExpanded();
							e.stopPropagation();
						}}>
							{typeof l === 'string' ?
								Array.isArray(props.options) ?
									capitalise(l) :
									l :
								l.name
							}
						</li>;
					})}
				</ul>
			</div>
		</div>
	);
}