import React, { useMemo, ReactElement, useState, useCallback, useEffect, useContext, FormEvent } from 'react';
import url from 'url';
import { ProfileProps } from '.';
import { Result, Player } from '../../../interfaces';
import { GET, POST, server } from 'utils/requests';
import { SingleClash } from 'components/SingleClash';
import { DisplayNameContext } from '../../../Contexts';
import { AxiosRequestConfig } from 'axios';
import styles from 'css/profile.module.css';
import sidebarStyles from 'css/sidebar.module.css';
import { AiOutlineClose } from 'react-icons/ai';
import { px } from 'utils/prototype';
interface ActiveProps {
	player: Player
    clearClash: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    setDisplayPlayer: (id: string) => void
	requireLastName: boolean
}

export function ActivePlayer(props: ActiveProps): ReactElement {

	const displayName = props.requireLastName ?
		[props.player.firstName, props.player.lastName].join(' ') :
		props.player.firstName;
	
	return (
		<div id={props.player.id} className={[sidebarStyles.playerLine, styles.playerLine].join(' ')}>
			<div
				className={['playerInfo'].join(' ')}
				onClick={(e: React.MouseEvent<HTMLDivElement>) => props.setDisplayPlayer((e.target as HTMLDivElement).parentElement.id)}
			>
				{displayName}
			</div>
			{
				<button name='cancel' className={[sidebarStyles.cancel, styles.cancel].join(' ')} onClick={(props.clearClash)}>
					<AiOutlineClose />
				</button>
			}
		</div>
	);

}

export default function Clashes(props: ProfileProps & { player: Player, players: Player[] }) {

	const [clashes, setClashes] = useState(props.player.clashList || []);
	const requireLastName = useMemo(() => props.settings.competitors === 'individual', [props.settings.competitors]);
	const getDisplayName = useContext(DisplayNameContext);

	const updateClashes = useCallback(async () => {
		if (!props.player || !props.id || !props.player.id) return;
		GET({
			url: px('tournament', props.id, 'fetchClashes'),
			params: { id: props.player.id }
		})
			.then(setClashes);
	}, [props.id, setClashes, props.player]);

	const clearClash = useCallback(async (oppId) => POST({
		url: '/tournament/' + props.id + '/clearClash',
		data: {
			playerId: props.player.id,
			oppId
		}
	})
		.then(updateClashes), [props.id, props.player, updateClashes]);

	const List = useMemo((): ReactElement[] => {
		if (!props.playerDict.size) return null;
		let list = [] as ReactElement[];
		for (let i = 0; i < clashes.length; i++) {
			let opp = props.playerDict.get(clashes[i]);
			if (!opp) continue;
			list.push(<ActivePlayer
				key={['clash', i].join('.')}
				player={opp}
				requireLastName={requireLastName}
				clearClash={() => clearClash(opp.id)}
				setDisplayPlayer={props.setDisplayPlayer}
			/>);
		}
		return list;
	}, [props.playerDict, clashes, clearClash, props.setDisplayPlayer, requireLastName]);

	return <>
		{List}
		<SingleClash
			id={props.id}
			getDisplayName={getDisplayName}
			onSubmit={(e: FormEvent<HTMLFormElement>): Promise<void> => {
				e.preventDefault();
				e.persist();
				let form = e.target as HTMLFormElement & { oppName: HTMLInputElement };
				let opp = props.players.find(p => getDisplayName(p.id) === form.oppName.value);
				let options = {
					data: {
						playerId: props.player.id,
						oppId: opp.id
					},
					url: form.action
				} as AxiosRequestConfig;
				return POST(options)
					.then(() => {
						let form = e.target as HTMLFormElement;
						form.reset();
					})
					.then(updateClashes);
			}}
			player={props.player}
			players={props.players}
			action={url.resolve(server, ['tournament', props.id, 'createClash'].join('/'))}
		/>
	</>;

}