import React, { ReactElement, useContext } from 'react';
import cx from 'classnames';
import styles from 'css/components.module.css';
import { IconProps } from '../interfaces';
import useIcon from 'hooks/useIcon';
import { capitalise } from 'utils/prototype';
import { LoadedContext } from '../Contexts';

interface TabProps {
	name: string
	nameDict: {[key: string]: Partial<IconProps>}
	setName: (name: string) => void
	static?: boolean
	alwaysShow?: boolean
}

export default function TabSection(props: TabProps): ReactElement {
	
	const hasLoaded = useContext(LoadedContext);

	const { Icon } = useIcon({ hasLoaded });

	return (				
		<div className={styles.tabSection} style={{gridAutoColumns: props.static ? '1fr' : undefined}}>
			{Object.entries(props.nameDict).map(([k, v], i, arr) => {
				if (!v || v.hide) return null;

				let name = v.name;
				if (hasLoaded === false) name = '\u200b';
				else if (k !== props.name && !props.alwaysShow) name = '\u200b';
				else if (!name) name = capitalise(k);

				let icon = v.icon;
				if (!icon) icon = null;
				if (typeof icon !== 'string') icon = <Icon name={name} {...v as any} />;

				return <div
					key={[k, i].join('.')}
					onClick={() => props.setName(k as string)}
					className={cx(styles.tab, {
						[styles.alone]: arr.filter(v => v[1]).length === 1,
						[styles.active]: k === props.name
					})}
				>
					{icon}
					{name}
				</div>;
			})}
		</div>
	);
}