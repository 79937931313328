import React, { ReactElement, useEffect, useCallback, useState, useMemo, useRef } from 'react';
import cx from 'classnames';
import appStyles from 'css/app.module.css';
import profileStyles from 'css/profile.module.css';
import styles from 'css/menu.module.css';

import { User, TournamentStatus, Modes, IconProps, TournamentSettings } from '../interfaces';
import { Crown } from './Login';
import { GET, POST, cookies } from 'utils/requests';
import { AxiosError } from 'axios';
import { calculateHypotenuse, useStateWithLabel } from 'utils/prototype';
import { defaultUserSettings } from 'utils/userSettings';

import { IoIosCloseCircle } from 'react-icons/io';
import { MdExitToApp, MdRefresh } from 'react-icons/md';
import { FaAngleLeft, FaBars, FaBomb, FaCog, FaEarlybirds, FaFolderOpen, FaPlus, FaSitemap, FaSyncAlt, FaTimes, FaUndo, FaUndoAlt } from 'react-icons/fa';
import TabSection from 'components/TabSection';
import EditableForm from 'components/EditableField';
import useIcon from 'hooks/useIcon';
import { isDev } from 'utils/electron';

function getElementCentre(elem: HTMLElement): {x: number, y: number} {
	const rect = elem.getBoundingClientRect();
	return {
		x: (rect.left + rect.right) / 2,
		y: (rect.top + rect.bottom) / 2 - (document.getElementById('statusBar')?.getBoundingClientRect().height ?? 0)
	};
}

function renderLineBetween(linesMounted: boolean, start: {x: number, y: number}, end: {x: number, y: number}, width?: number): ReactElement {
	let diff = {x: Math.abs(end.x - start.x), y: Math.abs(end.y - start.y)};
	let length = calculateHypotenuse(end.x - start.x, end.y - start.y);

	let sign = Math.sign((end.x - start.x) * (end.y - start.y));
	if (!sign) sign = 1;
	let angle = Math.atan(diff.y / diff.x) * 180 / Math.PI;
	return <div key={['line', end.x, end.y].join('.')} className={styles.line} style={{
		width: length + 'px',
		height: width,
		left: !linesMounted ? start.x - (length / 2) : ((start.x + end.x) / 2) - (length / 2),
		top: !linesMounted ? start.y : ((start.y + end.y) / 2),
		transform: `rotate(${angle * sign}deg) scaleX(${linesMounted ? 1 : 0}) scaleY(1)`,
		opacity: !linesMounted ? 0 : 1
	}}/>;
}

interface MenuProps {
	endSession: () => Promise<void>
	setTournamentID: (id: string) => void
	triggerConfirm: (message: string, resolve: () => void, reject: () => void) => void
	user: User
	updateUser: () => void
	setMode: (mode: Modes) => void
	setDefaultSettings: (s: Partial<TournamentSettings>) => void
}

export default function Menu(props: MenuProps): ReactElement {

	const [tournamentDict, setTournamentDict] = useState({} as {[key: string]: TournamentStatus});
	const [loading, setLoading] = useStateWithLabel(true, 'loading');
	const [loadingOverride, overrideLoading] = useStateWithLabel(false, 'loadingOverride');
	const [linesMounted, mountLines] = useStateWithLabel(false, 'linesMounted');
	const [linesAdjusted, adjustLines] = useStateWithLabel(false, 'adjustedLines');
	const [selected, select] = useStateWithLabel('', 'selected');

	const updateTournamentDict = useCallback((): Promise<{[key: string]: TournamentStatus}> => {
		return GET({ url: '/profile/tournaments' })
			.then((tournList: TournamentStatus[]) => {
				let tournDict = tournList.reduce((acc, curr) => {
					acc[curr.id] = curr;
					return acc;
				}, {} as {[key: string]: TournamentStatus});
				setTournamentDict(tournDict);
				return tournDict;
			})
			.catch((e: Error) => {
				console.error(e);
				props.endSession();
				return {} as {[key: string]: TournamentStatus};
			});
	}, [setTournamentDict, props.endSession]);

	useEffect(() => {
		updateTournamentDict();
	}, [updateTournamentDict]);

	const validateTournament = useCallback((tournamentID: string): Promise<void> => {
		if (!tournamentID) return Promise.reject();
		if (!Object.values(tournamentDict).length) {
			return updateTournamentDict()
				.then((tournamentDict: {[key: string]: TournamentStatus}) => {
					if (tournamentDict[tournamentID] === undefined) throw new Error();
					return;
				});
		} else return tournamentID in tournamentDict ? Promise.resolve() : Promise.reject();
	}, [tournamentDict, updateTournamentDict]);

	const setTournamentID = useCallback((tournamentID: string): Promise<void> => {
		return validateTournament(tournamentID)
			.then(() => {
				cookies.set('tournamentID', tournamentID, { path: '/' }).catch(console.error);
				props.setTournamentID(tournamentID);
			})
			.catch(() => {
				cookies.remove('tournamentID').catch(console.error);
				props.setTournamentID('');
			});
	}, [validateTournament, props.setTournamentID]);

	const createNewTournament = useCallback(async (): Promise<void> => {
		try {
			let id = await POST({ url: '/initialise' });
			if (!id) throw new Error();
			props.setTournamentID(id);
			await updateTournamentDict();
		} catch (error) {
			let e = error as AxiosError;
			if (!e.response || e.response.status === 401) props.endSession();
		}
	}, [props.setTournamentID, updateTournamentDict, props.endSession]);

	const removeID = useCallback(async (id?: string): Promise<void> => {
		if (!id) {
			await POST({ url: '/user/closeAuthorization/all' });
			setTournamentDict({});
		} else {
			let dict = Object.assign({}, tournamentDict);
			delete dict[id];
			await POST({ url: `/tournament/${id}/closeAuthorization` });
			setTournamentDict(dict);	
		}
	}, [tournamentDict, setTournamentDict]);

	const [lines, setLines] = useState([] as ReactElement[]);
	const logoRef = useRef() as React.RefObject<HTMLDivElement>;
	const drawLines = useCallback(() => {
		if (!logoRef.current) return;
		let startCoords = getElementCentre(logoRef.current);
		startCoords.x += 10;
		let circles = Array.from(document.getElementsByClassName(styles.icon)) as HTMLElement[];
		let _lines = [] as ReactElement[];
		for (let c of circles) {
			if (c.style.opacity === '0') continue;
			let centre = getElementCentre(c);
			_lines.push(renderLineBetween(linesMounted, startCoords, centre));
		}
		setLines(_lines);
	}, [logoRef, renderLineBetween, linesMounted]);
	useEffect(() => {
		drawLines();
		mountLines(true);
		let x = setTimeout(() => adjustLines(true), 3000);
		return () => clearTimeout(x);
	}, [drawLines, adjustLines, mountLines, selected]);

	const [last, setLast] = useState({ id: '', name: '' } as TournamentStatus);
	const loadLast = useCallback(async () => {
		let cachedString = await cookies.get('lastOpened');
		if (!cachedString) return;
		if (typeof cachedString === 'object') {
			setLast(cachedString as any);
		} else {
			try {
				let cached = JSON.parse(cachedString);		
				setLast(cached);
			} catch (e) {
				//await cookies.remove('lastOpened');
			}
		}
	}, [setLast]);
	useEffect(() => {
		loadLast();
	}, [loadLast]);

	const { Icon } = useIcon();

	const navData = useMemo(() => {
		return {
			create: {
				onClick: () => props.setMode('intro'),
				icon: FaPlus,
				name: 'New Swiss'
			},
			roundRobin: {
				onClick: () => {
					props.setDefaultSettings({ pairingSystem: 'round-robin' });
					props.setMode('intro');
				},
				icon: FaEarlybirds,
				name: 'New Round-Robin'
			},
			knockout: {
				onClick: () => {
					props.setDefaultSettings({ pairingSystem: 'knockout' });
					props.setMode('intro');
				},
				icon: FaSitemap,
				name: 'New Knockout'
			},
			settings: {
				icon: FaCog,
				name: 'User settings'
			},
			load: {
				icon: FaBars,
				name: 'Load previous tournaments'
			},
			back: {
				onClick: props.endSession,
				icon: MdExitToApp,
				name: 'Exit to login',
				style: {
					strokeWidth: 0.5
				}
			},
			last: {
				onClick: () => setTournamentID(last.id),
				icon: FaFolderOpen,
				name: last.name,
				hide: !last.id
			},
			close: {
				onClick: () => props.triggerConfirm('Delete all saved tournaments?', removeID, () => {}),
				noSet: true,
				icon: FaBomb,
				name: 'Clear all tournaments'
			},
			refresh: {
				onClick: updateTournamentDict,
				noSet: true,
				icon: FaSyncAlt,
				name: 'Refresh tournament list'
			},
		} as {[key: string]: IconProps & {noSet?: boolean}};
	}, [createNewTournament, updateTournamentDict, props.endSession, props.triggerConfirm, removeID, setTournamentID, last, select]);

	const handleNavData = useCallback((keys: string[], side: 'left' | 'right' | ''): ReactElement[] => {
		let data = keys.reduce((acc: {[key: string]: IconProps & {noSet?: boolean}}, curr: string) => {
			if (!(curr in navData)) return acc;
			acc[curr] = navData[curr];
			return acc;
		}, {});
		return Object.entries(data).reduce((acc, [id, meta], i) => {
			acc.push(<div
				className={[styles.dotContainer, linesAdjusted ? styles.dotContainerLoaded : ''].join(' ')}
				key={['NavData', side, 'div', i].join('i')}
			>
				<div
					key={cx(side, 'dot', i)}
					id={id}
					className={cx(styles.dot, {
						[styles.dotUnloaded]: !linesAdjusted,
						[styles.reverse]: side !== 'left'
					})}
					onClick={() => {	
						if (selected === id) select('');
						else {				
							if (meta.onClick) meta.onClick();
							if (!meta.noSet) select(id);
						}
					}}
					style={meta.style}
				>
					<div
						className={cx(styles.dotDescription, {[styles.descriptionSelected]: selected === id })}
						key={cx(side, 'description', i)}
					>
						{meta.name}
					</div>
					<Icon className={styles.icon} name={meta.name} icon={meta.icon} />
					
				</div>
			</div>);
			return acc;
		}, [] as ReactElement[]);
	}, [navData, linesAdjusted, selected, select]);

	const loadTournamentDict = useCallback(async () => {
		let tournamentID = await cookies.get('tournamentID');
		if (tournamentID) setTournamentID(tournamentID);
	}, [setTournamentID]);

	useEffect(() => {
		loadTournamentDict();
	}, [loadTournamentDict]);
	useEffect(() => {
		window.addEventListener('resize', drawLines);
		let f = () => setLoading(false);
		if (isDev) {
			f();
			return () => window.removeEventListener('resize', drawLines);
		} else {
			let x = setTimeout(f, 2000);
			return () => {
				window.removeEventListener('resize', drawLines);
				clearTimeout(x);
			};
		}
	}, [drawLines, setLoading]);

	const foundNavData = useMemo((): ReactElement[] => {
		switch (selected) {
		case 'load':
			return handleNavData(['refresh', 'close', 'load'], 'right');
		case 'settings':
			return handleNavData(['last', 'settings', 'load'], 'right');
		default:
			if (!selected) return handleNavData(['last', 'settings', 'load'], 'right');
		}
	}, [selected, handleNavData]);

	const foundProfile = useMemo(() => {
		if (!selected) return handleNavData(['create', 'roundRobin', 'knockout'], 'left');
		return (
			<div className={styles.container}>
				<div className={styles.profile}>
					<TabSection
						name={selected}
						setName={(name: string) => select(name)}
						nameDict={{
							settings: {
								name: 'User Settings',
								icon: FaCog
							},
							load: {
								name: 'Previous Tournaments',
								icon: FaBars
							}
						}}
					/>
					{selected === 'load' ?
						<Listing
							setTournamentID={setTournamentID}
							createNewTournament={createNewTournament}
							removeID={removeID}
							updateTournamentSettings={updateTournamentDict}
							tournamentDict={tournamentDict}
							closeListing={() => select('')}
							triggerConfirm={props.triggerConfirm}
						/> :
						selected === 'settings' ?
							<UserSettings
								user={props.user}
								updateTournamentSettings={() => Promise.resolve()}
								updateSetting={() => Promise.resolve()}
								closeListing={() => select('')}
								updateUser={props.updateUser}
							/>
							: null}
				</div>
			</div>
		);
	}, [select, selected, setTournamentID, createNewTournament, removeID, updateTournamentDict, tournamentDict, props.triggerConfirm, handleNavData, props.user]);

	return (
		<div className={cx(appStyles.canvas, {[styles.quick]: isDev})}>
			{lines}
			<div className={[appStyles.column, styles.leftColumn].join(' ')}>
				{foundProfile}
			</div>
			<div ref={logoRef} className={[appStyles.column, styles.column].join(' ')}>
				<div />
				<Crown setLoading={(l: boolean) => overrideLoading(l)} loading={loading || loadingOverride} classNames={{
					logo: styles.logo
				}}/>
				{handleNavData(['back'], '')}
			</div>
			<div className={[appStyles.column, styles.rightColumn].join(' ')}>
				{foundNavData}
			</div>
		</div>
	);

}

interface ListingProps {
	setTournamentID: (id: string) => void
	createNewTournament: () => Promise<void>
	removeID: (id: string) => void
	closeListing: () => void
	updateTournamentSettings: () => void
	tournamentDict: {[key: string]: TournamentStatus}
	triggerConfirm: (message: string, resolve: () => void, reject: () => void) => void
}

export function Listing(props: ListingProps) {

	const [height, setHeight] = useState(0);

	const generateListing = useCallback((tournamentDict: {[key: string]: TournamentStatus}) => {
		let buttons = Object.values(tournamentDict).map((curr: TournamentStatus, i: number) => {
			let displayName = curr.name || curr.id;
			return (
				<div className={[profileStyles.row, styles.row].join(' ')} key={['row', i].join('.')}>
					<div
						className={[styles.clickButton, 'fieldBox'].join(' ')}						
						onClick={() => props.setTournamentID(curr.id)}
					>
						{displayName}
					</div>
					<div className={[styles.clickButton, styles.close, 'fieldBox'].join(' ')} onClick={() => {
						props.triggerConfirm('Delete tournament \'' + displayName + '\'?\nPlease note this action cannot be undone.', () => props.removeID(curr.id), () => {});
					}}>
						<IoIosCloseCircle />
					</div>
				</div>
			);
		}).filter(elem => elem);
		return buttons;
	}, [props.tournamentDict, props.setTournamentID, props.triggerConfirm, props.removeID]);

	const listing = React.useRef() as React.RefObject<HTMLDivElement>;
    
	useEffect(() => {
		let x = setTimeout(() => {
			if (!listing.current) return;
			setHeight(listing.current.getBoundingClientRect().height);
		}, 250);
		return () => clearTimeout(x);
	}, [listing, props.tournamentDict, generateListing, setHeight]);

	return (
		<div className={styles.sidebar}>
			<div className={[styles.profile].join(' ')}>				
				<div className='topBanner'>
                    Load Tournament
				</div>
				<div className={['scrollable', styles.scrollable].join(' ')}>
					<div className={styles.listWrapper} style={{height}}>
						<div ref={listing} className={styles.listing}>
							{generateListing(props.tournamentDict)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

interface UserSettingProps {
	updateTournamentSettings: () => Promise<void>
	updateSetting: (k: string, v: any) => Promise<void>
	closeListing: () => void
	user: User,
	updateUser: () => void
}

export function UserSettings (props: UserSettingProps) {

	const [height, setHeight] = useState(0);
	const listing = React.useRef() as React.RefObject<HTMLDivElement>;
    
	useEffect(() => {
		let x = setTimeout(() => {
			if (!listing.current) return;
			setHeight(listing.current.getBoundingClientRect().height);
		}, 250);
		return () => clearTimeout(x);
	}, [listing, setHeight]);
	
	const updateSetting = useCallback((k: string, v: any): Promise<void> => POST({
		url: '/profile/updateSetting',
		data: {
			[k]: v
		}
	}).then(props.updateUser), [props.updateUser]);

	const renderSettings = useMemo((): ReactElement[] => {
		return Object.entries(defaultUserSettings).map(([k, def], i): ReactElement => {
			if (def.show === false) return null;
			let v = props.user[k as keyof User] || def.defaultValue;
			if (def.value) v = def.value(props.user);
			if (def.type === 'number') v = Number(v);
			if (def.validate) {
				if (!def.validate(v, props.user)) return null;
			}
			let content = v;
			if (def.convert) content = def.convert(v) as string;
			let displayContent = typeof content !== 'undefined' ? content.toString() : '\u200b';
			return (
				<div className={profileStyles.row} key={['row', i].join('.')}>
					<div className={['fieldBox'].join(' ')}>{def.name + ':'}</div>
					{def.editable ?
						<EditableForm
							handleSubmit={updateSetting}
							content={content}
							url={'/updateSetting'}
							inputType='text'
							onSubmitSuccess={props.updateTournamentSettings}
							classNames={{
								editableField: 'fieldBox',
								input: styles.input
							}}
							name={k}
							additionalInputProps={{}}
							show={{
								submit: true
							}}
							useHandleSubmit
						/>
						: <div
							style={{
								cursor: def.type === 'link' ? 'copy' : 'default'
							}}
							className={['fieldBox', k === 'licensed' ? styles['license' + v.toString()] : ''].join(' ')}
							onClick={def.type !== 'link' ? null : () => navigator.clipboard.writeText(displayContent)}
						>
							{displayContent}
						</div>
					}
				</div>
			);
		});
	}, [props.user]);

	return (
		<div className={styles.sidebar}>
			<div className={styles.profile}>	
				<div className='topBanner'>
                    Settings
				</div>
				<div className={[styles.listWrapper, 'scrollable'].join(' ')} style={{height}}>
					<div ref={listing} className={styles.listing}>
						{renderSettings}
					</div>
				</div>
			</div>
			<div className={['bottomBanner', styles.bottomBanner].join(' ')}>
				<div onClick={props.closeListing} className='button'>
					<FaAngleLeft />
				</div>
			</div>
		</div>
	);
}