import React, { ReactElement, useCallback, useMemo, useContext, useState } from 'react';
import { server, POST } from 'utils/requests';
import url from 'url';
import styles from 'css/tournament.module.css';
import { TournamentStatus, TournamentSettings, Player } from '../interfaces';
import { EditableField } from 'components/';
import { FaAngleLeft, FaAngleRight, FaSearch } from 'react-icons/fa';
import { capitalise } from 'utils/prototype';
import Search from 'components/Search';
import { DisplayNameContext, LoadedContext } from '../Contexts';

interface InfoProps {
	userRound: number
	setRound: (round: number) => void

	status: TournamentStatus
	settings: TournamentSettings
	updateTournamentStatus: () => void
	filterPairings: (choices: string[]) => void
	totalRounds: number | 'Infinity'
	players: Player[]
	lastRoundOverride: boolean
	switchPairings: (set?: boolean) => void
	search: (id: string) => void
}

export default function InfoProps(props: InfoProps): ReactElement {

	let lastRound = false;
	if (typeof props.lastRoundOverride !== 'undefined') lastRound = props.lastRoundOverride;
	else lastRound = !props.status.active;

	let uri = url.resolve(server, ['tournament', props.status.id, 'setName'].join('/'));

	const round = useMemo(() => props.userRound || props.status.round, [props.userRound, props.status.round]);

	const decrementRound = useCallback(() => {
		if (round > 1) {
			props.setRound(round - 1);
			props.switchPairings(false);
		}
	}, [round, props.setRound, props.switchPairings]);

	const getDisplayName = useContext(DisplayNameContext);

	const incrementRound = useCallback(() => {
		if (round < props.status.round) {
			props.setRound(round + 1);
			props.switchPairings(false);
		}
	}, [round, props.setRound, props.switchPairings]);

	const hasLoaded = useContext(LoadedContext);
	const [searchMode, toggleSearch] = useState(false);

	let roundStyle = '';
	if (!props.status.round) roundStyle = styles.pending;
	else if (lastRound && !props.status.active) roundStyle = styles.finished;
	else if (!props.status.active) roundStyle = styles.paused;
	else roundStyle = styles.active;

	return (
		<div className={styles.titleBar}>
			<div className={styles.stage}>
				<div className={[styles.row, styles.headerBar, searchMode ? styles.expanded : ''].join(' ')}>
					<div className={[styles.header].join(' ')} onClick={() => searchMode ? toggleSearch(false) : null}>
						<EditableField
							content={props.status.name}
							url={uri}
							inputType='text'
							handleSubmit={(k: string, v: any) => POST({
								url: uri,
								data: {
									[k]: v
								}
							})}
							classNames={styles}
							additionalInputProps={{}}
							onSubmitSuccess={props.updateTournamentStatus}
							defaultContent={!hasLoaded ? '' : `Unnamed ${capitalise(props.settings.pairingSystem)} Tournament`}
							show={{
								submit: true
							}}
						/>
					</div>
					{
						props.status.round ?
							!searchMode ?
								<div className={['button', styles.button].join(' ')} onClick={() => toggleSearch(true)}>
									<FaSearch />
								</div> :
								<div className={['button', styles.searchButton].join(' ')}>
									<Search
										onSubmit={(id: string) => {
											toggleSearch(false);
											props.search(id);
										}}
										onCancel={() => toggleSearch(false)}
										onChange={props.filterPairings}
										options={props.players}
										getName={getDisplayName as any}
										styles={styles}
										placeholder='Search All Players'
										showHistory={false}
										showChoices={false}
										cacheHistory={false}
										preserveLastSearch
									/>
								</div> :
							null
					}
				</div>
				<div className={[styles.row, styles.statusBar].join(' ')}>
					<div id={styles.previous} className={[round > 1 ? 'button' : styles.fixed].join(' ')} onClick={decrementRound}>
						{hasLoaded ? <FaAngleLeft /> : null}
					</div>
					<div className={[styles.fixed, styles.round, roundStyle].join(' ')}>
						{!hasLoaded ? null : <>
							Round: {!round ? 'Not yet initialised' : round + ' / ' + (props.totalRounds === 'Infinity' ? '∞' : props.totalRounds)}
						</>}
					</div>
					<div id={styles.next} className={[round < props.status.round ? 'button' : styles.fixed].join(' ')} onClick={incrementRound}>
						{hasLoaded ? <FaAngleRight /> : null}
					</div>
				</div>
			</div>
		</div>
	);

}