import React from 'react';
import cx from 'classnames';
import styles from 'css/alert.module.css';
import { IoMdClose, IoMdCheckbox } from 'react-icons/io';
import { FaCircleNotch } from 'react-icons/fa';

export interface AlertProps {
	message: string | JSX.Element | boolean
	title?: string | JSX.Element
	type?: 'error' | 'confirm' | 'info' | 'action' | 'loading' | ''
	resolve?: () => void
	reject?: () => void
	close?: () => void,
	tall?: boolean
	wide?: boolean
}

/**
 * You need to wrap setAlert in a useEffect and call it repeatedly
 * if you are resolving a function which is a dependency
 */
export default function Alert (props: AlertProps) {
	
	const message = React.isValidElement(props.message) || typeof props.message === 'string' ?
		props.message :
		JSON.stringify(props.message, null, 4) + '\n\nIf you\'re seeing this error, please report it to the developers.';

	if (props.type === 'loading') return (
		<div className={styles.shade} onClick={(e) => e.stopPropagation()}>
			<div className={styles.loading}>
				<FaCircleNotch />
			</div>
		</div>
	);

	const resolve = () => {
		if (props.resolve) props.resolve();
		props.close();
	};

	return (
		<div className={styles.shade} onClick={(e) => e.stopPropagation()}>
			<div className={cx('container', styles.container, {
				[styles.tall]: props.tall,
				[styles.wide]: props.wide
			})}>
				<div className={styles.alertBox}>
					{props.title ? <div className={styles.title}>
						{props.title}
					</div> : null}
					<div className={[styles.textBox, 'scrollable'].join(' ')}>
						{message}
					</div>
					<div className={styles.buttonRow}>
						{props.type === 'action' ?
							<button className={[styles.OK, 'button'].join(' ')}
								autoFocus
								onClick={resolve}
							>
								<IoMdClose />
							</button> :
							props.type !== 'confirm' ?
								<button
									className={[styles.OK, 'button', props.type === 'error' ? styles.ERROR : ''].join(' ')}
									onClick={resolve}
									autoFocus
								>
									OK
								</button> :
								<>
									<button className={[styles.NO, 'button'].join(' ')}
										autoFocus
										onClick={() => {
											if (props.reject) props.reject();
											props.close();
										}}
									>
										<IoMdClose />
									</button>
									<button className={[styles.YES, 'button'].join(' ')} onClick={resolve}>
										<IoMdCheckbox />
									</button>
								</>
						}
					</div>
				</div>
			</div>
		</div>
	);

}