import { useCallback, useContext, useMemo, useState } from 'react';
import { isElectron } from 'utils/electron';
import { GET, POST } from 'utils/requests';
import ECF, { SearchProfile as ECFSearchProfile } from 'ecf';
import FIDE, { SearchProfile as FIDESearchProfile } from 'fide-chess';
import Lichess, { SearchResult as LichessSearchResult } from 'lichess';
import { px } from 'utils/prototype';
import { AlertContext, SettingsContext } from '../../../Contexts';
import { TournamentSettings } from '../../../interfaces';
import { convertToECF, convertToFIDE } from 'resources/rating';

const lichess = new Lichess('');

interface ChessComSearchUser {
	id: string
	title?: string
	username: string
	href: string
	name?: string
	country?: string
	joinedAt: string
	online: boolean
	rating: number
}


export const sources = {
	new: {
		placeholder: '',
		input: (id: string) => Promise.resolve([]),
		parse: (p: ECFSearchProfile) => '',
		rating: (r: number, s: TournamentSettings) => 0,
		getData: (r: number) => ({ rating: 0 }),
	},
	ecf: {
		placeholder: 'Last name, First name',
		input: (id: string) => ECF.searchUsers(id, 5, true),
		parse: (p: ECFSearchProfile) => {
			if (!p) return '';
			return `${p.name} (S:${p.standard ? p.standard.rating : '-'}/R:${p.rapidplay ? p.rapidplay.rating : '-'})\n${p.club}`;
		},
		rating: (r: number, s: TournamentSettings) => s.ratingSystem === 'FIDE' ? convertToFIDE(r): r,
		getData: (p: ECFSearchProfile & {type?: string}) => {
			let rating = p.standard.rating || p.rapidplay.rating;
			let firstName = [p.firstName, p.middleName].join(' ');
			p.type = 'ecf';
			return {
				firstName,
				lastName: p.lastName,
				rating,
				account: p
			};
		}
	},
	fide: {
		placeholder: 'Name or FIDE ID',
		input: (id: string) => FIDE.search(id, { nb: 5 }),
		parse: (p: FIDESearchProfile) => {
			if (!p) return '';
			return `${p.Name} (S:${p['Std.']}/R:${p['Rpd.']}/B:${p['Blz.']}) [${p['B-Year']}]`;
		},
		rating: (r: number, s: TournamentSettings) => s.ratingSystem === 'ECF' ? convertToECF(r): r,
		getData: (p: FIDESearchProfile & {type?: string}) => {
			let rating = p['Std.'] || p['Rpd.'] || p['Blz.'];
			let [lastName, firstName] = p.Name.split(', ').map(v => v.trim());
			p.type = 'fide';
			return {
				firstName,
				lastName,
				rating,
				account: p
			};
		}
	},
	lichess: {
		placeholder: 'Lichess username',
		input: (id: string) => lichess.users.search(id),
		parse: (p: LichessSearchResult) => {
			if (!p) return '';
			return p.name;
		},
		rating: (r: number, s: TournamentSettings) => 0,
		getData: (p: LichessSearchResult & {type?: string}) => {
			p.type = 'lichess';
			return {
				firstName: p.name,
				rating: 0,
				account: p
			};
		}
	},
	chessCom: {
		placeholder: 'Name or full Chess.com username',
		input: (id: string) => lichess.users.search(id),
		parse: (p: ChessComSearchUser) => {
			if (!p) return '';
			return `${p.username} (${p.title ? `${p.title}/` : ''}${p.name ? `N:${p.name}/` : ''}R:${p.rating}) [${p.country}]`;
		},
		rating: (r: number, s: TournamentSettings) => 0,
		getData: (p: ChessComSearchUser & {type?: string}) => {
			p.type = 'chessCom';
			return {
				firstName: p.name ?? p.username,
				rating: p.rating,
				account: p
			};
		}
	}
};

interface SearchProps {
	id: string
	source: keyof typeof sources | 'new'
}

export default function useSearch(props: SearchProps) {

	const { setAlert } = useContext(AlertContext);
	const settings = useContext(SettingsContext);
	const source = useMemo(() => sources[props.source], [sources, props.source]);

	const [results, setResults] = useState([]);

	const handleInput = useCallback(async (id: string) => {
		if (!id) {
			setResults([]);
			return [];
		}
		let parsed = isElectron ? await (source.input as any)(id).catch((e: Error): any[] => {
			console.error(e);
			return [];
		}) : await GET({
			url: px('fetch', props.source, 'searchUsers'),
			params: { argument: id, nb: 5, parse: true }
		}) as any[];
		setResults(parsed);
		return parsed;
	}, [source, setResults]);

	const parseUser = useMemo(() => source.parse, [source]);

	const handleSubmit = useCallback((id: string) => {
		let p = results.find(p => p.id === id);
		if (!p) {
			setAlert({
				message: `Couldn't add new player: ${id}\nContact a developer if this problem persists`,
				type: 'error'
			});
			return Promise.resolve();
		}
		let data = source.getData(p);
		data.rating = source.rating(data.rating, settings);
		return POST({
			url: px('tournament', props.id, 'appendPlayer'),
			data
		});
	}, [setAlert, props.id, source, results, settings]);

	const placeholder = source.placeholder;

	return { handleInput, parseUser, handleSubmit, placeholder };
}