import React, { ReactElement, useCallback } from 'react';
import { EditableField } from 'components';
import { TournamentSettings, TournamentStatus, Player } from '../../interfaces';
import url from 'url';

import styles from 'css/profile.module.css';
import { server, POST } from 'utils/requests';
import { defaultSettings, TournamentSetting } from 'resources/settings';
import CollapsableSection, { SectionBreak } from 'components/CollapsableSection';
import { useEditable } from 'components/EditableField';
import defaultProfile from 'assets/default_profile.jpg';

interface SettingsProps {
	updateTournamentSettings: () => Promise<void>
	updateSetting: (k: string, v: any) => Promise<void>
	updateRounds: () => Promise<void>
	status: TournamentStatus
	settings: TournamentSettings
	setLastRound: (override: boolean) => void // need to include a method to cancel a last round token when the total rounds is incremented
	triggerError: (message: string) => void
	players: Player[]
	rounds: Date[]
}

export default function Public(props: SettingsProps): ReactElement {

	const { SettingsRow } = useEditable(props);
    
	const clearRound = useCallback((round: number): Promise<void> => {
		return POST({
			url: '/tournament/' + props.status.id + '/clearRound',
			data: { round }
		});
	}, [props.status.id]);

	const renderRound = useCallback((i: number) => {
		return (
			<div className={[styles.row, styles.roundRow].join(' ')} key={['row', i].join('.')}>
				<div className='fieldBox'>
					{`Round ${i}:`}
				</div>
				<EditableField
					handleClear={() => clearRound(i)}
					content={props.rounds[i] ? props.rounds[i].toString() : ''}
					defaultEditContent={new Date(Date.now()).toString()}
					url={url.resolve(server, ['tournament', props.status.id, 'updateRound'].join('/'))}
					inputType='datetime-local'
					onSubmitSuccess={props.updateRounds}
					classNames={{
						editableField: styles.editableField,
						input: styles.input,
					}}
					additionalInputProps={{}}
					name={'round.' + i.toString()}
					show={{
						submit: true,
						increment: false,
						clear: true
					}}
					handleSubmit={(k, v) => {
						if (isNaN(new Date(v).valueOf())) return Promise.reject();
						let number = k.split('.').pop() as string;
						return POST({
							url: url.resolve(server, ['tournament', props.status.id, 'updateRound'].join('/')),
							data: { [Number(number)]: new Date(v).toISOString() }
						});
					}}
					useHandleSubmit
				/>
			</div>
		);
	}, [props.updateSetting, props.rounds, props.status.id, props.updateRounds]);

	let roundEntries = [];
	let length = props.settings.totalRounds === Infinity || props.settings.totalRounds === 'Infinity' ? Math.max(props.rounds.length, 1) : props.settings.totalRounds;
	for (let i = 1; i <= length; i++) roundEntries.push(i);

	return (
		<>            
			<div className={[styles.profileContainer, 'scrollable'].join(' ')}>
				<CollapsableSection
					key={'public'}
					value={'public'}
					header={'Information'}
					useInternalState
					expanded={true}
				>
					{(Object.entries(defaultSettings.public) as [keyof TournamentSettings, TournamentSetting<string | number | boolean>][])
						.map(([k, v], i) => <SettingsRow key={['k', i].join('.')} k={k} def={v} i={i} />)}
				</CollapsableSection>
				<CollapsableSection
					key={'permissions'}
					value={'permissons'}
					header={'Permissions'}
					useInternalState
					expanded={true}
				>
					{(Object.entries(defaultSettings.public_permissions) as [keyof TournamentSettings, TournamentSetting<string | number | boolean>][])
						.map(([k, v], i) => <SettingsRow key={['k', i].join('.')} k={k} def={v} i={i} />)}
				</CollapsableSection>
				{props.settings.roundTimes ? <CollapsableSection
					key={'rounds'}
					value={'rounds'}
					header={'Rounds'}
					useInternalState
					expanded={true}
				>
					{roundEntries.map(renderRound)}
				</CollapsableSection> : null}
				<CollapsableSection
					key={'profile'}
					value={'profile'}
					header={'Profile'}
					useInternalState
					expanded={true}
				>
					<div className={styles.baseContainer}>
						<div className={styles.profilePictureFrame}>
							<img className={styles.profilePicture} src={props.settings.logoURL || defaultProfile} alt='Invalid image URL' />
						</div>
						<EditableField
							handleSubmit={(k, v) => props.updateSetting(k, v)}
							content={props.settings.description}
							url={url.resolve(server, ['tournament', props.status.id, 'updateSetting'].join('/'))}
							inputType='textarea'
							classNames={{
								form: styles.description,
								editableField: [styles.editableField, styles.descriptionDiv].join(' '),
								input: [styles.input, 'scrollable'].join(' ')
							}}
							name={'description'}
							additionalInputProps={{}}
							show={{
								submit: true,
								reset: true
							}}
							useHandleSubmit
						/>
					</div>
				</CollapsableSection>
				<SectionBreak />
			</div>
		</>
	);
}