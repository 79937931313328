import React, { useCallback, useReducer, useMemo, useContext, ReactElement, useState } from 'react';
import cx from 'classnames';
import { defaultSettings, TournamentSetting } from 'resources/settings';
import { POST } from 'utils/requests';
import { AxiosError } from 'axios';
import { AnyDictionary, TournamentSettings, Modes } from '../interfaces';
import { useEditable } from 'components/EditableField';
import { AlertContext } from '../Contexts';

import styles from './css/intro.module.css';
import alertStyles from 'css/alert.module.css';
import { capitalise } from 'utils/prototype';
import { MdExitToApp, MdPool } from 'react-icons/md';
import { FaChess, FaFileImport, FaHandsHelping, FaPencilRuler, FaPlusSquare, FaUndo, FaUndoAlt, FaUserPlus, FaSitemap, FaAngleDown } from 'react-icons/fa';
import { robin, fide } from 'assets/icons';

interface IntroProps {
	endSession: () => void
	setTournamentID: (id: string) => void
	setMode: (mode: Modes) => void
	defaultSettings?: Partial<TournamentSettings>
}

const defSettings = Object.values(defaultSettings).reduce((acc, set) => {
	for (let [k, v] of Object.entries(set)) {
		(acc as any)[k] = v.defaultValue;
	}
	return acc;
}, {} as TournamentSettings);

const templates = {
	sevenRoundTeamSwiss: {
		name: '7-Round Team Swiss',
		icon: <FaPlusSquare />,
		settings: {
			pairingSystem: 'swiss',
			competitors: 'team',
			totalRounds: 7,
			displayPoints: 'match',
			gamePointTotal: 0,
			public: true,
			timeControl: '10+5',
			source: 'chessCom',
			pullResults: 2,
			allowSelfLinks: 3,
			byeGameReward: 2,
			showRatings: false,
			publishPairings: true
		}
	},
	individualRoundRobin: {
		name: 'Individual Round-Robin',
		icon: robin,
		settings: {
			pairingSystem: 'round-robin',
			competitors: 'individual'
		}
	},
	teamRoundRobinWithPools: {
		name: 'Team Round-Robin with Pools',
		icon: <MdPool />,
		settings: {
			pairingSystem: 'round-robin',
			competitors: 'individual',
			usingPools: true,
			displayPoints: 'game',
			gamePointTotal: 0,
			poolDisplay: 0,
			source: 'chessCom',
			pullResults: 2,
			allowSelfLinks: 3,
			showRatings: false
		}
	},
	fideSwissTournament: {
		name: 'FIDE Swiss Tournament',
		icon: fide,
		settings: {
			pairingSystem: 'swiss',
			competitors: 'individual',
			totalRounds: 6,
			ratingSystem: 'FIDE',
			checkForDuplicatePlayers: true,
			allowClashes: false,
			allowBonuses: false,
			performanceSystem: 'FIDE',
			byeGameReward: 2,
		}
	},
	selfSignUp: {
		name: 'Individual with Registration',
		icon: <FaUserPlus />,
		settings: {
			pairingSystem: 'swiss',
			competitors: 'individual',
			totalRounds: 7,
			ratingSystem: 'FIDE',
			checkForDuplicatePlayers: true,
			allowRegistration: 'open'
		}
	},
	knockout: {
		name: 'Knockout Bracket',
		icon: <FaSitemap />,
		settings: {
			pairingSystem: 'knockout',
			displayPoints: 'match',
			usingPools: false,
			allowBonuses: false
		}
	}
} as {[key: string]: { name: string, icon: JSX.Element | 'string', settings: Partial<TournamentSettings> }};

export default function Intro(props: IntroProps): ReactElement {

	const [mode, setMode] = useState('main' as 'main' | 'templates');
	const [name, setName] = useState('');

	const [modifiedSettings, setSetting] = useReducer((state: TournamentSettings, action: AnyDictionary | 'reset') => {
		if (action === 'reset') return {} as TournamentSettings;
		return Object.assign({}, state, action);
	}, {} as TournamentSettings);

	const settings = useMemo(() => Object.assign({}, defSettings, props.defaultSettings, modifiedSettings, { name }), [props.defaultSettings, name, modifiedSettings]);

	const { setAlert } = useContext(AlertContext);

	const { SettingsRow } = useEditable({
		updateTournamentSettings: () => Promise.resolve(),
		updateSetting: (k, v) => {
			if (k === 'name') return Promise.resolve(setName(v));
			return Promise.resolve(setSetting({ [k]: v }));
		},
		status: {
			round: 0,
			name: undefined,
			id: '',
			active: true
		},
		settings,
		triggerError: (message: string) => setAlert({ message, type: 'error' }),
		players: [],
		resize: false,
		isIntro: true
	});

	const initialise = useCallback(async (): Promise<void> => {
		try {
			let id = await POST({ url: '/initialise', data: Object.assign({}, modifiedSettings, { name }) });
			if (!id) throw new Error();
			props.setTournamentID(id);
			props.setMode('tournament');
		} catch (error) {
			let e = error as AxiosError;
			if (!e.response || e.response.status === 401) props.endSession();
		}
	}, [props.setTournamentID, modifiedSettings, name, props.endSession]);

	const [advanced, toggleAdvanced] = useReducer((s: boolean, a?: boolean) => typeof a === 'boolean' ? a : !s, false) as [boolean, (a?: any) => void];

	const contents = useMemo(() => {
		let list = Object.entries(defaultSettings);
		let index = list.findIndex(([section]) => section === 'automation');
		let automation = list.splice(index, 1)[0];
		list.splice(1, 0, automation);
		return <>
			<div className={styles.section} key={['section', 'first'].join('.')} />
			<SettingsRow k={'name'} def={{
				name: 'Tournament name',
				type: 'string'
			}} i={0} />
			{list.map(([section, data], i) => {
				if (section.startsWith('stats')) return null;
				if (section.includes('hidden')) return null;
				if (section.startsWith('public') && !settings.public) return null;
				return <React.Fragment key={cx(section, i)}>
					{section === 'permissions' ?
						<div className={cx(styles.section, styles.divider)} onClick={toggleAdvanced}>
							<hr />
							<FaAngleDown className={cx(styles.angle, {[styles.rotated]: advanced})} />
							ADVANCED SETTINGS
							<hr />
						</div> :
						null
					}
					<div className={cx(styles.set, {
						[styles.collapsed]: (section === 'permissions' || section === 'points') && !advanced
					})}>
						<div className={styles.section} key={['section', section].join('.')}>
							{section.split('_').map(capitalise).join(' ')}
						</div>
						{(Object.entries(data) as [keyof TournamentSettings, TournamentSetting<string | number | boolean>][])
							.map(([k, v], i) => {
								if (v.locked) return null;
								return <SettingsRow key={['k', i].join('.')} k={k} def={v} i={i + 1} />;
							})}
					</div>
				</React.Fragment>;
			})}
		</>;
	}, [SettingsRow, advanced, toggleAdvanced]);

	const templateGrid = useMemo(() => {
		return (
			<div className={styles.templates}>
				{Object.values(templates).map((v, i) => {
					return (
						<div key={['template', i].join('.')} className='button' onClick={() => {
							setSetting(v.settings);
							toggleAdvanced(true);
							setMode('main');
						}}>
							{typeof v.icon === 'string' ? <img src={v.icon} /> : v.icon}
							{v.name}
						</div>
					);
				})}
			</div>
		);
	}, []);

	return (
		<div className={['container', styles.container, alertStyles.container, alertStyles.tall].join(' ')}>
			<div className={alertStyles.alertBox}>
				<div className={alertStyles.title}>
					Create New Tournament
				</div>
				<div className={[alertStyles.textBox, styles.content, 'scrollable'].join(' ')}>
					<div className={alertStyles.buttonRow}>
						<button className={[
							'button',
							mode === 'main' && Object.keys(modifiedSettings).some(k => modifiedSettings[k as keyof TournamentSettings] !== defSettings[k as keyof TournamentSettings]) ?
								'' : 'null'
						].join(' ')} onClick={() => setSetting('reset')}
						>
							<FaFileImport />Use defaults
						</button>
						{mode === 'main' ?
							<button className={['button'].join(' ')} onClick={() => setMode('templates')}>
								<FaPencilRuler />Use a template
							</button> :
							<button className={['button'].join(' ')} onClick={() => setMode('main')}>
								<MdExitToApp />Back
							</button>
						}
						<button className={['button', 'null', mode !== 'main' ? 'null' : ''].join(' ')} onClick={initialise}>
							<FaHandsHelping />Walk me through
						</button>
					</div>
					{mode === 'main' ? contents : templateGrid}
				</div>
				<div className={alertStyles.buttonRow}>
					<button className={[alertStyles.NO, 'button'].join(' ')} onClick={() => props.setMode('menu')}>
						<MdExitToApp />BACK
					</button>
					<button className={[alertStyles.YES, 'button'].join(' ')} onClick={initialise}>
						<FaChess />CREATE
					</button>
				</div>
			</div>
		</div>
	);
}