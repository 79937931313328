import React, { useRef, RefObject, useState, useCallback, useEffect, useMemo } from 'react';
import styles from './css/announcements.module.css';
import { POST, cookies } from 'utils/requests';
import TabSection from 'components/TabSection';
import { FaEye, FaInfoCircle, FaPlusCircle } from 'react-icons/fa';
import Markdown from 'react-markdown';
import { parseHtml, RouterLink } from 'utils/plugins';
import Core from './Core';

export default function Announcements(props: {
	id: string
	prev: {
		id: string
		announcement: string
	}[]
	initialA: number
	leave: () => void
}) {

	const textRef = useRef() as RefObject<HTMLTextAreaElement>;
	const [currA, handleInput] = useState('');
	const [id, setID] = useState(props.initialA);
	const prevA = useMemo(() => props.prev[id]?.announcement || '', [props.prev, id]);
	useEffect(() => {
		handleInput(props.prev[id]?.announcement || '');
	}, [props.prev, id, handleInput]);

	useEffect(() => {
		(async () => {
			let prev = await cookies.get('lastAnnouncement');
			if (!prev) return;
			handleInput(prev);
			if (textRef.current) textRef.current.value = prev;
		})();
	}, [handleInput, textRef]);

	useEffect(() => {
		(async () => await cookies.set('lastAnnouncement', currA))();
	}, [currA]);

	const handleSubmit = useCallback(() => POST({
		url: ['', 'tournament', props.id, 'setAnnouncement'].join('/'),
		data: {
			id: id ? props.prev[id].id : undefined,
			data: currA
		}
	})
		.then(() => cookies.remove('lastAnnouncement'))
		.then(props.leave), [props.id, props.prev, id, currA, props.leave]);

	return (
		<Core id='announcements' childClass={styles.stage} buttons={[
			{
				name: 'Open Markdown guide',
				icon: <FaInfoCircle />,
				href: 'https://guides.github.com/features/mastering-markdown/'
			}
		]}>
			<div className={styles.col1}>
				<div className={['container', 'scrollable', styles.editingArea].join(' ')}>
					<TabSection
						name={id ? 'a.' + id.toString() : 'new'}
						setName={(k: string) => {
							if (k === 'new') return setID(0);
							let value = k.slice(2);
							let nb = Number(value);
							setID(nb);
						}}
						nameDict={{
							...Object.keys(props.prev).reduce((acc, i) => {
								if (i === '0') return acc;
								acc['a.' + i.toString()] = '#' + i.toString();
								return acc;
							}, {} as {[key: string]: string}),
							new: {
								icon: FaPlusCircle,
								name: 'New'
							}
						}}
					/>
					<textarea
						onChange={(e) => e.target && e.target ? handleInput(e.target?.value || '') : null}
						className={[styles.textarea, 'scrollable'].join(' ')}
						name='textArea'
						ref={textRef}
						defaultValue={prevA}
						placeholder='Please write your announcement in Markdown format&#10;\
							Open the above guide to markdown for help.&#10;\
							You can draft an announcement here and come back to it any time.'
					/>
				</div>
				<div className={['container', styles.buttons].join(' ')}>
					<div style={currA !== prevA && (!id ? currA : true) ? {} : { visibility: 'hidden' }} >
						<button className={[styles.submit, styles.reset, 'button'].join(' ')} onClick={() => {
							if (textRef.current) textRef.current.value = prevA || '';
							handleInput(prevA || '');
						}}>
							RESET
						</button>
						<button className={[styles.submit, 'button'].join(' ')} onClick={handleSubmit}>
							SUBMIT
						</button>
					</div>
				</div>
			</div>
			<div className={styles.col2}>
				<div className={['container', styles.preview].join(' ')}>
					<TabSection
						name='preview'
						setName={() => {}}
						nameDict={{
							preview: {
								icon: FaEye,
								color: 'lightgrey'
							}
						}}
					/>
					<div className={[styles.previewWrapper, 'scrollable'].join(' ')}>
						<Markdown
							source={currA.trim()}
							escapeHtml={false}
							astPlugins={[parseHtml]}
							renderers={{
								link: RouterLink
							}}
						/>
					</div>
				</div>
			</div>
		</Core>
	);
}