import React, { ReactElement, RefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Core from '../Core';

import styles from 'screen/css/export.module.css';
import Player from 'models/player';
import { FaCompressArrowsAlt, FaCopy, FaExpandArrowsAlt } from 'react-icons/fa';
import { TournamentStandings, TournamentStatus, History } from '../../interfaces';
import { Result } from 'resources/result';
import { SettingsContext } from '../../Contexts';
import { pascalise, px } from 'utils/prototype';
import { GET } from 'utils/requests';

interface FIDEProps {
	players: Player[]
	status: TournamentStatus
	className?: string
	expanded?: boolean
	toggleExpand?: () => void
	results: Result[]
}

export default function FIDEGrade(props: FIDEProps): ReactElement {

	const ref = useRef() as RefObject<HTMLTextAreaElement>;
	const { results } = props;
	const settings = useContext(SettingsContext);

	const [createdAt, finishedAt] = useMemo(() => {
		if (!results.length) return [new Date(), new Date()];
		let [c, f] = [Infinity, 0];
		for (let r of results) {
			let v = new Date(r.createdAt).valueOf();
			if (v < c) c = v;
			if (v > f) f = v;
		}
		return [c, f].map(v => new Date(v));
	}, [results]);

	const details = useMemo(() => {
		let [minutes, increment] = settings.timeControl?.split('+').map(v => Number(v)) || [0, 0];
		let headers = {
			'012': props.status.name || '{NAME}',
			'022': settings.location,
			'032': '{FEDERATION}', //TODO
			'042': convertFromDate(createdAt),
			'052': convertFromDate(finishedAt),
			'062': `${props.players.length} (${props.players.length})`,	//TODO,
			'072': '', //TODO
			'092': pascalise(settings.pairingSystem) + ' System',
			'102': '{FIDE ID}',
			'112': '{FIDE CODE}',
			'122': `${minutes} minutes each plus ${increment} seconds a move throughout`
		} as {[key: string]: string};
		return Object.entries(headers)
			.sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
			.map(([k, v]) => k + ' ' + v).join('\n');
	}, [settings, props.status, props.players]);

	const [state, setState] = useState([] as string[]);
	const updateState = useCallback(() => {
		if (!settings.tournamentId) return;
		return GET({
			url: px('tournament', settings.tournamentId, 'getState'),
			params: { force: true }
		})
			.then(({ players: p }: {players: TournamentStandings }) => {
				let arr = [] as string[];
				let max = Math.max(...Object.keys(p).map(n => Number(n)));
				for (let i = max; i >= 0; i -= 0.5) {
					let score = i.toString();
					arr = arr.concat(p[score]);
				}
				return arr;
			})
			.then(setState);
	}, [settings.tournamentId, setState]);
	useEffect(() => {
		updateState();
	}, [updateState]);

	
	const players = useMemo(() => {
		const sorted = props.players.sort((a, b) => b.rating - a.rating);
		if (!sorted.length) return '';
		const ids = sorted.map(p => p.id);
		let headers = [
			{
				code: 'DDD',
				value: p => {
					let x = (p.pool || 0) + 1;
					if (settings.pairingSystem === 'knockout') x = 0;
					let n = x.toString();
					return '0'.repeat(3 - Math.min(3, n.length)) + n;
				}
			},
			{
				code: 'SSSS',
				value: p => ids.indexOf(p.id) + 1,
				align: 'right'
			},
			{
				code: 'sTTT',
				value: p => `${(p.gender || 'M').toLowerCase()}${p.title || ''}`
			},
			{
				code: 'N',
				length: m => m + 10,
				value: p => [p.lastName, p.firstName].join(',')
			},
			{
				code: 'RRRR',
				value: p => p.rating
			},
			{
				code: 'FFF',
				value: p => p.nationality
			},
			{
				code: 'I',
				length: () => 11,
				value: p => p.fide,
				align: 'right'
			},
			{
				code: 'BBBB',
				value: p => p.bYear
			},
			{
				code: 'PPPP',
				value: p => p.score,
				align: 'right'
			},
			{
				code: 'RRRR',
				value: p => state.indexOf(p.id) + 1,
				align: 'right'
			}
		] as {
			code: string,
			value: (p: Player) => string | number,
			align?: 'left' | 'right',
			length?: (maxLength: number) => number
		}[];

		let rounds = [] as number[];
		for (let i = 1; i < props.status.round + 1; i++) rounds.push(i);
		
		const historyMap = (h: History): string => {
			if (!h) return '';
			let id = (ids.indexOf(h.id) + 1).toString();
			let n = ' '.repeat(4 - Math.min(4, id.length)) + id;
			let colour = h.colour.toLowerCase();
			let score = h.match?.toString() || '';
			if (score === '0.5') score = '=';
			if (h.id === 'bye') {
				if (h.match === 1) score = '+';
				else if (h.match === 0.5) score = '=';
				colour = '-';
				n = '0000';
			}
			return [
				'',
				n,
				colour,
				score
			].join(' ');
		};

		let lengths = [] as number[];
		let pSection = sorted.map(p => [
			...headers.map(h => h.value(p)?.toString() || ''),
			...rounds.map(i => historyMap(p.histories[i]))
		]);
		for (let i = 0; i < headers.length; i++) {
			let h = headers[i];
			let length = h.code.length;
			if (h.length) {
				let pLengths = pSection.map(p => p[i].length);
				length = h.length(Math.max(...pLengths));
				lengths[i] = length;
			}
			let align = h.align || 'left';
			for (let j = 0; j < pSection.length; j++) {
				let padding = ' '.repeat(length - pSection[j][i].length);
				pSection[j][i] = [
					align === 'right' ? padding : '',
					pSection[j][i],
					align === 'left' ? padding : ''
				].join('');
			}
		}
		let hSection = [
			...headers.map((h, i) => {
				let v = h.code;
				if (!lengths[i]) return v;
				if (lengths[i] < 0) return v;
				return v.repeat(lengths[i]);
			}),
			...rounds.map(i => {
				let n = i.toString();
				return [
					'',
					n.repeat(4),
					n.repeat(1),
					n.repeat(1),
				].join(' ');
			})
		].join(' ') + ' ';
		return [
			hSection,
			pSection.map(line => line.join(' ') + ' ').join('\n'),
		].join('\n');
	}, [props.status.round, settings, state, props.players]);

	const numbers = useMemo(() => {
		let headers = players.split('\n')[0];
		if (!headers) return '';
		let row1 = '';
		let row2 = '';
		for (let i = 0; i < headers.length; i++) {
			let r = (i + 1) % 10;
			if (!r) {
				let n = Math.floor((i + 1) / 10).toString();
				row1 += ' '.repeat(10 - Math.min(10, n.length)) + n;
			}
			row2 += r;
		}
		return [row1, row2].join('\n');
	}, [players]);

	const roundTimes = useMemo(() => {
		if (!props.players.length) return '';
		let headers = players.split('\n')[0];
		if (!headers) return '';
		let index = headers.indexOf('1111') - 3;
		if (index < 0) return '';

		let rounds = [] as number[];
		for (let i = 1; i < props.status.round + 1; i++) rounds.push(i);
		let times = rounds.map(r => convertFromDate(new Date(props.status.createdAt)).slice(2));
		return [
			'132',
			' '.repeat(index),
			times.join('  ')
		].join('');
	}, [props.status, props.players, players]);

	const [value, setValue] = useState('');
	const updateValueFromComponents = useCallback(() => {
		setValue([
			details,
			roundTimes,
			numbers,
			players
		].join('\n'));
	}, [setValue, players]);
	useEffect(() => {
		updateValueFromComponents();
	}, [updateValueFromComponents]);
	const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setValue(e.target.value);
	}, [setValue]);

	return (
		<Core id='export' isChild className={props.className}
			title='FIDE grading output'
			buttons={[
				{
					name: props.expanded ? 'Contract' : 'Expand',
					icon: props.expanded ? FaCompressArrowsAlt : FaExpandArrowsAlt,
					onClick: props.toggleExpand,
					hide: !props.toggleExpand
				},
				{
					name: 'Copy',
					icon: FaCopy,
					onClick: () => {
						if (!ref.current) return;
						ref.current.select();
						document.execCommand('copy');
					},
					hide: !props.players.length || !results.length
				}
			]}
		>
			<div className={styles.container}>
				<textarea ref={ref} className={styles.textarea + ' scrollable scrollable-x'} value={value} spellCheck={false} onChange={handleChange} />
			</div>
		</Core>
	);
}

function convertFromDate(v: Date): string {
	if (!v) return '';
	if (isNaN(v.valueOf())) return '';
	return [v.getUTCDate(), v.getUTCMonth(), v.getUTCFullYear()].reverse().map(n => {
		let s = n.toString();
		return '0'.repeat(2 - Math.min(2, s.length)) + s;
	}).join('/');
}