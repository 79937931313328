import React, { ReactElement, useState, useCallback, RefObject, useRef, useContext, useMemo, useEffect, useReducer } from 'react';
import cx from 'classnames';
import { Player, TournamentSettings, TournamentPlayers, TournamentStandings } from '../../interfaces';

import styles from 'css/sidebar.module.css';
import { FaCheck, FaTimes, FaPen, FaQuestionCircle } from 'react-icons/fa';
import { AlertContext, DisplayNameContext } from '../../Contexts';
import Players from './Players';
import NewPlayer from './New';
import { GET } from 'utils/requests';
import Banner from './Banner';
import { TournamentSetting } from 'resources/settings';

export interface SidebarProps {
	playerDict: Map<string, Player>
	updatePlayerDict: (force?: boolean) => void
    setPlayerDict: (value: {
        id?: string;
        player?: Player;
        players?: Player[];
        hash?: string;
    }) => void
	id: string
	settings: TournamentSettings
	setDisplayPlayer: (id: string) => void
    checkIfEnoughPlayers: (reload?: boolean) => void
	stateKey: string
	updateSetting: (k: string, v: any, def?: TournamentSetting<string | number | boolean>) => Promise<void>
	jumpedTo: string
}

export default function Sidebar(props: SidebarProps) {

	const [round, setRound] = useState(0);
	const [players, setPlayersRaw] = useState({} as TournamentPlayers);
	const [linkedState, setLinkedState] = useState({} as TournamentStandings);

	const canToggleNewPlayer = useMemo(() => {
		if (!props.settings.allowNewPlayers) return false;
		return true;
	}, [round, props.settings.allowNewPlayers]);

	/** UPDATES SECTION */

	const { startLoading, stopLoading } = useContext(AlertContext);

	const updatePlayerState = useCallback((force?: boolean): Promise<TournamentStandings> => {
		if (!props.id) return Promise.resolve({} as TournamentStandings);
		return GET({ url: '/tournament/' + props.id + '/getState', params: { force } })
			.then(({round, players}: {round: number, players: TournamentStandings }) => {
				setLinkedState(players);
				setRound(round);
				return players;
			});
	}, [props.id, setLinkedState, setRound, startLoading, stopLoading]);
	useEffect(() => {
		(window as any).updatePlayerState = updatePlayerState;
	}, [updatePlayerState]);
	/**
	 * The fundamental method. Called everytime there's a change to the player list. The backbone of this element.
	 */
	const setPlayers = useCallback(async (refresh?: boolean | void): Promise<void> => {
		let state = refresh !== false ? await updatePlayerState() : linkedState;
		if (!state) return Promise.resolve();
		let players = {} as TournamentPlayers;
		for (let [k, v] of Object.entries(state)) {
			players[k] = v.map((id: string) => {
				let player = props.playerDict.get(id);
				//if (!player) console.error('Unmatched ID: ' + id);
				return player;
			}).filter((v) => v);
		}
		setPlayersRaw(players);
		props.checkIfEnoughPlayers(false);
	}, [updatePlayerState, props.playerDict, props.checkIfEnoughPlayers, linkedState, props.stateKey]);


	return <>
		<Players {...props} round={round} setPlayers={setPlayers} players={players} />
		{canToggleNewPlayer ? <NewPlayer {...props} setPlayers={setPlayers} /> : null}
		<Banner {...props} round={round} />
	</>;
}

interface ActiveProps {
	player: Player

	editPlayer: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	activatePlayer: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    clearPlayer: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    allowNewPlayers: boolean
    setDisplayPlayer: (id: string) => void

	round: number
	requireLastName: boolean
}

export function ActivePlayer(props: ActiveProps): ReactElement {

	const getDisplayName = useContext(DisplayNameContext);

	if (typeof props.player.rating !== 'number') props.player.rating = Number(props.player.rating);

	const [active, setActive] = useReducer((state: boolean, action?: boolean) => {
		if (typeof action === 'boolean') return action;
		return !state;
	}, props.player.active) as [boolean, (action?: boolean) => void];
	useEffect(() => {
		setActive(props.player.active);
	}, [setActive, props.player.active]);

	let title = '';
	if (!props.player.histories[props.round]) title = 'Unpaired';
	if (props.player.isPlaying) title = 'Playing';
	
	return (
		<div id={props.player.id} className={styles.playerLine}>
			<div
				className={cx(
					'playerInfo',
					styles.playerInfo,
					{
						[styles.full]: props.round && !props.allowNewPlayers,
						[styles.playerInactive]: !active,
						[styles.isPlaying]: props.player.isPlaying,
						[styles.isUnpaired]: active && !props.player.histories[props.round]
					}
				)}
				title={title}
				onClick={(e: React.MouseEvent<HTMLDivElement>) => props.setDisplayPlayer((e.target as HTMLDivElement).parentElement.id)}
			>
				{getDisplayName(props.player, ['rating'])}
			</div>
			{props.round && !props.allowNewPlayers ? null : <>
				<button name='edit' className={styles.edit} onClick={(props.editPlayer)} >
					<FaPen />
				</button>
				{active ?
					<button name='cancel' className={styles.cancel} onClick={(e) => {
						setActive();
						props.clearPlayer(e);
					}}>
						<FaTimes />
					</button> :
					<button name='active' className={styles.activate} onClick={(e) => {
						setActive();
						props.activatePlayer(e);
					}}>
						<FaCheck />
					</button>
				}
			</>}
		</div>
	);

}

interface FormProps {
	id: string
	i: number
	player?: Player,
	settings: TournamentSettings
	onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
	close: () => void
	handleInput?: (v: string[]) => void
    handlePaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void
	action: string
	isActive?: boolean
}

export function EditableForm(props: FormProps) {

	const formRef = useRef() as RefObject<HTMLFormElement>;
	const [canSubmit, setSubmit] =  useState(undefined as boolean | undefined);

	const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLFormElement>) => {
		switch (e.keyCode) {
		case 27: {
			return props.close();
		}
		case 13: {
			if (!formRef.current) return;
			props.onSubmit(e as any);
		}
		}
	}, [props.close]);

	const { setAlert } = useContext(AlertContext);

	const showHelp = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
		setAlert({
			title: 'How do I add new players?',
			message: <>
				Type in your players to get started.
				<ul>
					<li>If entering individuals, just write name and rating in the boxes!</li>
					<li>If entering teams, select Tournament type 'Team' in settings.</li>
					<li>If entering usernames, currently select Tournament type 'Team' in settings. Support for usernames will be added later.</li>
					<li>You can also add players via the 'ECF' tab</li>
					<li>You can also paste names in by copying your data from an Excel spreadsheet (or any CSV location) and pasting it in the <span className='bold'>top left</span> box of this section. It <span className='bold'>MUST</span> be in the format <span className='code'>firstName, lastName, rating</span> for each entry (or 3 Excel columns, where the first is firstName, second is lastName, and third is Rating). If in team mode, then the format takes only 2 columns. Format for a more user-friendly paste feature is coming soon.</li>
				</ul>
				Once you have enough players begin your tournament!
			</>,
			type: 'info',
			tall: true
		});
		e.preventDefault();
	}, [setAlert]);

	const handleInput = useCallback((e: React.KeyboardEvent<HTMLFormElement>): void => {
		handleKeyDown(e);
		let input = e.target as HTMLInputElement;
		let form = formRef.current as HTMLFormElement;
		if (!form) return;

		let inputs = Array.from(form.querySelectorAll('input'));
		if (props.handleInput) props.handleInput(inputs.map(elem => elem.value));

		if (inputs.filter(v => v.required).every(i => i.value === '')) setSubmit(undefined);
		else if (inputs.filter(v => v.required).every(i => i.checkValidity())) setSubmit(true);
		else setSubmit(false);
	}, [handleKeyDown, props.handleInput, formRef, setSubmit]);
	
	return <>
		<form
			target='_self'
			onKeyDown={handleInput}
			onSubmit={props.onSubmit}
			method='POST'
			action={props.action}
			onInput={handleInput}
			ref={formRef}
			className={cx({[styles.isActive]: props.isActive})}
		>
			{props.isActive ? <div className={styles.shroud} onClick={props.close} /> : null}
			<div className={cx(styles.playerLine, {[styles.isActive]: props.isActive})}>
				<input type='text' className={[styles.input, !props.settings.checkForDuplicatePlayers ? styles.striped : ''].join(' ')} name='firstName' required
					autoFocus={!props.i}
					placeholder={props.player ? undefined : props.settings.competitors === 'individual' ? 'First name' : 'Team name'}
					defaultValue={props.player?.firstName}
					onPaste={props.handlePaste}
					style={{ gridArea: props.settings.competitors === 'individual' ? 'firstName' : 'firstName / firstName / lastName / lastName' }}
				/>
				<input type='text' className={styles.input} name='lastName' required={props.settings.competitors === 'individual' }
					placeholder={props.player ? undefined : 'Last name'}
					defaultValue={props.player?.lastName}
					style={{
						gridArea: 'lastName',
						display: props.settings.competitors === 'individual' ? 'block' : 'none'
					}}
				/>
				<input type='number'
					className={styles.input}
					min={0}
					max={3200}
					name='rating'
					required={props.settings.competitors === 'individual'}
					placeholder={props.player ? undefined : props.settings.competitors === 'individual' ? 'Rating' : 'Av. Rating'}
					defaultValue={props.player?.rating}
					style={{ gridArea: 'edit' }}
				/>
				<input type='hidden' name='id' value={props.player?.id} />
				{canSubmit === undefined ?
					<button tabIndex={-1} className={[styles.info].join(' ')} onClick={showHelp}>
						<FaQuestionCircle />
					</button> :
					canSubmit === true ?
						<button tabIndex={-1} className={[styles.submit].join(' ')}>
							<FaCheck />
						</button> :					
						<button tabIndex={-1} className={[styles.cancel].join(' ')} onClick={(e) => {
							formRef.current.reset();
							handleInput(e as any);
							e.preventDefault();
						}}>
							<FaTimes />
						</button>
				}
			</div>
		</form>
	</>;
}
