import React, { ReactElement, useCallback, useRef, RefObject, useState, useMemo, useContext, useEffect } from 'react';
import { TournamentSettings, TournamentStatus } from '../interfaces';
import CSV from 'papaparse';

import styles from 'css/input.module.css';
import tournStyles from 'css/tournament.module.css';

import { POST } from 'utils/requests';
import * as regexes from 'utils/regexes';
import Player from 'models/player';
import { parseName } from 'utils/prototype';
import TabSection from 'components/TabSection';
import { FaHeading, FaExclamationCircle, FaEye, FaDownload, FaPlusCircle, FaMinusCircle } from 'react-icons/fa';
import { EditableField } from 'components';
import { AlertContext } from '../Contexts';
import { dict as countries } from 'assets/countries.json';
import { CountryCode } from 'fide-chess/lib/interfaces';
import Core from './Core';

interface PasteInputProps {
    status: TournamentStatus
    settings: TournamentSettings
    id: string
	updatePlayerDict: () => void
	players: Player[]
	leave: (() => void)
}

const countryValueDict = {} as {[key: string]: CountryCode};
for (let [k, v] of Object.entries(countries)) {
	countryValueDict[v.toLowerCase()] = k as CountryCode;
}

export default function PasteInput(props: PasteInputProps): ReactElement {

	const requireLastName = false;
	const textRef = useRef() as RefObject<HTMLTextAreaElement>;
	const outputRef = useRef() as RefObject<HTMLTextAreaElement>;
	const [allowNewPlayers, setNew] = useState(props.settings.allowNewPlayers);
	const [loading, setLoading] = useState(false);

	const knownFields = useMemo(() => {
		return {
			id: {
				name: 'Scorch ID',
				search: ['id'],
				validate: (v: string) => !v || props.players.some(p => p.id === v),
				convert: (v: string, p: Player) => {
					return {};
				},
				type: 'string'
			},
			firstName: {
				name: 'Name',
				search: ['name'],
				convert: (v: string, p: Player) => {
					if (!requireLastName) {
						return {
							firstName: v
						};
					} else {
						let { firstName, lastName, middleName } = parseName(v);
						return {
							lastName,
							firstName: [firstName, middleName].join(' ')
						};
					}
				},
				type: 'string',
				required: true
			},
			rating: {
				name: 'Rating',
				search: ['rating'],
				type: 'number',
				required: true
			},
			nationality: {
				name: 'Nationality',
				type: 'string',
				validate: (v: string) => Object.values(countries).some(c => c.toLowerCase() === v.toLowerCase()),
				convert: (v: string, p: Player) => {
					return {
						nationality: countryValueDict[v.toLowerCase()]
					};
				},
				feedback: <>The list of valid federations is as follows:<br />{Object.values(countries).join(', ')}</>,
				search: ['country', 'nation']
			},
			email: {
				name: 'Email',
				search: ['email'],
				type: 'string',
				validate: (v: string) => regexes.email.test(v),
				feedback: <>Email inputs must match the following regex format:<br />{regexes.email.toString()}</>,
				convert: (v: string, p: Player) => {
					return {
						contact: Object.assign(p.contact || {}, {
							email: v
						})
					};
				}
			},
			facebook: {
				name: 'Facebook',
				type: 'string',
				search: ['facebook'],
				convert: (v: string, p: Player) => {
					return {
						contact: Object.assign(p.contact || {}, {
							facebook: v
						})
					};
				}
			},
			phone: {
				name: 'Phone #',
				type: 'string',
				search: ['phone', 'number', 'contact'],
				convert: (v: string, p: Player) => {
					return {
						contact: Object.assign(p.contact || {}, {
							phone: v
						})
					};
				}
			},
			lichess: {
				name: 'Lichess username',
				search: ['lichess'],
				type: 'string',
				validate: (v: string) => regexes.lichess.test(v),
				feedback: <>Invalid format a Lichess username</>
			},
			chessCom: {
				name: 'Chess.com username',
				search: ['chess.com'],
				type: 'string'
			},
			ecfId: {
				name: 'ECF ID',
				search: ['ecf'],
				type: 'string',
				convert: (v: string, p: Player) => {
					return {
						ecf: { id: v }
					};
				}
			},
			fideId: {
				name: 'FIDE ID',
				search: ['fide'],
				type: 'string',
				convert: (v: string, p: Player) => {
					return {
						fide: { id: v }
					};
				}
			}
		} as {[key: string]: {
			name: string
			search: string[]
			feedback?: JSX.Element | string
			convert?: (v: string, p: Player) => {[key in keyof Player]?: any}
			required?: boolean
			type?: string
			validate?: (v: string | number | boolean) => boolean
		}};
	}, [requireLastName, props.players]);

	const [fieldTypes, setTypes] = useState([] as ('string' | 'boolean' | 'number')[]);
	const [autoHeaders, setAutoHeaders] = useState([] as string[]);
	const [headers, setHeaders] = useState([] as string[]);

	const { setAlert } = useContext(AlertContext);

	const handleData = useCallback((data: string[][]): {[key: string]: string | number | boolean}[] => {
		let rawHeaders: string[] = [];
		let headers: string[] = [];
		(data.shift() as string[]).forEach((key: string, i: number) => {
			rawHeaders[i] = key;
			for (let [field, meta] of Object.entries(knownFields)) {
				if (headers.includes(field)) continue;
				for (let s of meta.search) {
					if (key.toLowerCase().includes(s)) return headers[i] = (field);				
				}
			}
		});
		data = data.filter(row => row.length === rawHeaders.length);
		let types = rawHeaders.map((r, i) => {
			let h = headers[i];
			switch (h) {
			case 'rating': {
				return 'number';
			}
			default: {
				if (data.every((row) => !row[i].trim() || regexes.trueBoolean.test(row[i].trim()) || regexes.falseBoolean.test(row[i].trim()))) {
					if (data.some((row) => row[i].trim())) return 'boolean';
				}
				return 'string';
			}
			}
		});
		if (headers.indexOf('firstName') === -1) {
			setAlert({
				type: 'error',
				message: 'Must have at least one column with title \'name\'',
			});
			return [];
		}
		let setId = false;
		if (headers.indexOf('id') === -1) {
			headers.unshift('id');
			rawHeaders.unshift('BLANK');
			types.unshift('string');
			setId = true;
		}
		setTypes(types);
		setAutoHeaders(headers);
		setHeaders(headers);
		return data.map((row) => {
			if (setId) row.unshift('');
			let parsed = row.map((_entry: string, i: number): string | boolean | number | undefined => {
				let entry = _entry.trim();
				switch (types[i]) {
				case 'number': {
					return Number(entry);
				}
				case 'boolean': {
					if (regexes.falseBoolean.test(entry.trim())) return false;
					if (regexes.trueBoolean.test(entry.trim())) return true;
					return undefined;
				}
				case 'string': {
					if (!entry) return '';
					if (headers[i] === 'nationality') {
						if (entry === 'USA') return 'United States of America';
						if (entry === 'Russia') return 'Russian Federation';
					}
					return entry;
				}
				default: {
					return undefined;
				}
				}
			});
			return parsed.reduce((acc: {[key: string]: string | number | boolean | undefined}, curr, i) => {
				acc[rawHeaders[i]] = curr;
				return acc;
			}, {});
		});
	}, [knownFields, setAlert]);

	const [parsedData, setParsed] = useState([] as ({[key: string]: string | number | boolean} & { __player?: Player })[]);

	const handleClear = useCallback(() => {
		if (textRef.current) {
			textRef.current.value = '';
		}
		if (outputRef.current) {
			outputRef.current.value = '';
		}
		setHeaders([]);
		setParsed([]);
	}, [textRef, outputRef, setHeaders, setParsed]);

	const handleReset = useCallback(() => {
		setHeaders(autoHeaders);
	}, [setHeaders, autoHeaders]);

	const handleInput = useCallback(() => {
		let textArea = textRef.current;
		if (!textArea) return;
		let text = textArea.value;
		if (!text.trim()) return;
		let json = CSV.parse(text, {
			delimitersToGuess: [',', '\t'],
			fastMode: true,
			skipEmptyLines: 'greedy'
		} as CSV.ParseConfig) as CSV.ParseResult<string[]>;
		let data = handleData(json.data);
		setParsed(data);
		let output = outputRef.current;
		if (!output) return;
		output.value = JSON.stringify(data, null, 4);
	}, [setParsed, textRef, outputRef, handleData]);

	const handleShow = useCallback(() => {
		let data = handleData([]);
		setParsed(data);
	}, [setParsed, handleData]);

	const rawHeaders = useMemo(() => {
		if (!parsedData.length) return [];
		return Object.keys(parsedData[0]);
	}, [parsedData]);

	const [oldEntry, newEntry] = useMemo(() => {
		if (!parsedData.length) return [[], []];
		let newEntry = [];
		let oldEntry = [];
		for (let i = 0; i < parsedData.length; i++) {
			let entry = Object.assign(parsedData[i], { __index: i });
			let player: Player;
			let index = headers.indexOf('firstName');
			let field = rawHeaders[index];
			let firstName = (entry[field] as string).toLowerCase();
			player = props.players.find(p => {
				if (entry[0] === p.id) return true;
				if (!requireLastName) return p.firstName.toLowerCase() === firstName;
				return (p.firstName + ' ' + p.lastName).toLowerCase() === firstName;
			});
			if (player) {
				entry.__player = player;
				entry = Object.assign(player, entry);
				oldEntry.push(entry);
			} else
			if (allowNewPlayers) {
				newEntry.push(entry);
			}
		}
		return [oldEntry, newEntry];
	}, [parsedData, rawHeaders, headers, props.players, requireLastName, allowNewPlayers]);

	const playerData = useMemo(() => {
		return oldEntry.concat(newEntry);
	}, [oldEntry, newEntry]);

	const { startLoading, stopLoading } = useContext(AlertContext);

	const handleSubmit = useCallback(() => {
		let newData = newEntry.map((p) => {
			for (let i = 0; i < rawHeaders.length; i++) {
				if (headers[i]) {
					let meta = knownFields[headers[i]];
					if (rawHeaders[i] !== undefined && rawHeaders[i] !== null) {
						if (meta.convert) p = Object.assign(p, meta.convert(p[rawHeaders[i]] as string, p as any as Player));
						else p[headers[i]] = p[rawHeaders[i]];
					}
					delete p[rawHeaders[i]];
				}
			}
			return p;
		});
		let oldData = oldEntry.reduce((acc: {[key: string]: {[key: string]: any}}, p) => {
			let newObj = {} as {[key: string]: any};
			for (let i = 0; i < rawHeaders.length; i++) {
				if (headers[i]) {
					let meta = knownFields[headers[i]];
					if (rawHeaders[i] !== undefined && rawHeaders[i] !== null) {
						if (meta.convert) newObj = Object.assign(newObj, meta.convert(p[rawHeaders[i]] as string, newObj as any as Player));
						else newObj[headers[i]] = p[rawHeaders[i]];
					}
				} else newObj[rawHeaders[i]] = p[rawHeaders[i]];
			}
			acc[p.id as string] = newObj;
			return acc;
		}, {});
		startLoading();
		Promise.all([
			Object.keys(oldData).length ? POST({
				url: ['tournament', props.id, 'bulkEditPlayers'].join('/'),
				data: oldData
			}) : Promise.resolve(),
			newData.length ? POST({
				url: ['tournament', props.id, 'bulkAddPlayers'].join('/'),
				data: newData
			}) : Promise.resolve()
		])
			.then(props.leave)
			.then(props.updatePlayerDict)
			.finally(stopLoading);
	}, [handleClear, oldEntry, newEntry, headers, rawHeaders, knownFields, props.id, props.leave, props.updatePlayerDict]);

	const setField = useCallback((i: number, k: string, content: any) => {
		let data = parsedData.slice(0);
		data[i][k] = content;
		setParsed(data);
	}, [parsedData, setParsed]);

	const [headerKey, setHeaderKey] = useState('');
	const [over, setOver] = useState(-2);

	const handleDragEnd = useCallback(() => {
		if (!headerKey || over < -1) return;
		let swap = headers.indexOf(headerKey);
		let newHeaders = headers.slice(0);
		let [removed] = over > -1 ? newHeaders.splice(over, 1, headerKey) : [undefined];
		if (swap >= 0) newHeaders.splice(swap, 1, removed);
		setHeaders(newHeaders);
		setHeaderKey('');
		setOver(-2);
	}, [headerKey, over, setHeaders, headers, setHeaderKey, setOver]);

	const playerHeaders = useMemo(() => {
		return (
			<tr>
				{rawHeaders.map((h, i) => <th key={['key', i].join('.')}>
					{headers[i] ? 
						<div
							className={['fieldBox', styles.parsedHeader, styles.tableHeader, over === i ? styles.dragEnter : ''].join(' ')}
							draggable
							onDragStart={() => setHeaderKey(headers[i])}
							onDragEnd={handleDragEnd}
							onDragEnter={() => setOver(i)}
						>
							{knownFields[headers[i]].name}
						</div> :
						<div
							className={['fieldBox', styles.tableHeader, over === i ? styles.dragEnter : ''].join(' ')}
							onDragEnter={() => setOver(i)}
						>
							{h}
						</div>
					}
				</th>)}
			</tr>
		);
	}, [rawHeaders, over, setOver, headers]);

	const [highlighted, setHighlighted] = useState('');

	const [errors, setErrors] = useState([] as {
		message: string,
		meta: { header: string, feedback?: JSX.Element | string },
		id: string,
		type: 'error' | 'warn'
	}[]);

	const Errors = useMemo(() => {

		if (playerData.length && !errors.length) return <div className={[styles.target, 'scrollable'].join(' ')} onDragEnter={() => setOver(-1)}>
			<button className={['button', styles.submit, styles.clear].join(' ')} onClick={handleClear}>
				CLEAR ALL
			</button>
			<button className={['button', styles.submit].join(' ')} onClick={handleSubmit}>
				IMPORT DATA
			</button>
		</div>;

		return <div className={[styles.target, 'scrollable'].join(' ')} onDragEnter={() => setOver(-1)}>
			<div className={styles.list}>
				{errors.map((e, i) => {
					return <>
						<div className={['fieldBox', styles.error].join(' ')} onClick={() => {
							setHighlighted(e.id);
							if (e.meta.feedback) setTimeout(() => setAlert({
								message: e.meta.feedback,
								type: 'info',
								title: 'Validation for header: ' + e.meta.header
							}), 800);
						}}>
							{e.message}
						</div>
						<div className='fieldBox button' onClick={() => {							
							let cell = document.getElementById(e.id);
							if (cell) cell.scrollIntoView({
								behavior: 'smooth'
							});
						}}>
							<FaEye />
						</div>
					</>;
				})}
			</div>
			{
				playerData.length ? 
					<>
						<button className={['button', styles.submit, styles.clear].join(' ')} onClick={handleClear}>
						CLEAR ALL
						</button>
					</>:
					null
			}
			<div className={styles.placeholder}>
				When your spreadsheet is uploaded, any errors found will appear here.
				Handle all errors to import the data.
			</div>
		</div>;
	}, [errors, setHighlighted, playerData, handleSubmit, handleClear]);

	const playerRows = useMemo(() => {
		return playerData.map((entry, i) => {
			return <tr key={['tr', i].join(' ')}>
				{rawHeaders.map((h, j) => {
					let value = entry[h];
					let borrowed = false;
					if ((value === '' || value === undefined) && entry.__player) {
						value = entry.__player[headers[j] as keyof Player] as string | number | boolean || '';
						borrowed = true;
					} else
					if (value === undefined) value = '';
					return <td id={[i, j].join('.')} key={['td', i, j].join(' ')}><EditableField
						url=''
						content={value.toString()}
						handleSubmit={(k, v) => Promise.resolve(setField(entry.__index, k, v))}
						name={h}
						classNames={{
							editableField: [
								'fieldBox',
								borrowed ? styles.borrowed : '',
								errors.some(e => e.id === [i, j].join('.')) ? styles.errorCell : '',
								highlighted === [i, j].join('.') ? styles.highlighted : ''
							].join(' ')
						}}
						additionalInputProps={{}}
						useHandleSubmit
						inputType={fieldTypes[j] === 'number' ? 'number' : 'text'}
					/></td>;
				})}				
			</tr>;
		});
	}, [headers, highlighted, errors, playerData]);

	const Headers = useMemo(() => {
		let unused = Object.entries(knownFields).filter(([k]) => !headers.includes(k));
		const resetButton = !headers.every((h, i) => h === autoHeaders[i]) ? <button className={['button', styles.submit, styles.reset].join(' ')} onClick={handleReset}>
			RESET HEADERS
		</button> : null;

		return <div className={[styles.target, 'scrollable'].join(' ')} onDragEnter={() => setOver(-1)}>
			<div className={styles.list}>
				{unused.map(([k, h]) => {
					return <div
						key={k}
						draggable
						className={['fieldBox', styles.parsedHeader].join(' ')}
						onDragStart={() => setHeaderKey(k)}
						onDragEnd={handleDragEnd}
					>
						{h.name}
					</div>;
				})}
			</div>
			{resetButton}
			<div className={styles.placeholder}>
				These are the known headers for each player that ScorchApp processes in a special way. It is important to identify these correctly in your spreadsheet.<br />
				Any data that does not fit a known header category will still be stored and displayed but it is not of any significance to ScorchApp.<br />
				If you think there is a header missing, please contact a developer.
			</div>
		</div>;
	}, [headers, setHeaderKey, handleDragEnd, knownFields, setOver, autoHeaders]);

	useEffect(() => {
		let e = [] as {
			message: string,
			id: string,
			type: 'error' | 'warn',
			meta: { header: string, feedback?: JSX.Element | string }
		}[];
		for (let j = 0; j < headers.length; j++) {
			let h = headers[j];
			if (!h) continue;
			let meta = knownFields[h];
			for (let i = 0; i < playerData.length; i++) {
				let player = playerData[i];
				let entry = player[rawHeaders[j]];
				if (meta.type) {
					if (typeof entry !== meta.type) {
						e.push({
							message: `Invalid type: [${i},${h}]: should be a ${meta.type}`,
							id: [i, j].join('.'),
							type: 'error',
							meta: Object.assign(meta, { header: h })
						});
						continue;
					}
				}
				if (meta.validate) {
					let res = meta.validate(entry);
					if (!res) e.push({
						message: `Failed validation: [${i},${h}]: click to see more information`,
						id: [i, j].join('.'),
						type: 'error',
						meta: Object.assign(meta, { header: h })
					});
				}
			}
		}
		if (!headers.includes('firstName')) e.unshift({
			message: 'Must have field \'name\'',
			id: [0, 0].join('.'),
			type: 'error',
			meta: Object.assign(knownFields.firstName, { header: 'firstName' })
		});
		if (newEntry.length && !headers.includes('rating')) e.unshift({
			message: 'Must have field \'rating\'',
			id: [0, 0].join('.'),
			type: 'error',
			meta: Object.assign(knownFields.firstName, { header: 'rating' })
		});
		setErrors(e);
		setHighlighted('');
	}, [headers, playerData, setErrors, newEntry]);

	const [rightTabTop, setRightTabTop] = useState('headers');
	const [rightTabBottom, setRightTabBottom] = useState('errors');

	const handleDownload = useCallback(() => {
		let data = playerData.map(p => {
			for (let i = 0; i < rawHeaders.length; i++) {
				let value = p[rawHeaders[i] as keyof Player];
				delete p[rawHeaders[i]];
				p[rawHeaders[i] || headers[i]] = value;
			}
			return p;
		});
		let properties = Object.getOwnPropertyNames(playerData[0]);
		for (let p of playerData) {
			if (p.__player) delete p.__player;
			for (let prop of properties) {
				if (typeof p[prop] === 'object' && p[prop] !== null) {
					p[prop] = JSON.stringify(p[prop]);
				}
			}
		}
		let csv = CSV.unparse(data);
		const element = document.createElement('a');
		const fileContent = csv;
		const fileFormat = 'csv';
		const file = new Blob([fileContent], {type: 'text/csv'});
		const tourName = props.status.name;
		const date = new Date(Date.now()).toString().slice(4, 15);
	
		element.href = URL.createObjectURL(file);
		element.download = `${tourName}_${date}_players.${fileFormat}`;
		element.click();
	}, [playerData]);
	
	return (
		<>	
			<Core id='pasteInput' className={styles.stage} title='Smart Import by ScorchApp' childClass={styles.body}
				buttons={[
					{
						name: 'Download',
						icon: <FaDownload />,
						onClick: handleDownload
					},
					{
						name: allowNewPlayers ? 'Ignore new players' : 'Allow new players',
						icon: allowNewPlayers ? <FaMinusCircle /> : <FaPlusCircle />,
						onClick: () => setNew(!allowNewPlayers)
					}
				]}>
				<div className='container'>
					<div className={styles.pasteContainer} onDragEnter={() => setOver(-1)}>
						<div className={styles.paste}>
							<div className={[styles.pasteArea].join(' ')}>
								<textarea
									ref={textRef}
									className={styles.textarea}
									name='textArea'
									placeholder='Please paste your input in csv (comma-separated values) format&#10;\
										You can do that by going to Excel or your spreadsheet program of choice and selecting everything.&#10;\
										Copy it and paste it here, then hit SUBMIT when you&#39;re happy.'
									onClick={(e) => (e.target as HTMLTextAreaElement).select()}
								/>
								{!playerData.length ? <button className={[styles.submit, 'button'].join(' ')} onClick={handleInput}>
									SUBMIT
								</button> : null }
							</div>
						</div>
					</div>
				</div>
				<div className='container' style={{display: 'none' }}>
					<div className={styles.pasteContainer} onDragEnter={() => setOver(-1)}>
						<div className={styles.paste}>
							<div className={[styles.pasteArea].join(' ')}>
								<textarea ref={outputRef} className={[styles.output, 'scrollable'].join(' ')} name='textArea' placeholder='Raw data output'/> 
							</div>
						</div>
					</div>
				</div>
				<div className={['container', styles.table].join(' ')}>
					<div className={[styles.pasteContainer, 'scrollable'].join(' ')}>
						{
							!playerRows.length ?
								<div className={[styles.placeholder, styles.description].join(' ')}>
									Welcome to Smart Import! Here you can use spreadsheet data to create new players.<br />
									ScorchApp will automatically try and detect relevant Headers from your data.<br />
									You can then drag and drop the headers if you think any are missing.<br />
									Get pasting to start!
								</div> :
								null
						}
						<table className={styles.playerTable}>
							<thead>
								{playerHeaders}
							</thead>
							<tbody>
								{playerRows}
							</tbody>
						</table>
					</div>
				</div>
			</Core>
			<div className={[tournStyles.profile, styles.headerSection].join(' ')}>
				<div className={tournStyles.profileStage}>
					<TabSection
						name={rightTabTop}
						setName={(name: string) => setRightTabTop(name)}
						nameDict={{
							headers: {
								icon: FaHeading,
								color: 'lightgrey'
							}
						}}
					/>
					{
						rightTabTop === 'headers' ?
							Headers :
							null
					}
				</div>
			</div>
			<div className={[tournStyles.profile, styles.errors].join(' ')}>
				<div className={tournStyles.profileStage}>
					<TabSection
						name={rightTabBottom}
						setName={(name: string) => setRightTabBottom(name)}
						nameDict={{
							errors: {
								icon: FaExclamationCircle,
								color: '#385898'
							}
						}}
					/>
					{
						rightTabBottom === 'errors' ?
							Errors :
							null
					}
				</div>
			</div>
		</>
	);
}