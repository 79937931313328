import React, { Context, ReactNode, createContext, ReactElement } from 'react';
import { Player, TournamentModes, TournamentSettings, NextButtonStates } from './interfaces';
import { AlertProps } from './tournament/Alert';

type ContextValue<T> = [Context<T>, T];

export default function Contexts({ values, children }: {
	values: ContextValue<any>[]
	children?: ReactNode
}) {

	return values.reduce((acc, [context, value]) => {
		return (
			<context.Provider value={value}>
				{acc}
			</context.Provider>
		);
	}, children) as any as ReactElement;
	
}

export const SettingsContext = createContext({} as TournamentSettings);
SettingsContext.displayName = 'SettingsContext';

export const PlayerDictContext = createContext(new Map() as Map<string, Player>);
PlayerDictContext.displayName = 'PlayerDictContext';

type AddOns = 'rating' | 'score';
export const DisplayNameContext = createContext((() => '') as (id: string | Player, addOns?: AddOns[]) => string);
DisplayNameContext.displayName = 'DisplayNameContext';

export const AlertContext = createContext({
	setAlert: (props: AlertProps) => {},
	closeAlert: () => {},
	triggerConfirm: (message: string, resolve?: () => void, reject?: () => void) => {},
	startLoading: () => {},
	stopLoading: () => {}
});
AlertContext.displayName = 'AlertContext';

export const LoadedContext = createContext(true);
LoadedContext.displayName = 'LoadedContext';

export const SetModeContext = createContext((m: TournamentModes) => {});
SetModeContext.displayName = 'SetModeContext';

export const NextButtonContext = createContext({
	nextButton: 'skeleton',
	setNextButton: (v: NextButtonStates) => {}
});
NextButtonContext.displayName = 'NextButtonContext';
