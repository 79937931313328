import React, { Dispatch, SetStateAction, useState, useCallback, useEffect, useMemo, useReducer } from 'react';
import { cookies } from 'utils/requests';
import { defaultColumns } from '../../Tournament';

export function useCacheColumns(shouldRun: boolean) {

	const [columns, setColumns] = useState(defaultColumns);

	const boundaries = useMemo(() => columns.reduce((acc, curr) => {
		acc.push((acc[acc.length - 1] ?? 0) + curr);
		return acc;
	}, [] as number[]).slice(0, -1), [columns]);
	const loadColumns = useCallback(async () => {
		let cols: string = await cookies.get('columns');
		if (!cols) return;
		setColumns(cols.split(',').map(nb => Number(nb)));
	}, [setColumns]);
	const [cachedColumns, setCached] = useState(columns);

	const cacheColumns = useCallback(() => {
		if (!shouldRun) return;
		if (JSON.stringify(columns) === JSON.stringify(cachedColumns)) return;
		cookies.set('columns', columns.map(nb => nb.toString()).join(','));
		setCached(columns);
	}, [columns]);

	return { columns, setColumns, boundaries, loadColumns, cacheColumns };

}

function dispatchEvent(eventName: string): void {
	let event = document.createEvent('HTMLEvents') as Event & { eventName: string };
	event.initEvent(eventName, true, true);
	event.eventName = eventName;
	document.dispatchEvent(event);
}

const dockFactor = 70;

export function useMouseEvents({ columns, boundaries, setColumns, toggleSidebar, toggleRightTab, shouldRun }: {
	columns: number[],
	boundaries: number[],
	setColumns: (columns: number[]) => void
	toggleSidebar: (set?: boolean) => void,
	toggleRightTab: (set?: boolean) => void
	shouldRun: boolean
}) {

	const [resize, setResize] = useState(0);
	const [mouseClick, setMouseClick] = useState(0);

	const handleMouseMove = useCallback((e: MouseEvent) => {
		let percentX = (e.x - dockFactor) / (window.innerWidth - dockFactor) * 100;
		if (mouseClick) {
			let prev = (columns.slice(0, mouseClick - 1).reduce((acc, curr) => acc += curr, 0));
			let sum = prev + columns[mouseClick - 1] + columns[mouseClick];
			let left = percentX - prev;
			let right = sum - percentX;
			let threshold = 2;
			if (left < threshold) {
				right += left;
				left = 0;
				if (mouseClick === 1) toggleSidebar(false);
				dispatchEvent('mouseup');
			} else
			if (right < threshold) {
				left += right;
				right = 0;
				if (mouseClick === columns.length - 1) {
					dispatchEvent('mouseup');
					toggleRightTab(false);
				}
			}
			columns[mouseClick - 1] = left;
			columns[mouseClick] = right;
			setColumns(columns.slice(0));
		} else {
			let threshold = 0.4;
			let index = boundaries.findIndex(b => Math.abs(b - percentX) < threshold);
			if (index === 0 && boundaries[0] === 0) index = -1;
			else if (index === boundaries.length - 1 && boundaries[boundaries.length - 1] === 100) index = -1;
			setResize(index + 1);
		}
	}, [mouseClick, columns, setResize, setColumns, boundaries, toggleSidebar, toggleRightTab]);

	useEffect(() => {
		if (!shouldRun) return;
		document.addEventListener('mousemove', handleMouseMove);
		return () => document.removeEventListener('mousemove', handleMouseMove);
	}, [mouseClick, handleMouseMove, shouldRun]);
	useEffect(() => {
		if (!shouldRun) return;
		if (!resize) return;
		let callback = () => setMouseClick(resize);
		document.addEventListener('mousedown', callback);
		return () => document.removeEventListener('mousedown', callback);
	}, [resize, setMouseClick, shouldRun]);
	useEffect(() => {
		if (!shouldRun) return;
		let callback = () => setMouseClick(0);
		document.addEventListener('mouseup', callback);
		return () => document.removeEventListener('mouseup', callback);
	}, [resize, setMouseClick, shouldRun]);

	return { resize };
}