import React, { useState, useCallback, useMemo, useEffect, useContext, RefObject, useRef, useReducer } from 'react';
import { TournamentStatus, TournamentSettings } from '../interfaces';
import { GET } from 'utils/requests';
import { Result } from 'resources/result';
import { DisplayNameContext } from '../Contexts';
import { FaCircle } from 'react-icons/fa';
import SinglePairing from 'tournament/Pairings/SinglePairing';
import { useValidate } from 'tournament/Pairings';

import styles from './css/knockout.module.css';
import Core from './Core';

interface KnockoutProps {
	id: string
	status: TournamentStatus
	settings: TournamentSettings
	setDisplayPlayer: (id: string) => void
	className?: string
	resultKey?: string
	scrollable?: boolean
}
const w = 5; //circleWidth
export default function Knockout(props: KnockoutProps) {

	const [results, setResults] = useState([] as Result[]);
	const updateResults = useCallback(() => {
		if (!props.id) return;
		if (!props.status.round) return;
		return GET({
			url: '/tournament/' + props.id + '/getPairings',
			params: { round: null, withResults: true }
		}).then(setResults);
	}, [setResults, props.id, props.status.round]);
	useEffect(() => {
		updateResults();
	}, [props.resultKey, updateResults]);

	const totalRounds = 6;
	//const totalRounds = useMemo(() => props.settings.totalRounds === 'Infinity' ? results.reduce((acc, curr) => curr.round > acc ? curr.round : 0, 0) : props.settings.totalRounds, [props.settings, results]);

	const columns = useMemo(() => {
		let arr = [] as Result[][];
		if (!totalRounds) return arr;
		for (let i = 1; i < totalRounds + 1; i++) {
			let power = (totalRounds) - i;
			let total = 2 ** power;
			if (!arr[i]) arr[i] = [];
			for (let r of results) {
				if (r.round !== i) continue;
				arr[i].push(r);
				if (arr[i].length === total) break;
			}
		}
		return arr;
	}, [results, props.status.round]);

	const getDisplayName = useContext(DisplayNameContext);
	const validateForm = useValidate(props.settings);

	const stage = useRef() as RefObject<HTMLDivElement>;
	const [coords, setCoords] = useReducer((state: [DOMRect, DOMRect][][], action: [DOMRect, DOMRect][][]) => {
		for (let i = 0; i < action.length; i++) {
			if (!state[i]) return action;
			for (let j = 0; j < action[i].length; j++) {
				let s = state[i][j];
				let a = action[i][j];
				if (!s) return action;
				if (s[0].x !== a[0].x) return action;
				if (s[0].y !== a[0].y) return action;
				if (s[1].x !== a[1].x) return action;
				if (s[1].y !== a[1].y) return action;
			}
		}
		return state;
	}, []);
	const [hasRenderedLines, setRenderedLines] = useState(false);
	const renderLines = useCallback(() => {
		if (hasRenderedLines) return;
		if (!results.length) return;
		let starts = Array.from(document.getElementsByClassName(styles.winner));
		let ends = Array.from(document.getElementsByClassName(styles.all));

		let dividers = [] as HTMLDivElement[];
		for (let i = 1; i < totalRounds; i++) {
			dividers.push(document.getElementById(['divider', i].join('.')) as HTMLDivElement);
		}
		
		let c = [] as [DOMRect, DOMRect][][];
		let tos = [] as {[key: string]: HTMLDivElement}[];
		for (let to of ends) {
			let [round, id] = to.getAttribute('data-svg').split('.');
			if (!tos[Number(round)]) tos[Number(round)] = {};
			tos[Number(round)][id] = to as HTMLDivElement;
		}
		let hasRendered = false;
		for (let from of starts) {
			let [round, id] = from.getAttribute('data-svg').split('.');
			if (!tos[Number(round)]) continue;
			let to = tos[Number(round)][id];
			if (!from || !to) continue;
			if (!c[Number(round)]) c[Number(round)] = [];
			c[Number(round)].push([
				from.getBoundingClientRect(),
				to.getBoundingClientRect()
			]);
			if (!hasRendered) hasRendered = true;
		}
		setCoords(c);
		setRenderedLines(hasRendered);
	}, [results.length, totalRounds, setCoords, hasRenderedLines, setRenderedLines]);
	const tL = useMemo(() => coords.flat(2).reduce((acc, curr) => [curr.left < acc[0] ? curr.left : acc[0], curr.top < acc[1] ? curr.top : acc[1]], [10000, 10000]), [coords]);
	const bR = useMemo(() => coords.flat(2).reduce((acc, curr) => [curr.left > acc[0] ? curr.left : acc[0], curr.top > acc[1] ? curr.top : acc[1]], [0, 0]), [coords]);
	useEffect(() => {
		renderLines();
	});
	useEffect(() => {
		let x = () => setRenderedLines(false);
		window.addEventListener('resize', x);
		return () => window.removeEventListener('resize', x);
	}, [setRenderedLines]);

	let min = useMemo(() => {
		if (!stage.current) return [0, 0];
		let r = stage.current.getBoundingClientRect();
		return [r.left, r.top];
	}, [stage.current]);

	return <Core id='knockout' className={props.className} childClass={styles.stage} childRef={stage} scrollable={props.scrollable}>
		<div className={styles.columns}>
			{columns.map((c, i) => {
				if (!c) return null;
				return <>
					<div key={['column', i].join('.')} className={styles.column} style={{ marginTop: !c.length ? 0 : (100 * (2 ** (i - 1)) - 100) + 'px' }}>
						{/*<div style={{ height: (2 * (i - 1)) + 'em' }} />*/}
						{c.map((r, j) => {
							return <div key={['matchUp', j].join('.')} className={styles.matchUp}>
								<div className={[styles.white].join(' ')}>
									{i > 1 ? <div data-svg={[i - 1, 2 * j /*r.idW*/].join('.')} className={styles.all} /> : null}
									<FaCircle style={{color: 'white'}} />
									<div
										className={['playerInfo', styles.playerInfo, r.idW === 'bye' ? styles.bye : ''].join(' ')}
										onClick={(e: React.MouseEvent<HTMLDivElement>) => r.idW === 'bye' ? null : props.setDisplayPlayer(r.idW)}
									>{getDisplayName(r.idW)}</div>
									{r.matchW ? <div data-svg={[i, j /*r.idB*/].join('.')} className={styles.winner} /> : null}
								</div>
								<SinglePairing
									result={props.settings.displayPoints === 'match' ?
										[r.matchW, r.matchB, {}] :
										[r.resultWhite, r.resultBlack, {}]
									}
									gameLinks={{}}
									tournamentID={props.id}
									inputClassName={''}
									validate={validateForm}
									handleSubmit={updateResults}
								/>
								<div className={[styles.black].join(' ')}>
									{i > 1 ? <div data-svg={[i - 1, 2 * j + 1 /*r.idB*/].join('.')} className={styles.all} /> : null}
									<FaCircle style={{color: 'black'}} />
									<div
										className={['playerInfo', styles.playerInfo, r.idB === 'bye' ? styles.bye : ''].join(' ')}
										onClick={(e: React.MouseEvent<HTMLDivElement>) => r.idB === 'bye' ? null : props.setDisplayPlayer(r.idB)}
									>{getDisplayName(r.idB)}</div>
									{r.matchB ? <div data-svg={[i, j /*r.idB*/].join('.')} className={styles.winner} /> : null}
								</div>
							</div>;
						})}
						{/*<div style={{ height: (2 * (i - 1)) + 'em' }} />*/}
					</div>
				</>;
			})}
		</div>
		<svg className={styles.connectors} style={{left: tL[0] - min[0] + w / 2, top: tL[1] - min[1] + w / 2 }} width={bR[0] < tL[0] ? 0 : bR[0] - tL[0]} height={bR[1] < tL[1] ? 0 : bR[1] - tL[1]}>						
			{coords.map((round, i) => <g key={['lines', i].join('.')} className={['ct-series', 'ct-series-' + String.fromCharCode(i + 65 - 1)].join(' ')}>
				{round.map(([from, to], j) => {
					const [a, b] = [1 / 3, 2 / 3];
					const q = 1 / 2;
					
					let f = [from.left - tL[0], from.top - tL[1]];
					let t = [to.left - tL[0], to.top - tL[1]];
					let m = [(f[0] + t[0]) / 2, (f[1] + t[1]) / 2];
					let c1 = [f[0] + (m[0] - f[0]) * b, f[1] + (m[1] - f[1]) * 0];
					let c2 = [f[0] + (m[0] - f[0]) * 1, f[1] + (m[1] - f[1]) * a];
					let c3 = [m[0] + (t[0] - m[0]) * a, m[1] + (t[1] - m[1]) * 1];

					let q1 = [f[0] + (t[0] - f[0]) * q, f[1] + (t[1] - f[1]) * 0];
					let q2 = [f[0] + (t[0] - f[0]) * 1, f[1] + (t[1] - f[1]) * q];

					return (
						<path
							key={['path', j].join('.')}
							className={[styles.line, 'ct-line'].join(' ')}
							d={Object.entries({
								/* Straight Lines */
								//M: [from.left - tL[0], from.top - tL[1]],
								//L: [to.left - tL[0], to.top - tL[1]]

								/* Single Cubic */
								M: f.join(' '),
								C: [
									q1,
									q2,
									t
								].map(l => l.join(' ')).join(', ')

								/* Cubic Beziers *//*
								M: f.join(' '),
								C: [
									c1,
									c2,
									m
								].map(l => l.join(' ')).join(', '),
								S: [
									c3,
									t
								].map(l => l.join(' ')).join(', ')*/
							}).map(([k, v]) => k + v).join(' ')}
						/>
					);
				})}
			</g>)}		
		</svg>
	</Core>;
}