import React, { Dispatch, SetStateAction, useState, useReducer, useCallback, useEffect } from 'react';
import { defaultColumns } from '../../Tournament';
import { TournamentModes } from '../../interfaces';

export function useModes({
	columns,
	setColumns,
	boundaries,
}: {
	columns: number[]
	setColumns: Dispatch<number[]>
	boundaries: number[]
}) {
	
	const [mode, setMode] = useReducer((_s, a?: TournamentModes) => a || '', '') as [TournamentModes, Dispatch<TournamentModes | void>];

	const [rightTab, setRightTab] = useState('settings' as 'settings' | 'public' | 'player' | 'automation');
	const [switchMode, setSwitchMode] = useState(false); //switchPairingsMode
	const switchPairings = useCallback((set?: boolean) => setSwitchMode(set ?? !switchMode), [switchMode]);
	
	const [stateKey, setStateKey] = useState('');

	const reduceRightTab = useCallback((state: boolean, set: boolean): boolean => {
		
		if (set !== false && boundaries[boundaries.length - 1] === 100) {
			let _columns = columns.slice(0);
			_columns[_columns.length - 1] = defaultColumns[_columns.length - 1];
			if (_columns[_columns.length - 2] < defaultColumns[_columns.length - 2]) _columns = defaultColumns as [number, number, number];
			else _columns[_columns.length - 2] = _columns[_columns.length - 2] - defaultColumns[_columns.length - 1];
			setColumns(_columns);
			return true;
		} else {
			return typeof set === 'boolean' ? set : !state;
		}
	}, [columns, boundaries]);
	const [showRightTab, toggleRightTab] = useReducer(reduceRightTab, true) as [boolean, Dispatch<boolean | void>];


	//sidebar
	const reduceSidebar = useCallback((state: boolean, set?: boolean): boolean => {
		if (columns[0] === 0) {
			let _columns = columns.slice(0);
			_columns[0] = defaultColumns[0];
			if (_columns[1] < defaultColumns[0]) _columns = defaultColumns as [number, number, number];
			else _columns[1] = _columns[1] - defaultColumns[0];
			setColumns(_columns);
			return true;
		} else return typeof set === 'boolean' ? set : !state;
	}, [columns, setColumns]);
	const [sidebar, toggleSidebar] = useReducer(reduceSidebar, true) as [boolean, Dispatch<boolean | void>];

	//Display Player

	const [displayPlayer, setDisplayPlayerRaw] = useState('');
	const setDisplayPlayer = useCallback((id: string) => {
		if (!showRightTab) toggleRightTab(true);
		if (rightTab !== 'player') setRightTab('player');
		setDisplayPlayerRaw(id);
	}, [setDisplayPlayerRaw, rightTab, setRightTab, showRightTab, toggleRightTab]);

	return {
		mode,
		setMode,
		switchMode,
		switchPairings,
		stateKey,
		setStateKey,
		rightTab,
		setRightTab,
		showRightTab,
		toggleRightTab,
		sidebar,
		toggleSidebar,
		displayPlayer,
		setDisplayPlayer
	};
}