import React, { useCallback, ReactElement } from 'react';
import { Player } from '../interfaces';

import styles from 'css/profile.module.css';
import sidebarStyles from 'css/sidebar.module.css';
import { AiOutlineCheck } from 'react-icons/ai';

interface FormProps {
	id: string
	onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
    action: string
    player: Player
    players: Player[]
    getDisplayName: (player: string) => string
}

export function SingleClash(props: FormProps): ReactElement {

	const { players } = props;

	const handleInput = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
		let input = e.target as HTMLInputElement;
		let text = input.value;
		if (!text.trim()) return;
		let length = text.length;
		let choices = props.players.filter(p => p.id !== props.player.id && props.getDisplayName(p.id).toLowerCase().startsWith(text.toLowerCase()));
		let firstChoice = choices[0];
		if (!firstChoice) return;
		let autofill = props.getDisplayName(firstChoice.id);
		input.value = autofill;
		input.select();
		input.setSelectionRange(length, autofill.length, 'forward');
	}, [players]);
	
	return (
		<form
			target='_self'
			onSubmit={props.onSubmit}
			method='POST'
			action={props.action}
		>
			<div className={styles.scoreSection}>
				<input type='text' className={[sidebarStyles.input, styles.input].join(' ')} name='oppName' required
					placeholder='Opponent name'
					onInput={handleInput}
					autoComplete={'off'}
					style={{gridArea: 'round / round / GP / GP'}}
					autoFocus
				/>
				<button tabIndex={-1} className={sidebarStyles.submit}
					style={{gridArea: 'submit'}}
				>
					<AiOutlineCheck />
				</button>
			</div>
		</form>
	);
}
