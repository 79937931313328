import React, { useDebugValue, useState, ReactElement } from 'react';
import os from 'os';
import * as regexes from './regexes';

import { dict as countries, specify as specifyFlags } from 'assets/countries.json';
import { isElectron } from './electron';
import { Platform } from '../interfaces';
const flags = 'https://ratings.fide.com/svg/|.svg';

export function getFlag(v: string): ReactElement | null {
	if (!v) return null;
	let title = countries[v as keyof typeof countries];
	let src = flags.replace('|', v);
	let custom = specifyFlags.includes(v);
	if (custom) src = '/flags/|.svg'.replace('|', v);
	return <img src={src} alt={v} title={title} data-custom-flag={custom ? true : undefined} />;
}

export function getOS(): '' | Platform {
	if (isElectron) {
		let p = os.platform();
		switch (p) {
		case 'aix':
			return '';
		case 'android':
			return '';
		case 'darwin':
			return 'mac';
		case 'freebsd':
			return '';
		case 'linux':
			return '';
		case 'openbsd':
			return '';
		case 'sunos':
			return '';
		case 'win32':
			return 'windows';
		case 'cygwin':
			return '';
		case 'netbsd':
			return '';
		}
	} else {
		let OSName = '';
		if (navigator.appVersion.includes('Win')) OSName = 'windows';
		if (navigator.appVersion.includes('Mac')) OSName = 'mac';
		if (navigator.appVersion.includes('X11')) OSName = ''; //'unix';
		if (navigator.appVersion.includes('Linux')) OSName = 'ubuntu';
		return OSName as Platform;
	}
}

export function px(...arr: (string | number)[]): string {
	return ['', ...arr].join('/');
}

export function pl(arr: any[] | number): 's' | '' {
	if (Array.isArray(arr)) {
		if (arr.length > 1) return 's';
		return '';
	}
	switch (typeof arr) {
	case 'number':
		if (arr > 1) return 's';
		return '';
	case 'undefined':
		return 's';
	default:
		return '';
	}
}

export function capitalise(str: string): string {
	if (!str) return str;
	return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase().replace(' id', ' ID');
}

export function pascalise(s: string) {
	if (!s) return '';
	return s.slice(0, 1).toUpperCase() + s.slice(1);
}

export function delay(ms: number): Promise<void> {
	return new Promise((res) => {
		setTimeout(res, ms);
	});
}

export function calculateHypotenuse(base: number, height: number): number {
	return Math.sqrt(Math.pow(base, 2) + Math.pow(height, 2));
}

export function useStateWithLabel(initialValue: any, name: string) {
	const [value, setValue] = useState(initialValue);
	useDebugValue(`${name}: ${value}`);
	return [value, setValue];
}

export function daysBetween(start: Date, end: Date): number {

	const oneDay = 1000 * 60 * 60 * 24; // The number of milliseconds in all UTC days (no DST)
  
	// so it's safe to divide by 24 hours
	return (end.valueOf() - start.valueOf()) / oneDay;
}

export function convertPoolDisplay(pool: number, setting: number): string {
	if (isNaN(pool)) return '';
	if (setting === 0) return letterise(pool);
	if (setting === 1) return pool.toString();
	if (setting === 2) return romanise(pool);
	return pool.toString();
}

export function letterise(num: number): string {
	let n = num - 1;
	let base = String.fromCharCode(65 + (n % 26));
	let res = base.repeat(Math.floor(n / 26) + 1);
	return res;
}

export function romanise(num: number): string {
	if (isNaN(num)) return '';
	let digits: string[] = num.toString().split('');
	let key = ['','C','CC','CCC','CD','D','DC','DCC','DCCC','CM',
		'','X','XX','XXX','XL','L','LX','LXX','LXXX','XC',
		'','I','II','III','IV','V','VI','VII','VIII','IX'];
	let roman = '';
	let i = 3;
	while (i--) {
		let d = parseInt(digits.pop() as string);
		roman = (key[d + (i * 10)] || '') + roman;
	}
	return Array(+digits.join('') + 1).join('M') + roman;
}

export function parseName(argument: string) {
	let def = {
		lastName: '',
		firstName: '',
		middleName: ''
	};
	if (argument.includes(',')) {
		let args = argument.split(',');
		if (args.length > 2) throw new SyntaxError('max 1 comma in searchstring');
		def.lastName = args[0].trim();
		let remainder = args[1].trim().split(regexes.spaces);
		def.firstName = remainder[0].trim();
		def.middleName = remainder.slice(1).join(' ');
	} else {
		let names = argument.split(regexes.spaces);
		def.lastName = names.pop();
		if (names[0]) def.firstName = names.shift();
		if (names[0]) def.middleName = names.join(' ');
	}
	return def;
}

export function camelCaseToWords(property: string) {
	if (typeof property !== 'string') return property;
	if (!regexes.camelCase.test(property)) return property;
	let barred = property.replace(regexes.lettersCapital, v => '|' + v);
	let raw = barred.split('|');
	let words = [capitalise(raw[0]), ...raw.slice(1)];
	for (let i = 0; i < words.length; i++) {
		if (words[i] === 'Id') words[i] = 'ID';
	}
	return words.join(' ');
}

export function isDate(v: any): boolean {
	if (typeof v !== 'string') return false;
	if (!isNaN(Number(v))) return false;
	let date = new Date(v);
	if (isNaN(date.valueOf())) return false;
	return true;
}