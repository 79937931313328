import { History, Player } from 'models';

export const TieBreaks = {
	gamePoint: {
		name: 'By game point total',
		func: (a: Player, b: Player): number => {
			return b.gamePoints - a.gamePoints;
		}
	},
	rating: {
		name: 'By rating',
		func: (a: Player, b: Player): number => {
			return b.rating - a.rating;
		}
	},
	null: null as null,
	h2h: {
		name: 'By head-to-head score',
		func: (a: Player, b: Player): number => {
			return b.scoreAgainst(a.id) - a.scoreAgainst(a.id);
		}
	},
	gamePointDiff: {
		name: 'By net game points',
		func: (a: Player, b: Player): number => {
			return b.netGamePoint - a.netGamePoint;
		}
	},
	whites: {
		name: 'By times played black',
		func: (a: Player, b: Player): number => {
			return a.whiteTotal - b.whiteTotal;
		}
	},
	progScore: {
		name: 'By progressive score',
		func: (a: Player, b: Player): number => {
			return b.progressiveScore - a.progressiveScore;
		}
	},
	wins: {
		name: 'By most wins',
		func: (a: Player, b: Player): number => {
			if (!b.histories || !a.histories) return 0;
			return (b.histories as History[]).filter(h => h.match === 1).length - (a.histories as History[]).filter(h => h.match === 1).length;
		}
	},
	gameh2h: {
		name: 'By head-to-head of game points',
		func: (a: Player, b: Player): number => {
			return b.gameScoreAgainst(a.id) - a.gameScoreAgainst(a.id);
		}
	},
	perfRating: {
		name: 'By performance rating',
		func: (a: Player, b: Player): number => {
			return b.performanceRating - a.performanceRating;
		}
	},
	oppRating: {
		name: 'By average rating cut',
		func: (a: Player, b: Player): number => {
			if (b.oppRatingSum !== a.oppRatingSum) return b.oppRatingSum - a.oppRatingSum;
			let [aHistories, bHistories] = [a.histories?.filter((h: History) => h), b.histories?.filter((h: History) => h)];
			if (!aHistories || !bHistories || !aHistories.length || !bHistories.length) return 0;
			let aSorted = (aHistories as History[]).sort((x, y) => x.oppRating - y.oppRating);
			let bSorted = (bHistories as History[]).sort((x, y) => x.oppRating - y.oppRating);
			while (aSorted && bSorted) {
				let [aNext, bNext] = [aSorted.shift(), bSorted.shift()];
				if (aNext == undefined || bNext === undefined) break;
				if (!aNext && !bNext) continue;
				let [aAv, bAv] = [aSorted.reduce((acc, curr) => curr ? acc += curr.oppRating : acc, 0) / aSorted.length, bSorted.reduce((acc, curr) => curr ? acc += curr.oppRating : acc, 0) / bSorted.length];
				if (aAv !== bAv) return bAv - aAv;				
			}
			return 0;
		}
	},
	alphabetical: {
		name: 'Alphabetical',
		func: (a: Player, b: Player): number => {
			if (b.firstName > a.firstName) return -1;
			else if (a.firstName > b.firstName) return 1;
			return 0;
		}
	}
};
