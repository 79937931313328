import React, { useCallback } from 'react';
import { Bottom } from 'components/Buttons';
import { FaTimes, FaWindowMaximize, FaCogs, FaSyncAlt, FaBullhorn } from 'react-icons/fa';
import styles from 'css/profile.module.css';
import { POST } from 'utils/requests';
import { Player } from '../../interfaces';

export default function Banner(props: {
	id: string
	displayPlayer: string
	modifyPlayerDict: (p: Player, hash: string) => void
	clearDisplayPlayer: () => void
}) {

	const syncPlayer = useCallback(() => POST({
		url: ['', 'tournament', props.id, 'syncPlayer'].join('/'),
		data: { id: props.displayPlayer }
	}).then((p) => props.modifyPlayerDict(p, Math.random().toString().slice(2, 9))), [props.id, props.displayPlayer, props.modifyPlayerDict]);

	return (
		<div className={['bottomBannerBackground', styles.bottomBanner].join(' ')}>
			<div className='bottomBanner'>
				{Bottom([
					{
						name: 'Sync profile',
						icon: <FaSyncAlt key='sync'/>,
						onClick: syncPlayer,
						hide: !props.displayPlayer
					},
					{
						name: 'Close profile',
						className: styles.close,
						icon: <FaTimes key='close' />,
						onClick: props.clearDisplayPlayer,
						hide: !props.displayPlayer
					}
				])}
			</div>
		</div>
	);
}