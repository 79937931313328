import React, { ReactElement, useCallback, useMemo, useContext, useState, useReducer, Dispatch, CSSProperties, useEffect } from 'react';
import cx from 'classnames';
import styles from './css/dock.module.css';
import { TournamentStatus, TournamentSettings, DockOption, ResultObject, TournamentModes, IconProps } from '../interfaces';
import { MdExitToApp } from 'react-icons/md';
import {  FaTh, FaPauseCircle, FaPlayCircle, FaFilter, FaAngleDoubleDown, FaStickyNote, FaFileExport, FaGripLines, FaSyncAlt, FaFileImport, FaCogs, FaBullhorn, FaBomb, FaArrowsAlt, FaSkullCrossbones, FaSitemap, FaSortAlphaUp, FaWrench, FaTools, FaRandom } from 'react-icons/fa';
import { LoadedContext, AlertContext, NextButtonContext } from '../Contexts';
import { TiFlowSwitch } from 'react-icons/ti';
import { cookies, POST } from 'utils/requests';
import { Result } from 'resources/result';
import { TernaryInput } from 'components/Inputs';
import { isElectron } from 'utils/electron';
import Sort from 'screen/Sort';
import Player from 'models/player';
import { px, capitalise } from 'utils/prototype';
import * as regexes from 'utils/regexes';
import useIcon from 'hooks/useIcon';

interface DockProps {
	setMode: (m: TournamentModes) => void
	userRound: number
	status: TournamentStatus
	settings: TournamentSettings
	closeTournament: () => void
	lastRoundOverride: boolean
	setLastRound: (override: boolean) => void
	triggerError: (message: string) => void
	switchPairings: (set?: boolean) => void
	switchMode: boolean
	load: () => Promise<void>
	canReset: boolean
	resetColumns: () => void
	pairingFilters: {[key: string]: boolean | undefined }
	setFilters: (v: {[key: string]: boolean}) => void
	players: Player[]
	updatePlayerDict: () => Promise<void>
	checkForUpdates: () => Promise<void>
}

export const filterPairingOptions = [
	{
		key: 'link',
		name: 'Link',
		func: (r: Result): boolean => !!r.link,
		show: (s: TournamentSettings) => !!s.source
	},
	{
		key: 'started',
		name: 'Started',
		func: (r: Result): boolean => !!r.fetched?.startTime,
		show: (s: TournamentSettings) => !!s.pullResults
	},
	{
		key: 'unfinished',
		name: 'Unfinished',
		func: (r: Result, o: ResultObject): boolean => o[0] == null && o[1] == null
	}
] as DockOption[];

const baseColors = [
	'rgb(150, 150, 255)',
	'yellow',
	'indianred'
];

export default function Dock(props: DockProps): ReactElement {

	const round = useMemo(() => props.userRound || props.status.round, [props.userRound, props.status.round]);
	let lastRound = false;
	if (typeof props.lastRoundOverride !== 'undefined') lastRound = props.lastRoundOverride;
	else lastRound = !props.status.active;
	const hasLoaded = useContext(LoadedContext);

	const { setAlert, closeAlert, startLoading, stopLoading } = useContext(AlertContext);
	const undoRoundPairings = useCallback(() => {
		setAlert({
			type: 'confirm',
			message: 'Really undo all pairings for this round?',
			resolve: () => POST({
				url:['tournament', props.status.id, 'undoRoundPairings'].join('/'),
				data: { round }
			}).then(closeAlert).then(props.load),
		});
	}, [props.status.id, props.status.round, props.load, props.switchPairings, setAlert]);
	const destroyTournament = useCallback(() => {
		let displayName = props.status.name || props.status.id;
		setAlert({
			type: 'confirm',
			message: 'Delete tournament \'' + displayName + '\'?\nPlease note this action cannot be undone.',
			resolve: () => POST({
				url: ['tournament', props.status.id, 'closeAuthorization'].join('/'),
				data: { round }
			}).then(props.closeTournament),
		});
	}, [props.status.id, props.status.round, props.closeTournament, props.status.name, setAlert]);

	const [visibleOptions, setOptions] = useReducer((acc: string, curr: string) => {
		if (acc === curr) return '';
		return curr;
	}, '');

	const [knockoutPlayers, setKnockoutPlayers] = useState([] as Partial<Player>[]);
	const submitKnockout = useCallback(() => {
		return POST({
			url: px('tournament', props.status.id, 'startKnockout'),
			data: {
				players: knockoutPlayers
			}
		}).then(closeAlert).then(props.load);
	}, [props.load, props.status.id, knockoutPlayers, props.load]);
	const knockoutMessage = useMemo(() => {
		const modified = props.players.map(player => {
			let p = Object.assign({}, player, { pool: null });
			return p;
		});
		return <Sort
			className={styles.sort}
			updatePlayerDict={props.updatePlayerDict}
			mode='pools'
			poolName='Bracket'
			players={modified}
			setPlayers={setKnockoutPlayers}
			showSave={false}
			startTo='1'
			description={<>
				Please select which {props.settings.competitors === 'individual' ? 'players' : 'teams'} you would like to take through to the next stage of the tournament.<br />
				You can also group your {props.settings.competitors === 'individual' ? 'players' : 'teams'} into brackets.
			</>}
		/>;
	}, [props.updatePlayerDict, props.players, setKnockoutPlayers]);
	const [shouldAlert, shouldSetAlert] = useReducer(() => Math.random().toString(16).slice(2, 8), '');
	useEffect(() => {
		if (!shouldAlert) return;
		setAlert({
			title: <>Convert to Knockout finals</>,
			type: 'confirm',
			message: knockoutMessage,
			resolve:  submitKnockout,
			tall: true,
			wide: true
		});
	}, [shouldAlert, setAlert, props.settings, knockoutPlayers, submitKnockout]);

	const { nextButton } = useContext(NextButtonContext);
	
	const data = [
		{
			icon: FaFileImport,
			name: 'Smart Import Players',
			onClick: () => props.setMode('pasteInput')
		},
		{
			name: 'Export Mode',
			icon: FaFileExport,
			onClick: () => props.setMode('export')
		},
		{
			name: 'Detailed Settings View',
			icon: FaCogs,
			onClick: () => props.setMode('settings')
		},	
		{
			name: 'Crosstable',
			onClick: () => props.setMode('crossTable'),
			icon: FaTh,
			inactive: !props.status.round
		},
		{
			name: 'Announcements',
			icon: FaBullhorn,
			onClick: () => props.setMode('announcements'),
			inactive: !props.settings.isPublic
		},
		{
			name: 'Knockout View',
			icon: FaSitemap,
			onClick: () => props.setMode('knockout'),
			inactive: props.settings.pairingSystem !== 'knockout' || !props.status.round
		},
		{
			name: 'Quick Drag & Drop',
			icon: <FaSortAlphaUp />,
			onClick: () => props.setMode('sort')
		},
		'divider',
		{
			name: lastRound ? 'Resume Tournament' : 'Pause Tournament',
			onClick: () => {
				if (lastRound && props.status.round >= props.settings.totalRounds) props.triggerError('Increase the number of rounds in Settings to continue!');
				else props.setLastRound(!lastRound);
			},
			icon: lastRound ? FaPlayCircle : FaPauseCircle,
		},
		{
			name: 'Refresh',
			icon: FaSyncAlt,
			onClick: () => {
				startLoading();
				props.load().finally(stopLoading);
			},
			hide: false
		},
		{
			name: !props.switchMode ? 'Switch or Undo Pairings' : 'Back',
			style: { strokeWidth: 1.5 },
			icon: TiFlowSwitch,
			active: props.switchMode,
			onClick: () => props.switchPairings(),
			inactive: ((): boolean => {
				switch (props.settings.pairingSystem) {
				case 'swiss':
					if (!props.status.round) return true;
					if (!props.status.active) return true;
					return false;
				case 'round-robin':
					return false;
				case 'knockout':
					if (props.status.round <= 1) return false;
					if (props.status.systems?.some(s => props.status.round === s.from)) return false;
					return true;
				default:
					return true;
				}
			})()
		},
		{
			name: 'Undo Last Round Pairings',
			icon: FaBomb,
			color: 'indianred',
			inactive: props.userRound && props.userRound !== props.status.round,
			onClick: undoRoundPairings
		},
		{
			name: (() => {
				return 'Convert to Knockout finals';	//TODO
				let systems = ['swiss', 'round-robin', 'knockout'];
				let options = systems.filter(s => props.settings.pairingSystem !== s).map(capitalise).join(' or ');
				return `Convert to ${options} Finals`;
			})(),
			icon: FaRandom,
			onClick: shouldSetAlert,
			inactive: !['next', 'last', 'publish-final'].includes(nextButton)
		},
		{
			name: 'Filter Pairings',
			icon: FaFilter,
			color: 'darkgreen',
			ternaryOptions: true,
			onClick: () => setOptions('Filter Pairings'),
			active: visibleOptions === 'Filter Pairings',
			options: filterPairingOptions.map(o => {
				o.defaultValue = props.pairingFilters[o.key];
				return o;
			}),
			optionsData: {
				ternary: true,
				setValue: (k: string, v: boolean) => props.setFilters({ [k]: v })
			}
		},
		{
			name: 'Player Details',
			icon: FaStickyNote,
			onClick: () => setOptions('Player Details'),
			active: visibleOptions === 'Player Details',
			color: 'darkgreen',
			options: [
				{
					key: 'name',
					name: 'Name',
					locked: true,
					defaultValue: true
				},
				{
					key: 'score',
					name: 'Score'
				},
				{
					key: 'board',
					name: 'Board'
				},
				{
					key: 'rating',
					name: 'Rating'
				}
			] as DockOption[],
			optionsData: {
				setValue: handleDetails
			}
		},
		{
			name: 'Reset Layout',
			icon: FaArrowsAlt,
			onClick: props.resetColumns,
			inactive: !props.canReset
		},
		'divider',
		{
			name: 'Check for Updates',
			icon: FaTools,
			onClick: () => {
				startLoading();
				props.checkForUpdates().finally(stopLoading);
			},
			hide: !isElectron
		},
		{
			name: 'Destroy Tournament',
			icon: FaSkullCrossbones,
			style: { strokeWidth: 1.5 },
			onClick: destroyTournament
		},
		{
			name: 'Exit to Menu',
			icon: MdExitToApp,
			style: { strokeWidth: 1.5 },
			onClick: props.closeTournament
		}
	] as (IconProps | (IconProps & {
		options: DockOption[]
		optionsData: {
			ternary?: boolean
			setValue: (k: string, v: boolean) => void
		}
	}))[];

	const { Icon } = useIcon({ baseColors, hasLoaded });

	return (
		<div className={styles.dock}>
			<div className={cx(styles.stage, 'scrollable')}>
				<div className={[styles.column].join(' ')}>
					{data.map((b, i, arr) => {
						let container = arr.slice(0, i + 1).filter(v => typeof v === 'string').length;
						if (typeof b === 'string') {
							return <div key={['divider', i].join('.')} className={styles.container}>
								<FaGripLines />
							</div>;
						} else {
							if (b.hide) return null;
							return <div key={cx(b.name, i)} className={cx(styles.container, {[styles.active]: b.active})}>
								<div className={cx('button', styles.button, { null: hasLoaded && b.inactive })} onClick={b.onClick || ('options' in b ? () => setOptions(b.name) : null)}>
									<Icon {...b} container={container} />
								</div>
								<div className={styles.name}>
									{b.name}
								</div>
								{'options' in b ?
									<div className={cx(styles.options, {[styles.visible]: visibleOptions === b.name })}>
										<div className={styles.optionsTitle}>{b.name}</div>
										{b.options.map((o, j) => {
											return <div key={cx('option', i, j)}>
												<TernaryInput
													isTernary={b.optionsData.ternary}
													setValue={v => b.optionsData.setValue(o.key, v)}
													{...o}
												/>
												<label htmlFor={o.name}>{o.name}</label>
											</div>;
										})}
									</div> :
									null
								}
							</div>;
						}
					})}
				</div>
			</div>
		</div>
	);

}

async function handleDetails (k: string, v: boolean) {
	let old = await cookies.get('playerDetails');
	let updated: string;
	if (v) {
		updated = old + ',' + k;
	} else {
		updated = old.split(k).join('').split(',,').join(',');
	}
	cookies.set('playerDetails', updated);
	window.dispatchEvent(new Event('cookie'));
}