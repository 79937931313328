import React, { useState, useCallback, useContext, useReducer } from 'react';
import { FaInfoCircle, FaTimes } from 'react-icons/fa';
import { AlertContext, DisplayNameContext } from '../../../Contexts';
import { TournamentStatus, ID, Player, Result } from '../../../interfaces';
import { px } from 'utils/prototype';
import { POST } from 'utils/requests';
import componentStyles from 'css/components.module.css';

interface SwitchProps {
	round: number
	status: TournamentStatus
	playerDict: Map<string, Player>
	updatePlayerDict: () => Promise<void>
}

export default function useSwitch(props: SwitchProps) {

	const getDisplayName = useContext(DisplayNameContext);

	const [selected, select] = useReducer((state: string[], id: string | null) => {
		if (id === null) return [];
		let s = state.slice(0);
		if (state.includes(id)) {
			s.splice(state.indexOf(id), 1);
			return s;
		} else {
			if (state.length >= 2) return state;
			s.push(id);
			return s;
		}
	}, []);

	const { setAlert } = useContext(AlertContext);
	const triggerPairingSwap = useCallback(() => {
		if (selected.length !== 2) throw 'Wrong number of players selected';

		let clash = null as null | [string, string];
		
		if (!selected.includes('bye')) {
			let selectedPlayers = selected.map(id => props.playerDict.get(id));
			let oppPlayers = selectedPlayers.map(p => props.playerDict.get(p.histories[props.round].id));
			if (hasPlayed(selectedPlayers[0], oppPlayers[1])) clash = [selectedPlayers[0].id, oppPlayers[1].id];
			else if (hasPlayed(selectedPlayers[1], oppPlayers[0])) clash = [selectedPlayers[0].id, oppPlayers[1].id];
		}

		setAlert({
			message: <>
				Really switch pairings {getDisplayName(selected[0])} and {getDisplayName(selected[1])}?
				{clash ?
					<><br /><br /><strong>Note: {getDisplayName(clash[0])} has already played {getDisplayName(clash[1])}</strong></> :
					null
				}
			</>,
			type: 'confirm',
			resolve: () => POST({
				url: px('tournament', props.status.id, 'switchPairings'),
				data: {
					swapX: selected[0],
					swapY: selected[1],
					round: props.round
				}
			}).then(() => {
				select(null);
				props.updatePlayerDict();
			}),
			reject: () => select(null)
		});
	}, [props.playerDict, setAlert, selected, select, props.status.id, props.round, getDisplayName]);


	const destroyPairing = useCallback((id: string, idW: string, idB: string) => {
		setAlert({
			message: `Really remove pairing ${getDisplayName(idW)} vs. ${getDisplayName(idB)}?`,
			type: 'confirm',
			resolve: () => {
				POST({
					url: px('tournament', props.status.id, 'destroyPairing'),
					data: { id, round: props.round }
				}).then(() => {
					select(null);
					props.updatePlayerDict();
				});
			},
			reject: () => {}
		});
	}, [setAlert, getDisplayName, props.round, select, props.updatePlayerDict]);

	const SingleSwitch = useCallback(function SingleSwitch({ id, idW, idB, setDisplayPlayer }: {
		id: string
		idW: string
		idB: string
		setDisplayPlayer: (id: string) => void
	}) {

		const getDisplayName = useContext(DisplayNameContext);

		return (
			<div className={componentStyles.resultsBridge}>
				<div className={componentStyles.info} title={getDisplayName(idW)} onClick={() => setDisplayPlayer(idW)}>
					<FaInfoCircle />
				</div>
				<button tabIndex={-1} className={componentStyles.reject} onClick={() => destroyPairing(id, idW, idB)}>
					<FaTimes />
				</button>
				<div className={componentStyles.info} title={getDisplayName(idB)} onClick={() => setDisplayPlayer(idB)}>
					<FaInfoCircle />
				</div>
			</div>
		);
	}, [destroyPairing]);

	return { select, selected, triggerPairingSwap, SingleSwitch };

}

function hasPlayed(p: Player, o: Player) {
	for (let h of p.histories) {
		if (!h) continue;
		for (let i of o.histories) {
			if (!i) continue;
			if (h.id === o.id) return true;
		}
	}
	return false;
}