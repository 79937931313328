import React, { useState, ReactElement, useRef, RefObject, useEffect, useCallback, useMemo, createRef, FormEvent, CSSProperties, useContext } from 'react';
import url from 'url';
import cx from 'classnames';
import { FORM, server, POST } from 'utils/requests';
import { AnyDictionary, GameLinks, Player, TournamentSettings, ResultObject } from '../../interfaces';

import styles from 'css/components.module.css';
import * as icons from 'assets/icons';
import * as regexes from 'utils/regexes';
import EditableField from 'components/EditableField';
import { defaultSettings } from 'resources/settings';
import { FaUndo, FaChevronDown, FaCheck, FaTimes, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { SettingsContext } from '../../Contexts';

interface SinglePairingProps {
    result: ResultObject
    gameLinks: GameLinks
	tournamentID: string
	inputClassName: any
    hidden?: AnyDictionary
    expanded?: boolean
	expand?: (set: boolean) => void
	step?: number
	validate: (form: HTMLFormElement) => boolean | string | void
	handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

export default function SinglePairing(props: SinglePairingProps): ReactElement {

	const scores = props.result[0] || props.result[1] ? [props.result[0], props.result[1]] : props.result[3]?.scores || [null, null];
	const isConfirmed = props.result[0] !== null || props.result[1] !== null;
	const scored = scores.some(s => s !== null && s!== undefined) && props.result[3]?.endTime;

	const [valid, setValid] = useState(undefined as boolean | undefined);
	const [hover, setHover] = useState(scored ? '1 results submission to confirm' : '');
	const [hasContent, setHasContent] = useState(false);

	const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
		let input = e.target as HTMLInputElement;
		let form = input.parentElement as HTMLFormElement;
		let valid = props.validate(form);
		if (typeof valid === 'string') setHover(valid);
		else setHover('');
		if (typeof valid === 'boolean') setValid(valid);
		else if (typeof valid === 'string') setValid(false);
		else setValid(undefined);
		for (let child of Array.from(form.children)) {
			if (child.constructor.name !== 'HTMLInputElement') continue;
			if (child.getAttribute('type') === 'hidden') continue;
			if ((child as HTMLInputElement).value.toString()) {
				setHasContent(true);
				return;
			}
		}
		setHasContent(false);
	};

	const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLFormElement>): void => {
		let input = e.target as HTMLFormElement;
		let form = input.parentElement as HTMLFormElement;
		switch (e.keyCode) {
		case 27: {
			form.reset();
			setValid(undefined);
			setHover(undefined);
			input.blur();
			break;
		}
		case 13: {
			form.requestSubmit();
			break;
		}
		}
	}, [handleChange]);

	const clear = (e: React.FormEvent<HTMLButtonElement>) => {
		e.preventDefault();
		let input = e.target as HTMLButtonElement;
		let div = input.parentElement as HTMLDivElement;
		let form = div.parentElement as HTMLFormElement;
		form.reset();
		setHover('');
		setValid(undefined);
	};
	
	const hasLink = useMemo(() => Object.values(props.gameLinks).some(v => v), [props.gameLinks]);

	const w = useRef() as RefObject<HTMLInputElement>;
	const b = useRef() as RefObject<HTMLInputElement>;
	if (isConfirmed) return (
		<div className={styles.resultsBridge}>
			<div className={cx(styles.setResult, {[styles.linked]: hasLink })}>
				{scores[0] ?? '\u200b'}
			</div>
			<div className={styles.neutral} title='Show details' onClick={() => props.expand(!props.expanded)} style={{
				transform: props.expanded ? 'rotate(540deg)' : 'rotate(0deg)'
			}}>
				<FaChevronDown />
			</div>
			<div className={cx(styles.setResult, {[styles.linked]: hasLink })}>
				{scores[1] ?? '\u200b'}
			</div>
		</div>
	);
    
	return <form
		className={styles.resultsBridge}
		target='_self'
		onSubmit={(e) => {
			e.preventDefault();
			let valid = props.validate(e.target as HTMLFormElement);
			if (typeof valid === 'string' || !valid) return;
			FORM(e).then(() => props.handleSubmit(e));
		}}
		onKeyDown={handleKeyDown}
		method='POST'
		action={url.resolve(server, ['tournament', props.tournamentID, 'addResult'].join('/'))}
	>
		<input
			type='number'
			min={0}
			step={props.step ?? 0.5}
			name='resultWhite' 
			required
			className={[props.inputClassName, hasLink ? styles.linked : '', hasContent || hasLink ? styles.alwaysVisible : '', hasLink ? styles.hasLink : ''].join(' ')}
			onChange={handleChange}
			defaultValue={scores[0]}
			ref={w}
		/>
		<div className={styles.centre}>
			{hover ? <span className='tooltip'>{hover}</span> : null}
			{valid ?
				<button tabIndex={-1} className={styles.resolve} onClick={(e) => {
					e.preventDefault();
					((e.target as HTMLButtonElement).parentElement.parentElement as HTMLFormElement).requestSubmit();
				}}>
					<FaCheck />
				</button> :
				valid === false ?
					<button tabIndex={-1} className={styles.reject} onClick={clear}>
						<FaTimes />
					</button> :
					<div className={styles.neutral} title='Show details' onClick={() => props.expand(!props.expanded)} style={{
						transform: props.expanded ? 'rotate(540deg)' : 'rotate(0deg)'
					}}>
						<FaChevronDown />
					</div>
			}
		</div>
		<input
			type='number'
			min={0}
			step={props.step ?? 0.5}
			name='resultBlack'
			required
			className={[props.inputClassName, hasLink ? styles.linked : '', hasContent || hasLink ? styles.alwaysVisible : '', hasLink ? styles.hasLink : ''].join(' ')}
			onChange={handleChange}
			defaultValue={scores[1]}
			ref={b}
		/>
		{Object.entries(props.hidden).map(([name, value], i) => {
			return <input key={['hiddenInput', i].join('.')} type='hidden' name={name} value={value} required />;
		})}		
	</form>;
}

interface DetailsProps {
    tournamentID: string
    id: string
	className: string
	style?: CSSProperties
    expanded: boolean
    gameLinks: GameLinks
	individual: boolean
	round: number
    board: number
    result: ResultObject
    update: () => Promise<void>
	triggerConfirm: (message: string, resolve: () => void, reject: () => void) => void
	updatePlayerDict: () => void
	settings: TournamentSettings
}

export function PairingDetails(props: DetailsProps): ReactElement {

	const [height, setHeight] = useState('0px');
	const scores = props.result[0] || props.result[1] ? [props.result[0], props.result[1]] : props.result[3]?.scores || [null, null];
	const isConfirmed = props.result[0] != null || props.result[1] != null;
	const scored = scores.some(s => s !== null && s!== undefined) && props.result[3]?.endTime;
	const [mount, setMount] = useState(false);
	useEffect(() => {
		if (props.expanded) {
			setMount(true);
			return;
		}
		let x = setTimeout(() => setMount(false), 750);
		return () => clearTimeout(x);
	}, [props.expanded, setMount]);

	const details = useRef(null) as RefObject<HTMLDivElement>;
	const form = useRef(null) as RefObject<HTMLFormElement>;

	const settings = useContext(SettingsContext);

	const updateHeight = useCallback(() => {
		if (!details.current) return;
		setHeight(props.expanded ? details.current.getBoundingClientRect().height + 'px' : '0px');
	}, [setHeight, props.expanded, details.current]);

	useEffect(() => {
		window.addEventListener('resize', updateHeight);
		return () => window.removeEventListener('resize', updateHeight);
	}, [updateHeight]);

	useEffect(() => {
		if (!props.expanded) return;
		if (!details.current) return;
		updateHeight();
	}, [details.current, updateHeight, props.expanded]);
	useEffect(() => {
		window.dispatchEvent(new Event('resize'));
	}, [props.expanded, mount]);

	const handleConfirm = useCallback(() => {
		if (!details.current) return;
		let container = details.current.parentElement as HTMLDivElement;
		let resultsBridge = container.previousElementSibling as HTMLDivElement;
		let form = resultsBridge.querySelector('form') as HTMLFormElement;
		form.requestSubmit();
	}, [details.current]);

	const handleSubmit = useCallback((key: 'lichess' | 'chessCom', value: string): Promise<void> => {
		let v = value;
		if (!props.individual) {
			let matches = value.match(regexes.chessComTeam);
			if (!matches) {
				console.error('Invalid syntax on tournament: ' + value);
				return Promise.resolve();
			}
			v = 'https://chess.com/club/matches/live/' + matches[1];
		}
		return POST({
			url: url.resolve(server, ['tournament', props.tournamentID, 'updateResultLink'].join('/')),
			data: {
				id: props.id,
				[key]: v,
				type: props.individual ? 'individual' : 'team',
				round: props.round
			}
		});
	}, [props.id, props.individual, props.tournamentID]);

	const handleClear = useCallback((key: 'lichess' | 'chessCom'): Promise<void> => {
		return POST({
			url: url.resolve(server, ['tournament', props.tournamentID, 'clearResultLink'].join('/')),
			data: {
				id: props.id,
				site: key,
				type: props.individual ? 'individual' : 'team',
				round: props.round
			}
		}).then(props.update);
	}, [props.id, props.individual, props.tournamentID, props.update]);

	const undoResult = useCallback((): Promise<void> => {
		return POST({
			url: url.resolve(server, ['tournament', props.tournamentID, 'undoResult'].join('/')),
			data: {
				id: props.id,
				round: props.round
			}
		})
			.then(props.updatePlayerDict);
	}, [props.id, props.individual, props.tournamentID]);

	const validation = useMemo(() => {
		return [
			{
				k: `Results ${scored ? 'received' : 'not yet received'} from ${defaultSettings.automation.source.convert(settings.source)}`,
				v: scored,
				hide: !settings.source
			},
			{
				k: `Confirmation received by ${'White player'}`,
				v: false,
				hide: settings.allowSelfLinks < 1 || settings.allowSelfLinks > 2
			},
			{
				k: `Confirmation received by ${'Black player'}`,
				v: false,
				hide: settings.allowSelfLinks < 1 || settings.allowSelfLinks > 2
			}
		].filter(v => v);
	}, [settings.source, props.result, settings.allowSelfLinks]);

	useEffect(() => {
		let f = () => window.dispatchEvent(new Event('resize'));
		f();
		let x = setTimeout(f, 200);
		return () => clearTimeout(x);
	}, [mount]);

	if (!mount) return null;
	
	return (
		<div className={[props.className, styles.details].join(' ')} style={{ height, ...props.style }}>
			<div ref={details} className={[styles.detailsContent].join(' ')}>
				<div className={[styles.row, 'topBanner', styles.topBanner].join(' ')}>
					Board: {props.board}{'\n'}
				</div>
				<div className={[styles.buttonRow].join(' ')}>
					{isConfirmed ?
						<button className={[styles.button, styles.infinity, styles.undoPairing].join(' ')} onClick={() => props.triggerConfirm('Really undo result? This action cannot be undone', undoResult, () => {})}>
							<FaUndo />
							Undo result
						</button> :
						scored ?
							<button className={[styles.button, styles.submit].join(' ')} onClick={handleConfirm}>
								<FaCheck />
								Confirm automation
							</button> :
							null
					}
				</div>
				<div>
					<div className={styles.row}>
						Enter link to online {props.individual ? 'game' : 'team match'} below:
					</div>
					{settings.source !== 'chessCom' ? null : <div className={[styles.row, styles.linkRow].join(' ')}>
						<button className={[styles.submit, styles.linkSubmit].join(' ')}>
							<img src={icons.chessCom} alt='chess.com' />
						</button>
						<EditableField
							content={props.gameLinks.source === 'chessCom' ? props.gameLinks.link : ''}
							url={url.resolve(server, ['tournament', props.tournamentID, 'updateResultLink'].join('/'))}
							onSubmitSuccess={props.update}
							handleSubmit={(k: string, v: string) => handleSubmit('chessCom', v)}
							name={'link'}
							additionalInputProps={{}}
							classNames={{
								input: styles.inputURL,
								editableField: styles.editableField
							}}
							formRef={form}
							useHandleSubmit
							handleClear={() => handleClear('chessCom')}
							show={{
								submit: true,
								clear: true
							}}
						/>
					</div>}
					{settings.source !== 'lichess' ? null : <div className={[styles.row, styles.linkRow].join(' ')}>
						<button className={[styles.increment, styles.linkSubmit].join(' ')}>
							<img src={icons.lichess} alt='lichess' />
						</button>
						<EditableField
							content={props.gameLinks.source === 'lichess' ? props.gameLinks.link : ''}
							url={url.resolve(server, ['tournament', props.tournamentID, 'updateResultLink'].join('/'))}
							onSubmitSuccess={props.update}
							handleSubmit={(k: string, v: string) => handleSubmit('lichess', v)}
							name={'link'}
							additionalInputProps={{}}
							classNames={{
								input: styles.inputURL,
								editableField: styles.editableField
							}}
							formRef={form}
							useHandleSubmit
							handleClear={() => handleClear('lichess')}
							show={{
								submit: true,
								clear: true
							}}
						/>
					</div>}
				</div>
				<div className={styles.validationTable}>
					{validation.map(({k, v, hide}) => {
						if (hide) return null;
						return <>
							<div>{k}</div>
							<div>{v ? <FaCheckCircle /> : <FaTimesCircle />}</div>
						</>;
					})}
				</div>
			</div>
		</div>
	);
}