import React, { useState, useEffect } from 'react';
import { FaCheckCircle, FaBan, FaCircle } from 'react-icons/fa';
import { DockOption } from '../interfaces';
import styles from './css/inputs.module.css';

export function TernaryInput(props: {
	setValue: (v: boolean | undefined) => void
	isTernary?: boolean
} & DockOption) {

	const { defaultValue, isTernary } = props;

	const [value, setValue] = useState(isTernary ? defaultValue : !!defaultValue);
	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	if (value === true) return <FaCheckCircle className={styles.check} onClick={() => {
		if (props.locked) return;
		let newValue = false;
		setValue(newValue);
		props.setValue(newValue);
	}} />;

	if (value === false && isTernary) return <FaBan className={styles.ban} onClick={() => {
		if (props.locked) return;
		let newValue = isTernary ? undefined : true;
		setValue(newValue);
		props.setValue(newValue);
	}} />;

	return <FaCircle className={styles.blank}  onClick={() => {
		if (props.locked) return;
		let newValue = true;
		setValue(newValue);
		props.setValue(newValue);
	}} />;

}