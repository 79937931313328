import React, { useState, useMemo, useCallback, Dispatch, useEffect, useReducer, useContext, ReactText, ReactElement } from 'react';
import cx from 'classnames';

import Core from './Core';
import Player from 'models/player';

import styles from './css/sort.module.css';
import { dict as countries } from 'assets/countries.json';
import { capitalise, px, convertPoolDisplay } from 'utils/prototype';
import { FaSearch } from 'react-icons/fa';
import Search from 'components/Search';
import missing from 'assets/missing_flags';
import Draggable from 'components/DraggableRows';
import { POST, GET } from 'utils/requests';
import { SettingsContext, AlertContext } from '../Contexts';
import TabSection from 'components/TabSection';
import usePlayers from './hooks/usePlayers';
import useFlags from 'hooks/useFlag';
const missingCodes = missing.split('\n').filter(v => v);

type Mode = 'nationality' | 'teams' | 'pools' | 'active';

interface SortProps {
	className?: string
	players: Player[]
	updatePlayerDict: () => void
	setPlayers?: (p: Partial<Player>[]) => void
	mode?: Mode
	poolName?: string
	showSave?: boolean
	startTo?: string
	description?: string | ReactElement
}

interface Meta {
	options: { id: string }[]
	name: (p: { id: string }) => string
	filter: (id: string, p: Player) => boolean
	modify: (p: Player, v: any) => Player
}

export default function Sort(props: SortProps) {

	const settings = useContext(SettingsContext);
	const { setAlert } = useContext(AlertContext);
	const { getFlag } = useFlags();

	const { players, changedPlayers, setPlayers, handleReset, hasChanged } = usePlayers({
		handleChange: props.setPlayers,
		players: props.players
	});

	const showSave = useMemo(() => {
		if (props.showSave === false) return false;
		if (hasChanged) return true;
		return false;
	}, [props.showSave, hasChanged]);

	const sortOptions = useMemo(() => {
		return [
			settings.usingPools ? 'pools' : null,
			'nationality',
			settings.competitors === 'individual' ? 'teams' : null,
			'active'
		];
	}, [settings]);

	const [mode, setMode] = useState(props.mode || sortOptions.find(v => v) as Mode);

	const [teams, setTeams] = useState([] as { id: string, name: string }[]);
	const updateTeams = useCallback(() => GET({ url: px('tournament', settings.tournamentId, 'getTeams')} ).then(setTeams), [settings, setTeams]);
	useEffect(() => {
		if (mode !== 'teams') return;
		updateTeams();
	}, [mode, updateTeams]);

	const modeMeta: {[key: string]: Meta} = useMemo(() => ({
		nationality: {
			options: Object.keys(countries).filter(k => !missingCodes.includes(k)).map(id => ({ id })),
			name: (p: { id: string }) => countries[p.id as keyof typeof countries],
			filter: (id: string | null, p: Player) => id ? p.nationality === id : !p.nationality,
			modify: (p: Player, v: any) => {
				return Object.assign({}, p, { nationality: v });
			}
		},
		teams: {
			options: teams,
			name: ({ id }: { id: string }) => (teams.find(p => p.id === id))?.name,
			filter: (id: string | null, p: Player) => id ? p.teamId === id : !p.teamId,
			modify: (p: Player, v: any) => {
				return Object.assign({}, p, { teamId: v });
			}
		},
		pools: {
			options: players.reduce((acc: ({ id: string }[]), curr: Player) => {
				if (curr.pool + 1 < acc.length) return acc;
				for (let i = acc.length; i <= curr.pool + 1; i++) {
					acc[i] = { id: i.toString() };
				}
				return acc;
			}, []),
			name: (p: { id: string }) => convertPoolDisplay(parseInt(p.id), settings.poolDisplay),
			filter: (id: string | null, p: Player) => id ? p.pool === parseInt(id) : !p.pool,
			modify: (p: Player, v: any) => {
				return Object.assign({}, p, { pool: parseInt(v) });
			}
		},
		active: {
			options: [{ id: 'active' }, { id: 'inactive' }],
			name: (p: { id: string }) => capitalise(p.id),
			filter: (id: string, p: Player) => (id === 'active' && p.active) || (id === 'inactive' && !p.active),
			modify: (p: Player, v: 'active' | 'inactive') => {
				return Object.assign({}, p, { active: v === 'active' });
			}
		}
	}), [players, teams, settings]);

	const meta = useMemo(() => modeMeta[mode], [modeMeta, mode]);
	const [searchMode, toggleSearch] = useState(false);
	const [filtered, setFiltered] = useState([]);

	const [to, setTo] = useReducer((state: string, action: string) => state === action ? '' : action, props.startTo || '');
	const [rightFilter, setRightFilter] = useState('all' as 'all' | 'uncategorised');

	/* Col 1 */
	const countryGrid = useMemo(() => {

		return (
			<div className={cx(styles.templates, 'scrollable')}>
				{meta.options.map(({ id }, i) => {
					let k = id;
					let v = meta.name({ id });
					if (mode === 'pools' && id == '0') return null;
					if (!v) return null;
					return (
						<div key={cx('template', i)} onClick={() => setTo(k)} className={cx('button', {
							hidden: filtered.length && !filtered.includes(k),
							[styles.large]: mode !== 'nationality',
							[styles.unrepresented]: players.every(p => !meta.filter(k, p)),
							[styles.selected]: to === k
						})}>
							{mode === 'nationality' ?
								<>
									{getFlag(k)}
									{v}
								</> :
								v
							}
						</div>
					);
				})}
			</div>
		);
	}, [mode, meta, players, filtered, to]);

	/* Col 2 and 3 */

	const [toType, setToType] = useState('');
	const handleDragEnd = useCallback((fromId: string) => {
		let player = players.find(p => p.id === fromId);
		let v = '';
		if (toType === 'from') {
			if (rightFilter === 'all') return;
			v = '';
		}
		else v = to;
		let p = meta.modify(player, v);
		setPlayers(p);
	}, [toType, setPlayers, meta]);
	const handleSubmit = useCallback(() => {
		POST({
			url: px('tournament', settings.tournamentId, 'quickSort'),
			data: {
				mode,
				players: changedPlayers
			}
		})
			.then(props.updatePlayerDict)
			.then(() => setAlert({
				type: 'info',
				message: <>
					Updated {mode} for the following {settings.competitors === 'individual' ? 'players' : 'teams'}:
					<ul>
						{Object.values(changedPlayers).map(p => <li key={p.id}>{p.name}</li>)}
					</ul>
				</>
			}));
	}, [settings, changedPlayers, props.updatePlayerDict]);

	return (
		<Core id='sort' className={props.className} scrollable={false}		
			buttons={[
				{
					dropdown: true,
					resolve: setMode as (v: string) => void,
					current: mode,
					options: sortOptions
				}
			]}
		>
			<div className={styles.description}>
				{props.description}
			</div>
			<div className={styles.stage}>
				<div className={cx(styles.col1)}>
					<div className={cx(styles.row, {[styles.expanded]: searchMode})}>
						<div className={styles.header} onClick={() => searchMode ? toggleSearch(false) : null}>
							{mode === 'pools' ?
								capitalise(props.poolName ? props.poolName + 's' : mode) :
								capitalise(mode)
							}
						</div>
						{!searchMode ?
							<div className={cx('button', styles.button)} onClick={() => toggleSearch(true)}>
								<FaSearch />
							</div> :
							<div className={['button', styles.searchButton].join(' ')}>
								<Search
									onSubmit={() => toggleSearch(false)}
									onCancel={() => toggleSearch(false)}
									onChange={setFiltered}
									options={meta.options}
									getName={meta.name}
									styles={styles}
									placeholder={'Search All ' + capitalise(mode)}
									showHistory={false}
									showChoices={false}
									cacheHistory={false}
									preserveLastSearch
								/>
							</div>
						}
					</div>
					{countryGrid}
					{filtered.length ?
						<div onClick={() => setFiltered([])} className={cx('button', styles.clear)}>
							Clear Search Parameters
						</div> :
						null
					}
				</div>
				<div className={styles.col2}>
					<PlayersComponent type='to' category={to} {...{players, rightFilter, setRightFilter, poolName: props.poolName, meta, mode, setToType, handleDragEnd, handleSubmit, handleReset, showSave }} />
				</div>
				<div className={styles.col3}>
					<PlayersComponent type='from' category='' {...{players, rightFilter, setRightFilter, poolName: props.poolName, meta, mode, setToType, handleDragEnd, handleSubmit}} />
				</div>
			</div>
		</Core>
	);
}

function PlayersComponent({ type, category, players, rightFilter, setRightFilter, poolName, meta, mode, setToType, handleDragEnd, handleSubmit, handleReset, showSave }: {
	type: 'to' | 'from'
	category: string
	players: Player[]
	mode?: string
	poolName?: string
	rightFilter: 'all' | 'uncategorised'
	setRightFilter: (s: 'all' | 'uncategorised') => void
	meta: Meta,
	setToType: (v: string) => void
	handleDragEnd: (id: string) => void
	handleSubmit: () => void
	handleReset?: () => void
	showSave?: boolean
}) {

	const [activeState, setActiveState] = useState('active');
	let [activePlayers, inactivePlayers] = [players.filter(p => meta.filter('active', p)), players.filter(p => meta.filter('inactive', p))];

	let title = meta.name({ id: category });
	let filteredPlayers = !title ? players : players.filter(p => meta.filter(category, p));
	let uncategorised = players.filter(p => meta.filter(null, p));
	if (type === 'from' && rightFilter === 'uncategorised') filteredPlayers = uncategorised;
	if (mode === 'active' && type === 'from') filteredPlayers = players.filter(p => meta.filter(activeState, p));
	
	let dragOver = () => setToType(type);
	let dragEnd = (id: string) => handleDragEnd(id);
	return <>
		{mode === 'active' && type === 'from' ?
			<TabSection
				name={activeState}
				setName={setActiveState}
				nameDict={{
					active: {
						name: `Active (${activePlayers.length})`
					},
					inactive: {
						name: `Inactive (${inactivePlayers.length})`
					}
				}}
			/> :
			<TabSection
				name={rightFilter}
				setName={setRightFilter as (v: string) => void}
				nameDict={{
					all: {
						name: `${title ?
							mode === 'pools' ?
								(poolName || 'Pool') + ' ' + title : title : 'All'
						} (${type === 'from' ?
							players.length :
							filteredPlayers.length
						})`
					},
					uncategorised: {
						hide: type !== 'from',
						name: `Uncategorised (${uncategorised.length})`
					}
				}}
				alwaysShow
			/>
		}
		<Draggable className={cx(styles.list, 'scrollable')} 
			onSubmit={handleSubmit}
			onReset={handleReset}
			onDragOver={dragOver}
			onDragEnd={dragEnd}
			alwaysShowButtons={type === 'to' && showSave !== false}
		>
			{filteredPlayers.sort((a, b) => b.score - a.score).map((p, i) => {
				return (
					<div
						key={cx(p.id, i)}
						id={p.id}
						draggable
						className={cx('fieldBox', styles.player)}
						data-drag-id={type}
					>
						{p.name}
					</div>
				);
			})}
		</Draggable>
	</>;
}