import { capitalise } from 'utils/prototype';
import { TournamentStatus, Player } from '../interfaces';
import { resultsServer } from 'utils/requests';
import { TieBreaks } from './tiebreaks';

export function booleanConvert<T = string>(v: boolean, arr: T[] = []): 'On' | 'Off' | '' | T {
	if (v) return arr[0] ?? 'On';
	if (v === false) return arr[1] ?? 'Off';
	return '';
}

export interface TournamentSettings {

	tournamentId: string; // Note that the `null assertion` `!` is required in strict mode.
	pairingSystem: 'swiss' | 'round-robin'| 'knockout';
	competitors: string
	totalRounds: number | 'Infinity';
	performanceSystem: 'ELO' | 'FIDE';
	ratingSystem: 'ECF' | 'FIDE' | 'USCF';
	usingPools: boolean;

	displayPoints: 'match' | 'game';
	gamePointTotal: number;
	lossReward: number;
	drawReward: number;
	winReward: number;
	byeReward: 'win' | 'draw' | 'loss';
	byeGameReward: number;

	media?: 'online' | 'otb'
	isPublic: boolean
	timeControl: string
	location: string
	vanityURL: string
	logoURL: string
	profileURL: string
	rulesURL: string
	streamURL: string
	showRatings: boolean

	allowRegistration: 'closed' | 'approve' | 'open';
	isPublished: boolean
	publishPairings: boolean
	allowNewPlayers: boolean;
	checkForDuplicatePlayers: boolean
    allowSelfLinks: number;
    allowSelfSubmissions: boolean;
    allowClashes: boolean;
	roundTimes: boolean;
	allowBonuses: boolean;
	poolDisplay: number

	description: string;
	tieBreaks: string;
	prizes: string;

	source?: 'lichess' | 'chessCom';
	pullResults: number;

	createdAt: string
	updatedAt: string

}

export interface TournamentSetting<T> {
	name?: string
	enum?: string[] | number[]
	convert?: (v: T) => string
	validate?: (v: T) => boolean
	value?: (status: any, players: Player[], setting: TournamentSettings) => any
	type?: T extends string ?
		'string' | 'link' :
		T extends number ?
		'number' :
		T extends boolean ?
		'boolean' :
		''
	locked?: boolean,
	lockWhen?: {
		[key in keyof TournamentSettings]?: any
	},
	onInit?: boolean,
	lockOnInit?: boolean
	show?: {
        [key in keyof TournamentSettings]?: any | ((v: any) => boolean)
	}
	showOnIntro?: {
        [key in keyof TournamentSettings]?: any | ((v: any) => boolean)
	}
	hidden?: boolean
	allow?: {
		infinity?: boolean
		any?: boolean
		submit?: boolean
        increment?: boolean
        clear?: boolean
	}
	nb?: {
		min?: number
		max?: number
		step?: number
	}
	highlight?: boolean
	action?: string
	primaryKey?: boolean
	defaultValue?: T | null
	level?: number
	linked?: keyof TournamentSettings
	description?: string
	regex?: RegExp
}

export const defaultSettings = {
	core: {
		tournamentId: {
			name: 'Unique ID',
			type: 'string',
			locked: true,
			primaryKey: true,
			//description: 'Your tournament\'s ID generated by Scorch. Keep hold of it since if you don\'t set up a custom url it\'s what ScorchResults will use for your tournament page.'
		},
		status: {
			name: 'Status',
			enum: ['uninitialised', 'active', 'finished'],
			convert: capitalise,
			defaultValue: 'uninitialised',
			value: (status: TournamentStatus, p, settings: TournamentSettings): string => {
				if (!status.round) return 'uninitialised';
				if (status.active) return 'active';
				if (status.round !== settings.totalRounds) return 'inactive';
				return 'finished';
			},
			locked: true
		},
		pairingSystem: {
			name: 'Pairing system',
			enum: ['swiss', 'round-robin', 'knockout'],
			convert: capitalise,
			lockOnInit: true,
			defaultValue: 'swiss',
			description: 'Do you want a <span id="swiss" className="switcher">Swiss</span> tournament (players play opponents on the same number of points as themselves with a set number of rounds) or a <span id="round-robin" className="switcher">Round-Robin</span> tournament (everyone players everyone)? Future support for Stable Marriage and Knockout planned. [Message the developers](https://scorchapp.co.uk/about#contact) if you have any requests.'
		},
		competitors: {
			name: 'Tournament type',
			enum: ['individual', 'team'],
			convert: capitalise,
			lockOnInit: true,
			defaultValue: 'individual',
			description: 'If your pairings are organised around teams, change this option to \'teams\'.'
		},
		media: {
			name: 'Online?',
			enum: ['online', 'otb'],
			convert: (v: string) => v === 'otb' ? 'Over-the-board' : capitalise(v),
			defaultValue: 'online',
			description: 'Is your tournament online? <span id="online" className="switcher">Online</span> tournaments have a large number of additional settings to automate actions, such as automatic results, downloading games, and dates and times. <span id="otb" className="switcher">Over-the-board (OTB)</span> are played in classical in-person tournament settings.'
		},
		totalRounds: {
			name: 'Total rounds',
			convert: (v: number | 'Infinity') => v === 'Infinity' ? '∞' : v.toString(),
			type: 'number',
			defaultValue: Infinity,
			lockWhen: {
				pairingSystem: 'round-robin'
			},
			nb: {
				min: 1,
				max: 100
			},
			allow: {
				infinity: true
			}
		},
		ratingSystem: {
			name: 'Rating system',
			enum: ['ECF', 'FIDE', 'USCF'],
			lockOnInit: true,
			defaultValue: 'ECF',
			description: 'What scale of ratings do you want to use? If the numbers are roughly 0-260, use <span id="ECF" className="switcher">ECF</span>. If the numbers are 0-2600 use <span id="FIDE" className="switcher">FIDE</span>.'
		},
		displayPoints: {
			name: 'Games per pairing',
			convert: (v: 'match' | 'game') => v === 'match' ? 'Just One' : 'Multiple',
			enum: ['match', 'game'],
			defaultValue: 'match',
			description: 'If your players are playing multiple games in every pairing, you need to change this to <span id=\'game\' className=\'switcher\'>Multiple</span>. Otherwise, if all your pairings are single games, i.e. the result is always 1-0, ½-½, or 0-1, then change it to <span id=\'match\' className=\'switcher\'>Just One</span>.'
		},
		gamePointTotal: {
			name: 'Number of games per pairing',
			type: 'number',
			defaultValue: 1,
			show: {
				displayPoints: 'game'
			},
			nb: {
				step: 1,
				min: 1
			},
			allow: {
				any: true
			},
			highlight: true,
			linked: 'byeGameReward',
			convert: (v: number) => {
				if (!v) return 'Any';
				return v.toString();
			},
			description: 'How many games are there for every pairing? If it\'s a fixed number, such as all games are 4-0, 2-2, or 3½-½, then write in that fixed number. Otherwise, click <span id=\'0\' className=\'switcher\'>Any</span>.', 
		},
		usingPools: {
			name: 'Pair players in pools?',
			description: 'This setting is for if you\'re using a pool structure for your pairings. That means that a subset of players can only be paired with a subset of others, for instance, \'Pool A\' players can only play those in \'Pool A\', \'Pool B in Pool B\' and so on. You can switch off this setting at any time. <div id=\'poolButton\' />',
			type: 'boolean',
			defaultValue: false,
			convert: booleanConvert
		},
		timeControl: {
			name: 'Time control',
			type: 'string',
			defaultValue: '',
			regex: /([0-9.]+)\+([0-9.])/
		},
		isPublic: {
			name: 'Public',
			convert: booleanConvert,
			lockOnInit: false,
			type: 'boolean',
			defaultValue: false,
			level: 1
		},
		tieBreaks: {
			name: 'Tie Breaks',
			convert: () => '',
			type: 'string',
			defaultValue: null,
			validate: (v: string) => {
				let list = v.split(',');
				for (let k of list) {
					if (!(k in TieBreaks)) throw `Invalid tiebreak '${k}'`;
				}
				return true;
			},
			hidden: true
		}
	},
	permissions: {
		allowNewPlayers: {
			name: 'Mid-way joining?',
			convert: booleanConvert,
			lockOnInit: false,
			type: 'boolean',
			defaultValue: true,
			hidden: true
		},   
		isPublished: {
			name: 'Round is published?',
			convert: booleanConvert,
			type: 'boolean',
			defaultValue: true,
			hidden: true
		},
		checkForDuplicatePlayers: {
			name: 'Check for duplicate players?',
			convert: booleanConvert,
			lockOnInit: false,
			type: 'boolean',
			defaultValue: true,
			show: {
				allowNewPlayers: true,
				pairingSystem: 'swiss'
			},
			highlight: true,
			description: 'If this setting is on, ScorchChess will automatically check that any new player you enter in the New Player dialogue doesn\'t have the same name as an existing player'
		},
		allowClashes: {
			name: 'Forbid certain pairings?',
			convert: booleanConvert,
			lockOnInit: false,
			type: 'boolean',
			defaultValue: true,
			show: {
				pairingSystem: 'swiss'
			},
			description: 'Switch this setting on to enable the \'Forbidden Pairings\' feature. Forbid a player from playing another by clicking on any player and adding a new forbidden opponent in the Player dialogue. Also known as: clashes, banned pairings.'
		},
		allowBonuses: {
			name: 'Input score bonuses for players?',
			convert: booleanConvert,
			lockOnInit: false,
			type: 'boolean',
			defaultValue: true,
			description: 'Switch this setting on to enable the \'Score bonuses\' feature. This allows you to arbitarily add bonus points to players. Also known as: half-point byes, handicaps, bonuses.'
		},
		poolDisplay: {
			name: 'Pool numbering',
			enum: [0, 1, 2],
			type: 'number',
			defaultValue: 0,
			convert: (v: number) => {
				if (v === 0) return 'Letters';
				if (v === 1) return 'Numbers';
				if (v === 2) return 'Roman Numerals';
			},
			show: {
				usingPools: true
			}
		}
	},
	points: {
		lossReward: {
			name: 'Points on loss',
			lockOnInit: true,
			type: 'number',
			defaultValue: 0,
			nb: {
				step: 0.1
			}
		},
		drawReward: {
			name: 'Points on draw',
			lockOnInit: true,
			type: 'number',
			defaultValue: 0.5,
			nb: {
				step: 0.1
			}
		},
		winReward: {
			name: 'Points on win',
			lockOnInit: true,
			type: 'number',
			defaultValue: 1,
			nb: {
				step: 0.1
			}
		},
		byeReward: {
			name: 'Bye reward',
			enum: ['win', 'draw', 'loss'],
			lockOnInit: true,
			defaultValue: 'win',
			convert: capitalise,
			description: 'How many match points should be granted to players receiving a bye? It can either be the same number of points as is granted when a player <span id="win" className="switcher">Wins</span>, <span id="draw" className="switcher">Draws</span>, or <span id="loss" className="switcher">Loses</span>.'
		},
		byeGameReward: {
			name: 'Bye reward in game points',
			type: 'number',
			lockOnInit: false,
			defaultValue: 1,
			show: {
				displayPoints: 'game'
			},
			nb: {
				min: 0
			},
			highlight: true,
			description: 'How many game points should be granted to players receiving a bye? This can be any arbitary number.'
		},
		performanceSystem: {
			name: 'Performance rating',
			enum: ['ELO', 'FIDE'],
			lockOnInit: false,
			defaultValue: 'ELO',
			description: `This setting refers to the performance rating **algorithm** used. There are two performance rating options:  <span id="ELO" className="switcher">ELO</span> and  <span id="FIDE" className="switcher">FIDE</span>.
			- The ELO performance rating system refers to the average of an opponent's player's player ratings, buffed by 400 for each win and subtracted by 400 for every loss.
			- The FIDE formula calculates a rating difference factor dp using a lookup table from the player's percentage score. That difference factor is then summed to the average rating of all opponents played. For instance, if the average opponent rating is 1400 and the player has won 2 out of 3 games, their dp factor will be +125 and so their performance rating will be 1525.
			This feature is only important if you are interested in calculating performance ratings and expected scores.`
		}
	},
	stats: {
		nbPlayers: {
			name: 'Number of players',
			type: 'number',
			defaultValue: 0,
			value: (status: TournamentStatus, players: Player[]): number => players.length,
			convert: (v: number) => v.toString()
		},
		activePlayers: {
			name: 'Active players',
			type: 'number',
			defaultValue: 0,
			value: (status: TournamentStatus, players: Player[]): number => players.filter(p => p.active).length,
			convert: (v: number) => v.toString()
		},
		matchesPlayed: {
			name: 'Matches played',
			type: 'number',
			defaultValue: 0,
			value: (status: TournamentStatus, players: Player[]): number => players.reduce((acc, curr) => acc += curr.played, 0) / 2,
			convert: (v: number) => v.toString()
		},
		gamesPlayed: {
			name: 'Games played',
			type: 'number',
			defaultValue: 0,
			value: (status: TournamentStatus, players: Player[]): number => Math.ceil(players.reduce((acc, curr) => acc += curr.histories.reduce((acc, curr) => curr ? acc += curr.played : acc, 0), 0) / 2),
			convert: (v: number) => v.toString()
		},
		averageRating: {
			name: 'Average rating',
			type: 'number',
			defaultValue: 1200,
			value: (status: TournamentStatus, players: Player[]): number => {
				if (!players.length) return 0;
				let sum = players.reduce((acc, curr) => acc += curr.rating, 0);
				return Math.round(sum / players.length);
			},
			convert: (v: number) => v ? v.toString() : '-'
		},
		createdAt: {
			name: 'Created at',
			convert: (v: string) => new Date(v).toString().slice(0, 24),
			locked: true
		},
		updatedAt: {
			name: 'Last updated',
			convert: (v: string) => new Date(v).toString().slice(0, 24),
			locked: true
		}
	},
	public: {
		vanityURL: {
			name: 'Custom ID',
			type: 'string',
			defaultValue: '',
			action: 'updateVanityURL',
			level: 2
		},
		url: {
			name: 'URL',
			type: 'link',
			defaultValue: '',
			value: (status: TournamentStatus, p: Player[], s: TournamentSettings): string => `${resultsServer}t/${s.vanityURL || status.id}`.trim(),
			level: 2,
			showOnIntro: {
				vanityURL: (v: string) => v
			}
		},
		location: {
			name: 'Location',
			type: 'string',
			defaultValue: '',
			level: 1
		},
		profileURL: {
			name: 'Link to website',
			type: 'string',
			level: 1
		},
		rulesURL: {
			name: 'Link to rules',
			type: 'string',
			level: 1
		},
		logoURL: {
			name: 'Link to profile picture',
			type: 'string',
			level: 1
		},
		streamURL: {
			name: 'Stream link',
			type: 'string',
			level: 1
		}
	},
	public_permissions: {
		showRatings: {
			name: 'Show ratings',
			convert: booleanConvert,
			type: 'boolean',
			defaultValue: true,
			level: 1,

		},
		roundTimes: {
			name: 'Show round times',
			convert: booleanConvert,
			type: 'boolean',
			defaultValue: true,
			locked: false,
			level: 1
		},
		publishPairings: {
			name: 'Ask me before publishing pairings',
			convert: booleanConvert,
			type: 'boolean',
			defaultValue: false,
			level: 1,
			description: 'Enable a convenience feature that allows you to say when your pairings after a draw are viewable by the public on ScorchResults. This extra step is useful for changing any pairings to suit your preferences before publishing.'
		}
	},
	public_hidden: {
		description: {
			name: 'Description',
			type: 'string',
			level: 1
		},
		prizes: {
			name: 'Prizes',
			type: 'string',
			level: 1
		}
	},
	automation: {
		source: {
			name: 'Source',
			enum: ['', 'lichess', 'chessCom'],
			convert: (v: string): string => {
				if (v === 'lichess') return 'Lichess.org';
				if (v === 'chessCom') return 'Chess.com';
				return 'None';
			},
			show: {
				media: 'online'
			},
			defaultValue: '',
			level: 1,
			description: 'This setting enables ScorchChess\' state-of-the-art integration features. Select a website where games will be played to begin. ScorchChess currently has support for  <span id="lichess" className="switcher">Lichess.org</span> and  <span id="chessCom" className="switcher">Chess.com</span>. This setting controls your ability to submit game links.' 
		},
		pullResults: {
			name: 'Pull results',
			show: {
				source: ['lichess', 'chessCom']
			},
			enum: [0, 1, 2],
			type: 'number',
			highlight: true,
			level: 1,
			convert: (v: number) => {
				if (v === 0) return 'Off';
				if (v === 1) return 'Ask me first';
				return 'Pull and save all';
			},
			defaultValue: 1,
			description: `This setting enables ScorchChess' state-of-the-art integration features. This setting enables ScorchChess to sync the results with the results found on Lichess.org or Chess.com. There are 3 settings:
				- <span id="0" className="switcher">Off</span>: Don't sync. Let me submit the results myself manually.
				- <span id="1" className="switcher">Ask me first</span>: Sync, but don't confirm any results. Make all synced results provisional and I'll confirm them.
				- <span id="2" className="switcher">Pull and save all</span>: Sync and save all the results. If there any results that need correcting, you can edit results by clicking on the _Dropdown_ for a pairing and _Undo result_.`
		},
		allowSelfLinks: {
			name: 'Let users submit links?',
			lockOnInit: false,
			enum: [0, 1, 2, 3],
			type: 'number',
			defaultValue: 1,
			highlight: true,
			level: 1,
			show: {
				source: ['lichess', 'chessCom']
			},
			convert: (v: number) => {
				if (v === 0) return 'Off';
				if (v === 1) return 'Ask me first';
				if (v === 2) return 'Save if both players confirm';
				return 'Save if either player confirms';
			},
			description: `This setting lets users submit game links to either Lichess.org or Chess.com. If running a team tournament, team admins are allowed to do this. There are 4 options with this setting:
				- <span id="0" className="switcher">Off</span>: Don't allow users to submit game links. You can add a game link to any pairing yourself by clicking on the _Pairing Dropdown_.
				- <span id="1" className="switcher">Ask me first</span>: Provisionally add links. I will then confirm them using the _Pairing Dropdown_.
				- <span id="2" className="switcher">Save if both players confirm</span>: If any player adds a link, make it provisional. Show a confirmation dialogue to the the player and save it if they accept.
				- <span id="3" className="switcher">Save if either player confirms</span>: If any player adds a link, save it to the pairing.`
		},
		allowSelfSubmissions: {
			name: 'Let users submit results?',
			convert: booleanConvert,
			locked: true,
			lockOnInit: false,
			type: 'boolean',
			defaultValue: false
		},
		allowRegistration: {
			name: 'Let users register online?',
			convert: capitalise,
			enum: ['closed', 'approve', 'open'],
			onInit: false,
			locked: true,
			defaultValue: 'closed'
		}
	}
} as {
	[key: string]: {
		[key: string]: TournamentSetting<string | number | boolean>
	}
};