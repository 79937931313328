import React, { useState, useEffect, useMemo, RefObject, useReducer, useCallback, ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';

import { RouterLink, parseHtml } from 'utils/plugins';
import * as regexes from 'utils/regexes';

interface DescriptionProps {
	content: string | ReactElement | ReactElement[]
	onMount?: () => void
	handleToggle?: (id: string) => void
	className?: string
}

export default function useDescription({
	content,
	onMount,
	handleToggle,
	className
}: DescriptionProps) {

	const maxHeight = useMemo(() => {
		if (typeof content !== 'string') return '25em';
		let n = content?.split(regexes.spaces).length;
		return (n ? n / 6 + 2 : 25) + 'em';
	}, [content]);

	const desc = React.createRef() as RefObject<HTMLDivElement>;
	const reduceHeight = useCallback((state: string) => {
		if (!content) return '0em';
		return state === '0em' ? maxHeight : '0em';
	}, [content, maxHeight]);
	const [descHeight, toggleHeight] = useReducer(reduceHeight, '0em');

	const [mount, setMount] = useState(false);
	useEffect(() => {
		if (!descHeight.startsWith('0')) {
			setMount(true);
			if (onMount) onMount();
			return;
		}
		let x = setTimeout(() => setMount(false), 750);
		return () => clearTimeout(x);
	}, [descHeight, onMount, setMount]);

	useEffect(() => {
		if (!desc.current) return;
		if (!handleToggle) return;
		let spans = Array.from(desc.current.querySelectorAll('span.switcher')) as HTMLSpanElement[];
		for (let s of spans) {
			s.onclick = () => handleToggle(s.id);
		}			
	}, [handleToggle, desc]);

	useEffect(() => {
		setTimeout(() => window.dispatchEvent(new Event('resize')), 250);
	}, [descHeight]);

	const description = useMemo(() => {
		if (!mount) return null;
		return (
			<div ref={desc} className={className} style={{
				maxHeight: descHeight
			}}>
				{typeof content === 'string' ?
					<ReactMarkdown
						source={content?.split('\n').map(l => l.trim()).join('\n')}
						escapeHtml={false}
						astPlugins={[parseHtml]}
						renderers={{
							link: RouterLink
						}}
					/> :
					content
				}
			</div>
		);
	}, [mount, content, className, descHeight]);

	return { toggleHeight, description };
}