import React, { ReactElement, useCallback, useState, useMemo, useEffect } from 'react';
import cx from 'classnames';
import styles from './css/draggable.module.css';
import { FaSave } from 'react-icons/fa';
import { FiRefreshCcw } from 'react-icons/fi';

interface DraggableProps {
	className?: string
	children: ReactElement[]
	filter?: (r: ReactElement, i: number) => boolean
	numbers?: boolean
	alwaysShowButtons?: boolean
	draggedElement?: HTMLDivElement
	onReset?: () => void
	onSubmit: (order: string[]) => void
	onDrag?: (elem: HTMLDivElement) => void
	onDragOver?: (id: string) => void
	onDragEnd?: (id: string) => void
}

export default function Draggable(props: DraggableProps) {

	/**
	 * Anything -2 or below means a reset/default state of from/to
	 * -1 means it was sent to a different draggable
	 * Anything 0 or above means the index in the list
	 */
	const [from, setFrom] = useState(-2);
	const [to, setTo] = useState(-2);
	const [dragHeight, setDragHeight] = useState(43);

	const [children, setChildren] = useState(props.children);
	const propsOrder: string[] = useMemo(() => props.children.map(elem => elem.props.id), [props.children]);
	const nullIndex: number = useMemo(() => propsOrder.indexOf('null'), [propsOrder]);
	const initialOrder: string[] = useMemo(() => children.map(elem => elem.props.id), [children]);
	const hasChanged: boolean = useMemo(() => {
		for (let i = 0; i < propsOrder.length; i++) {
			if (propsOrder[i] !== initialOrder[i]) return true;
		}
		return false;
	}, [propsOrder, initialOrder]);
	const [dragging, setDragging] = useState(true);

	useEffect(() => {
		setChildren(props.children);
		setTo(-2);
	}, [props.children, setTo]);

	const handleDrag = useCallback((e: React.DragEvent<HTMLDivElement>) => {
		let div = e.target as HTMLDivElement;
		if (!div) return;
		setFrom(initialOrder.indexOf(div.id));
		setDragHeight(div.getBoundingClientRect().height);
	}, [initialOrder, setFrom, props.onDrag, setDragHeight]);

	const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
		let div = e.target as HTMLDivElement;
		if (props.onDragOver) props.onDragOver(div.id);
		if (div.id === 'last') setTo(initialOrder.length);
		else if (from < 0) setTo(initialOrder.indexOf(div.id));
		else if (initialOrder.indexOf(div.id) === from + 1) setTo(-2);
		else if (initialOrder.indexOf(div.id) === from) setTo(-2);
		else setTo(initialOrder.indexOf(div.id));
	}, [initialOrder, setTo, from, props.onDragOver]);

	const handleDragEnd = useCallback((e: React.DragEvent<HTMLDivElement>) => {
		if (from <= -2) return;
		let fromValue = children[from];
		if (props.onDragEnd) props.onDragEnd(fromValue.props.id);
		
		if (to <= -2) return;
		let toValue = children[to];
		let newChildren = children.slice(0);
		if (fromValue) newChildren.splice(from, 1);
		if (toValue) newChildren.splice(to === children.length ? newChildren.length : newChildren.indexOf(toValue), 0, fromValue);
		setChildren(newChildren);
		setFrom(-2);
		setTo(-2);
	}, [setTo, setFrom, from, to, children, setChildren, props.onDragEnd]);

	const handleReset = useCallback(() => {
		if (props.onReset) props.onReset();
		setChildren(props.children);
	}, [setChildren, props.children, props.onReset]);

	const handleSubmit = useCallback(() => {
		props.onSubmit(initialOrder);
	}, [props.onSubmit, initialOrder]);

	useEffect(() => {
		let f = () => setDragging(true);
		let g = () => setDragging(false);
		document.addEventListener('mousedown', f);
		document.addEventListener('mouseup', g);
		document.addEventListener('dragend', g);
		return () => {
			document.removeEventListener('mousedown', f);
			document.removeEventListener('mouseup', g);
			document.removeEventListener('dragend', g);
		};
	}, [setDragging]);

	return <>
		<div className={cx(styles.wrapper, 'scrollable')}>
			<div className={cx(props.className)}>
				{children.map((elem, i) => {
					if (props.filter && !props.filter(elem, i)) return null;
					return <div
						key={cx('draggable', 'div', i)}
						id={elem.props.id}
						className={cx(styles.listElement, {[styles.columns]: props.numbers})}
						draggable
						onDragStart={handleDrag}
						onDragEnd={handleDragEnd}
						onDragOver={handleDragOver}
						style={{
							marginTop: to === initialOrder.indexOf(elem.props.id) && dragHeight ? dragHeight + 10 + 'px' : 0
						}}
					>
						{elem.props.id !== 'null' && props.numbers ?
							<div className={cx(styles.number, 'fieldBox', {[styles.ignored]: propsOrder.indexOf(elem.props.id) > nullIndex})}>
								<>#{propsOrder.indexOf(elem.props.id) + 1}</>
							</div> :
							null
						}
						{React.cloneElement(elem, {
						})}
					</div>;
				})}
			</div>
			<div id='last' onDragOver={handleDragOver} className={cx(styles.spacer, {[styles.highlighted]: dragging })} />
		</div>
		<div className={styles.buttonContainer}
			onDragOver={handleDragOver}
			style={{
				marginTop: to === children.length && dragHeight ? dragHeight + 10 + 'px' : 0
			}}
		>
			<div className={cx('fieldBox', styles.tbReset)}
				id='last'
				onDragOver={handleDragOver}
				onClick={handleReset}
				style={{
					opacity: 'alwaysShowButtons' in props ? +props.alwaysShowButtons : +hasChanged
				}}
			>
				<FiRefreshCcw />RESET
			</div>
			<div className={cx('fieldBox', styles.tbSubmit)}
				id='last'
				onClick={handleSubmit}
				style={{
					opacity: 'alwaysShowButtons' in props ? +props.alwaysShowButtons : +hasChanged
				}}
			>
				<FaSave />SAVE
			</div>
		</div>
	</>;
}