import { useState, useReducer, useEffect, useCallback, useContext } from 'react';

import { GET } from 'utils/requests';
import { GameLinks, Result, ResultObject } from '../../../interfaces';
import Player from 'models/player';
import { Fetched, Game } from 'resources/result';
import { PlayerDictContext, SettingsContext } from '../../../Contexts';

interface PairingProps {
	id: string
	round: number
}

export default function usePairings(props: PairingProps) {

	const settings = useContext(SettingsContext);
	const playerDict = useContext(PlayerDictContext);

	const [resultsState, update] = useReducer(() => Math.random().toString(16).slice(2, 9), '');
	const reduceResults = useCallback((state: Map<string, ResultObject>, {id, W, B, gameLinks, fetched, games}: {
		id: string
		W: Player,
		B: Player,
		gameLinks: GameLinks,
		fetched?: Partial<Fetched>,
		games?: Partial<Game>[]
	}): Map<string, ResultObject> => {
		let scoreW = null;
		if (!W) scoreW = 0;
		else if (W.histories && W.histories[props.round] && W.histories[props.round][settings.displayPoints] !== null) scoreW = W.histories[props.round][settings.displayPoints];
		let scoreB = null;
		if (!B) scoreB = 0;
		else if (B.histories && B.histories[props.round] && B.histories[props.round][settings.displayPoints] !== null) scoreB = B.histories[props.round][settings.displayPoints];
		state.set(id, [
			scoreW,
			scoreB,
			gameLinks,
			fetched,
			games
		]);
		update();
		return state;
	}, [props.round, settings.displayPoints, update]);
	
	const [pairingData, setPairings] = useState([] as Result[]);
	const [results, setResults] = useReducer(reduceResults, new Map() as Map<string, ResultObject>);

	const getPairings = useCallback((): Promise<void> => {
		if (!props.id) return;
		// If you load this while you don't have a playerDict, the playerDict will get updated and then you'll have to load again
		// Plus, all the results will get entered as false byes since they won't be able to fetch their IDs
		if (!playerDict.size) return;
		return GET({
			url: '/tournament/' + props.id + '/getPairings',
			params: { round: props.round }
		})
			.then((pairings: Result[]) => {
				for (let r of pairings) {
					let { id, idW, idB, fetched, games } = r;
					let gameLinks = { link: r.link, source: r.linkSource, type: r.type };
					let [W, B] = [playerDict.get(idW), playerDict.get(idB)];
					setResults({
						id, 
						W,
						B,
						gameLinks,
						fetched,
						games
					});
				}
				setPairings(pairings);
			})
			.catch(console.error);
	}, [props.id, setPairings, setResults, props.round, playerDict]);

	useEffect(() => {
		getPairings();
	}, [getPairings]);

	return { pairingData, results, getPairings, resultsState };
}