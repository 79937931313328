import { User } from '../interfaces';
import { daysBetween } from './prototype';


export const defaultUserSettings = {
	username: {
		name: 'Username',
		type: 'string',
		convert: (v: string) => v || 'guest',
		locked: true,
		primaryKey: true,
	},
	email: {
		name: 'Email',
		type: 'string',
		locked: true,
		defaultValue: '',
		hideIfGuest: true
	},
	licensed: {
		name: 'License',
		type: 'number',
		defaultValue: 0,
		locked: true,
		convert: (v: number) => {
			if (v === 1) return 'Trial';
			if (v === 2) return 'Licensed';
			if (v === 3) return 'Admin';
			return 'Free';
		}
	},
	daysRemaining: {
		name: 'Trial time',
		type: 'number',
		locked: true,
		value: (user: User) => Math.floor(30 - daysBetween(new Date(user.lastLicensed), new Date(Date.now()))),
		convert: (v: number) => v + ' days',
		validate: (v: string, user: User) => user.licensed === 1
	},
	lastLicensed: {
		name: 'Last licensed',
		type: 'date',
		defaultValue: null,
		locked: true,
		convert: (v: string) => new Date(v).toString().slice(0, 21),
		validate: (v: string) => !!v
	},
	backgroundURL: {
		name: 'Background Image',
		type: 'string',
		defaultValue: '/images/leaves-texture.png',
		locked: true,
		validate: (v: string) => !!v,
		editable: true,
		show: false
	},
	createdAt: {
		name: 'Account created',
		convert: (v: string) => new Date(v).toString().slice(0, 21),
		locked: true,
		defaultValue: Date.now(),
		hideIfGuest: true
	},
	updatedAt: {
		name: 'Last seen',
		convert: (v: string) => new Date(v).toString().slice(0, 21),
		locked: true,
		defaultValue: Date.now(),
		hideIfGuest: true
	}
} as {
	[key: string]: {
		name?: string,
		enum?: string[],
        convert?: (v: any) => string,
        validate?: (v: any, user: User) => boolean
        value?: (user: User) => any
		type?: string
		locked?: boolean
		show?: boolean

		primaryKey?: boolean
		defaultValue?: any
		editable?: boolean
	}
};