import React, { ReactNode } from 'react';
import { isElectron } from './electron';

export function Link(props: {
	to: string
	id?: string
	className?: string
	children?: ReactNode;
} & React.RefAttributes<HTMLAnchorElement>) {

	if (isElectron) {
		return <div id={props.id} onClick={() => (window as any).api.shell}>
			{props.children}
		</div>;
	
	} else {

		return <a href={props.to} id={props.id} target='_blank' rel='noopener noreferrer' {...props}>
			{props.children}
		</a>;
	}
	
}