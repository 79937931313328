import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import cx from 'classnames';
import Sheet from 'react-datasheet';

import styles from 'screen/css/spreadsheet.module.css';
import 'react-datasheet/lib/react-datasheet.css';

import Player from 'models/player';
import Core, { Button } from '../Core';
import { camelCaseToWords, isDate } from 'utils/prototype';
import { FaArrowsAltV, FaCompressArrowsAlt, FaExpandArrowsAlt, FaEye, FaEyeSlash, FaMap, FaMinus, FaPlus } from 'react-icons/fa';
import { booleanConvert } from 'resources/settings';
import { SettingsContext } from '../../Contexts';
import usePlayers from 'screen/hooks/usePlayers';
import usePages from 'screen/hooks/usePages';

export interface GridElement extends Sheet.Cell<GridElement, number> {
	value?: string | number | boolean | null;
	header?: string,
	locked?: boolean
}

interface SpreadsheetProps {
	players: Player[]
	className?: string
	expanded?: boolean
	toggleExpand?: () => void
}

const defHeaderArr = ['id', 'tournamentId', 'name', 'firstName', 'lastName', 'nationality', 'rating', 'active', 'createdAt', 'updatedAt', 'score', 'gamePoints',
	'played', 'performanceRating', 'expectedScore', 'expectedGameScore', 'whiteTotal', 'chessCom', 'teamId', 'lastName', 'lichess'];
const locked = ['id', 'tournamentId', 'score', 'gamePoints', 'expectedGameScore', 'played', 'performanceRating', 'expectedScore',
	'whiteTotal', 'createdAt', 'updatedAt'];
const defHeaders = defHeaderArr.reduce((acc, curr) => {
	acc[curr] = { readOnly: locked.includes(curr) };
	return acc;
}, {} as { [key: string]: { readOnly: boolean }});
const maxRows = Math.floor(window.innerHeight / 75 - 1);

export default function Spreadsheet(props: SpreadsheetProps): ReactElement {

	const settings = useContext(SettingsContext);
	
	const { players, setPlayers } = usePlayers({
		players: props.players,
		sortFunc: (a, b) => {
			if (a.firstName > b.firstName) return 1;
			if (a.firstName < b.firstName) return -1;
			return 0;
		}
	});

	const { usingPages, page, setPage, setPages, maxPages } = usePages(Math.ceil(players.length / maxRows));
	const [readOnly, showReadOnly] = useReducer((s: boolean) => !s, true);

	const allHeaders = useMemo(() => {
		let obj: { [key: string]: { readOnly: boolean }} = players.reduce((acc, p) => {
			for (let [k, v] of Object.entries(p)) {
				if (k in acc) continue;
				if (typeof v === 'object') continue;
				if (settings.competitors === 'team') {
					if (k === 'firstName') continue;
					if (k === 'lastName') continue;
				}
				let r = locked.includes(k);
				acc[k as keyof Player] = { readOnly: r };
			}
			return acc;
		}, defHeaders);
		return obj;
	}, [players, settings]);
	const headers = useMemo(() => {
		if (readOnly) return Object.entries(allHeaders);
		let obj = Object.assign({}, allHeaders);
		for (let k of locked) delete obj[k];
		return Object.entries(obj);
	}, [allHeaders, readOnly]);

	const [start, end] = useMemo(() => usingPages ? [maxRows * page, maxRows * page + maxRows] : [0, players.length],
		[usingPages, page, players]);
	const grid = useMemo(() => {
		let g = [] as GridElement[][];

		let row = [] as GridElement[];
		for (let h of headers) {
			row.push({
				readOnly: true,
				value: h[0] === 'id' ? 'Scorch ID' : camelCaseToWords(h[0]),
				header: h[0],
				className: cx(styles.header),
				locked: h[1].readOnly
			});
		}
		g.push(row);

		for (let i = start; i < end; i++) {
			let p = players[i];
			if (!p) continue;
			let row = [] as GridElement[];
			for (let [h, meta] of headers) {
				if (typeof h === 'object') continue;
				let v = p[h as keyof Player];
				row.push({
					readOnly: meta.readOnly,
					value: preConvert(v) as string | number | null,
					header: h,
					className: cx(styles.cell)
				});
			}
			g.push(row);
		}
		return g;
	}, [players, headers, page, start, end]);

	const handleCellsChanged = useCallback((changes: { cell: Sheet.Cell<any, any>, row: number, col: number, value: any }[]) => {
		for (let c of changes) {
			let h = headers[c.col][0];
			setPlayers({
				index: c.row - 1,
				updates: {
					[h]: c.value
				}
			});
		}
	}, [headers, setPlayers]);

	const renderSheet = useCallback((props: React.PropsWithChildren<Sheet.SheetRendererProps<GridElement, any>>) => {
		return <table className={cx(props.className, styles.table)}>
			<tbody className={styles.body}>
				{props.children}
			</tbody>
		</table>;
	}, []);

	const highlightRow = useCallback(() => {
		let selected = document.getElementsByClassName('selected')[0] as HTMLElement;
		let arr = Array.from(document.getElementsByClassName(styles.longSelect));
		for (let elem of arr) {
			elem.classList.remove(styles.longSelect);
		}
		if (!selected) return;
		if (selected.classList.contains(styles.header)) return;
		let row: HTMLElement;
		while (selected.parentElement) {
			if (selected.parentElement.tagName === 'TR') {
				row = selected.parentElement;
				break;
			}
			selected = selected.parentElement;
		}
		row.classList.add(styles.longSelect);

		let headerProp = selected.getAttribute('data-header');
		let column = document.querySelectorAll(`tr:first-child td[data-header="${headerProp}"]`)[0];
		column.classList.add(styles.longSelect);

	}, []);
	useEffect(() => {
		window.addEventListener('click', highlightRow);
		return () => window.removeEventListener('click', highlightRow);
	}, [highlightRow]);

	return (
		<>	
			<Core className={cx(styles.core, props.className)} childClass={styles.wrapper} isChild
				id='export'
				title='Spreadsheet of all players'
				description={!props.expanded ? '' : 'Edit your players here as you would an Excel sheet. Press save when you\'re done.'}
				buttons={([
					{
						name: usingPages ? 'Paged mode' : 'Scroll mode',
						icon: usingPages ? FaMap : FaArrowsAltV,
						onClick: () => setPages(!usingPages),
					},
					{
						name: props.expanded ? 'Contract' : 'Expand',
						icon: props.expanded ? FaCompressArrowsAlt : FaExpandArrowsAlt,
						onClick: props.toggleExpand,
						hide: !props.toggleExpand
					},
					{
						name: readOnly ? 'Showing all' : 'Only editable',
						icon: readOnly ? FaEye : FaEyeSlash,
						onClick: showReadOnly
					}
				] as Button[]).concat(!usingPages ? [] : [
					{
						name: '',
						id: 'decrement',
						icon: FaMinus,
						onClick: () => setPage('decrement'),
						inactive: !page
					},
					{
						name: `Page ${page + 1}`,
						className: styles.indicator,
						icon: null,
						onClick: () => {}
					},
					{
						name: '',
						id: 'increment',
						icon: FaPlus,
						onClick: () => setPage('increment'),
						inactive: page >= maxPages - 1
					}
				])}
			>
				<div className={styles.players}>
					<Sheet
						data={grid}
						valueRenderer={(cell: GridElement) => postConvert(cell.value, cell)}
						dataRenderer={(cell: GridElement) => dataConvert(cell.value)}
						attributesRenderer={(cell: GridElement) => ({ 'data-header': cell.header, 'data-locked': cell.locked ? true : undefined })} 
						onCellsChanged={handleCellsChanged}
						sheetRenderer={renderSheet}
					/>
				</div>
				<div className='container' style={{display: 'none' }}>
					<div className={styles.pasteContainer}>
						<div className={styles.paste}>
							<div className={[styles.pasteArea].join(' ')}>
								<textarea
									className={[styles.output, 'scrollable'].join(' ')}
									name='textArea'
									placeholder='Raw data output'
								/> 
							</div>
						</div>
					</div>
				</div>
			</Core>
		</>
	);
}

function preConvert(value: any): string | boolean | number {
	switch (typeof value) {
	case 'object':
		return null;
	case 'boolean':
		return value;
	case 'number':
		let rounded = Math.round(value * 1000) / 1000;
		if (value !== rounded) return rounded;
	}
	return value;
}

function postConvert(value: string | number | boolean | null, cell?: GridElement): string | number {
	switch (typeof value) {
	case 'boolean':
		return booleanConvert(value, ['Y', 'N']);
	case 'string':
		if (isDate(value)) return new Date(value).toString().slice(4, 15);
	}
	return value;
}
function dataConvert(value: string | number | boolean | null): string | number {
	switch (typeof value) {
	case 'string':
		if (isDate(value)) return new Date(value).toISOString();
	}
	return postConvert(value);
}