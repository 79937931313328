import React, { ReactElement, useEffect, CSSProperties, useState, ReactNode, useRef, useCallback } from 'react';
import cx from 'classnames';
import styles from 'css/components.module.css';
import { FaAngleLeft } from 'react-icons/fa';

interface SectionProps {
    children?: ReactNode
    header: string | ReactElement
    useInternalState?: boolean
    value?: string
	expanded?: boolean
	mustExpand?: boolean
    setExpanded?: (state: boolean, action: string) => void
    style?: CSSProperties
	id?: string
	collapsable?: boolean
	scrollable?: boolean
	assertHeight?: string | number
	hasLoaded?: boolean
	withLengths?: boolean | number
}

export function SectionBreak() {

	return (
		<div className='sectionContainer'>
			<div className={[styles.sectionHeader, 'topBanner'].join(' ')}>
				<div className={styles.sectionHeaderText} style={{cursor: 'default'}} />
			</div>
		</div>
	);
}

export default function CollapsableSection(props: SectionProps) {

	const [expanded, expand] = useState(props.expanded);
	const [height, setHeight] = useState(0);
	const [mount, setMount] = useState(false);

	const innerDiv = useRef() as React.RefObject<HTMLDivElement>;
	useEffect(() => {
		expand(props.expanded);
	}, [expand, props.expanded]);
	const [internalExpanded, setExpanded] = useState(props.expanded !== undefined ? props.expanded : true);
	useEffect(() => {
		if (props.useInternalState) expand(internalExpanded);
	}, [props.useInternalState, internalExpanded, expand]);
	useEffect(() => {
		if (props.mustExpand && !expanded) expand(true);
	}, [props.mustExpand, expanded, expand]);

	useEffect(() => {
		if (expanded) {
			setMount(true);
			return;
		}
		let x = setTimeout(() => setMount(false), 750);
		return () => clearTimeout(x);
	}, [expanded, setMount]);
	
	const updateHeight = useCallback(() => {
		if (!innerDiv.current) return;
		setHeight(expanded ? innerDiv.current.getBoundingClientRect().height : 0);
	}, [setHeight, expanded, innerDiv.current, props.assertHeight]);
	useEffect((): void => {
		updateHeight();
	}, [updateHeight, props.children]);
	useEffect(() => {
		window.addEventListener('resize', updateHeight);
		return () => window.removeEventListener('resize', updateHeight);
	}, [updateHeight]);
	useEffect(() => {
		let f = () => window.dispatchEvent(new Event('resize'));
		f();
		let x = setTimeout(f, 100);
		return () => clearTimeout(x);
	}, [props.expanded, mount]);

	useEffect(() => {
		if (!innerDiv.current) return;
		for (let child of Array.from(innerDiv.current.children)) {
			if ('onload' in child) (child as HTMLImageElement).onload = updateHeight;
			for (let grandChild of Array.from(child.children)) {
				if ('onload' in grandChild) (grandChild as HTMLImageElement).onload = updateHeight;
			}
		}
	}, [innerDiv.current, updateHeight]);

	const handleClick = useCallback(() => {
		if (props.collapsable === false) return;
		if (props.useInternalState) setExpanded(!expanded);
		else props.setExpanded(!expanded, props.value);
	}, [props.collapsable, props.useInternalState, setExpanded, expanded, props.setExpanded, props.value]);

	return (
		<div className='sectionContainer' style={props.style}>
			<div className={cx(styles.sectionHeader, 'topBanner', { [styles.withLengths]: props.withLengths })}>
				<div className={styles.sectionHeaderText} onClick={handleClick}>
					{props.header && props.hasLoaded !== false ? props.header : '\u200b'}
				</div>
				{props.withLengths ? <div className={styles.length}>(
					{typeof props.withLengths === 'number' ?
						props.withLengths :
						Array.isArray(props.children) ?
							props.children.length : 
							null
					}
				)</div> : null}
				{!props.children || props.collapsable === false ?
					null : 
					<div className={styles.sectionToggle} onClick={handleClick}>
						<FaAngleLeft style={{
							transform: expanded ? 'rotate(-90deg)' : 'rotate(0deg)'
						}}/>
					</div>
				}
			</div>
			{!mount ? null : <div className={cx(styles.sectionListWrapper, {
				[styles.expanded]: expanded,
				scrollable: props.scrollable,
				['scrollable=x']: props.scrollable
			})} style={{
				height
			}}>
				<div ref={innerDiv} className={cx(styles.sectionList)} id={props.id}>
					{props.children}
				</div>
			</div>}
		</div>
	);
}