export const space = /\s+/g;
export const letters = /\w+/g;
export const image = /<img\s+.*?src=["'](.*).*?">/;
export const date = /^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}_/;
export const href = /^(https?:)?\/\//;

/* eslint-disable-next-line no-control-regex */
export const email = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

export const chessComTeam = /chess\.com\/(?:live(?:\?\w+)?#tm=|club\/matches\/live\/(?:[a-z-]+\/)?)([0-9]+)/;

export const streamLink = /(?:https:\/\/)(t?:www.)(?:(twitch).tv|(youtube).com)\/(.*)/;

export const trueBoolean = /^(y(es)?|true|on)?$/i;
export const falseBoolean = /^(n(?:o)?|false|off)?$/i;

export const spaces = /\s+/g;

export const lichess = /^[a-z][\w-]{0,28}[a-z0-9]$/i;

export const link = /(?:https:\/\/)?(?:www.)?([a-z.]*)\/?(?:.*)?/;

export const camelCase = /^[a-z][A-Za-z]*$/;
export const lettersCapital = /[A-Z]/g;
export const stripPunctuation = /[^\w_.,+]/g;