import React, { ReactElement, useContext } from 'react';
import { LoadedContext } from '../Contexts';

interface BottomProps {
	name: ReactElement | string
	hide?: boolean
	onClick?: () => void
	icon?: JSX.Element
	className?: string
}
export function Bottom(arr: BottomProps[]): ReactElement[] {

	const hasLoaded = useContext(LoadedContext);

	return arr.map((item, i) => {
		if (!item) return null;
		let {name, icon, onClick, hide} = item;
		if (hide) return null;
		return <div key={['button', i].join('.')} onClick={onClick} className={['button', item.className, !hasLoaded ? 'skel' : ''].join(' ')}>
			{icon}{' '}{name}
		</div>;
	});
}