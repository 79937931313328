import React, { useState, useCallback, useReducer } from 'react';
import Player from 'models/player';
import { GET } from 'utils/requests';

/**
 * This is such a tricky piece of logic
 * that we're going to isolate it in it's own custom hook
 * so that its systems and interconnectedness are very clear
 */
export function usePlayerDict({
	id,
	displayPlayer,
	setDisplayPlayer
}: {
	id: string
	displayPlayer: string
	setDisplayPlayer: (id: string) => void
}) {

	const [players, setPlayers] = useState([] as Player[]);
	const [setDictTimeout, awaitTimeout] = useState(false);
	const [lastUpdate, setLastUpdate] = useState(Date.now());

	const reducePlayerDict = useCallback((state: Map<string, Player>, action: {
		id?: string,
		player?: Player,
		players?: Player[],
		hash?: string
	}) => {
		if (action.id) {
			state.delete(action.id);
			if (action.id === displayPlayer) setDisplayPlayer('');
			return state;
		}
		let players: Player[];
		if (action.players) {
			state = new Map();
			players = action.players;
		} else players = [action.player];
		for (let p of players) state.set(p.id, p);
		return new Map(state);
	}, [setDisplayPlayer, displayPlayer, lastUpdate]);
	
	
	const [playerDict, setPlayerDict] = useReducer(reducePlayerDict, new Map() as Map<string, Player>);

	const updatePlayerDict = useCallback((force?: boolean): Promise<void> => {
		if (!force && (Date.now() - lastUpdate < 3000)) {
			setLastUpdate(Date.now());
			awaitTimeout(true);
			return Promise.resolve();
		}
		return GET({ url: '/tournament/' + id + '/fetchPlayers' })
			.then((players: Player[]) => {
				setLastUpdate(Date.now());
				awaitTimeout(false);
				setPlayers(players);
				setPlayerDict({ players });
			});
	}, [id, setPlayers, setPlayerDict, lastUpdate]);

	return {
		players,
		setDictTimeout,
		playerDict,
		updatePlayerDict,
		setPlayerDict
	};
}