import React, { useState, ReactElement, useRef, RefObject, useEffect, useCallback, useContext } from 'react';
import cx from 'classnames';
import url from 'url';
import { server, POST } from 'utils/requests';
import { Player } from '../../interfaces';

import componentStyles from 'css/components.module.css';
import pairingStyles from './css/pairings.module.css';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { DisplayNameContext, PlayerDictContext } from '../../Contexts';
import { px } from 'utils/prototype';

interface ManualProps {
	id: string
	round: number
	handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<React.FormEvent<HTMLFormElement>>
}
export default function ManualPairing(props: ManualProps): ReactElement {

	const [edit, setEdit] = useState(false);

	if (!edit) return (
		<div className={cx('button', pairingStyles.pairingAddButton)} onClick={() => setEdit(true)}>
			New manual pairing
		</div>
	);
	return (
		<div className={pairingStyles.manualPairing}>
			<Form {...props} close={() => setEdit(false)} />
		</div>
	);

}

function Form(props: ManualProps & { close: () => void }): ReactElement {

	const [W, setW] = useState(null as Player);
	const [B, setB] = useState(null as Player);

	const [valid, setValid] = useState(false);
	const [hover, setHover] = useState('');
	const formRef = useRef(null) as RefObject<HTMLFormElement>;

	const getDisplayName = useContext(DisplayNameContext);
	const playerDict = useContext(PlayerDictContext);

	useEffect(() => {
		setValid(false);
		if (!W || !B) return;
		if (W.id === 'bye' && B.id === 'bye') return setHover('Cannot pair BYE with BYE');
		if (W.histories && W.histories[props.round] && !W.histories[props.round].match) return setHover(getDisplayName(W.id) + ' is already paired');
		if (B.histories && B.histories[props.round] && !B.histories[props.round].match) return setHover(getDisplayName(B.id) + ' is already paired');
		setHover('');
		setValid(true);
	}, [W, B]);

	const handleInput = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
		let input = e.target as HTMLInputElement;
		if (!(e.nativeEvent as any as InputEvent).data) {
			if (input.name === 'idW') setW(null);
			else setB(null);
			return;
		}
		let text = input.value;
		if (!text.trim()) return;
		let length = text.length;
		let choices = [{ id: 'bye'} as any as Player, ...Array.from(playerDict.values())].filter(p => getDisplayName(p.id).toLowerCase().startsWith(text.toLowerCase()));
		let firstChoice = choices[0];
		if (!firstChoice) return;
		let autofill = getDisplayName(firstChoice.id);
		input.value = autofill;
		input.select();
		input.setSelectionRange(length, autofill.length, 'forward');
		if (input.name === 'idW') setW(firstChoice);
		else setB(firstChoice);
	}, [playerDict]);

	const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLFormElement>) => {
		switch (e.keyCode) {
		case 27: {
			return props.close();
		}
		case 13: {
			let form = formRef.current as HTMLFormElement;
			if (!form) return;
			form.requestSubmit();
			e.preventDefault();
		}
		}
	}, [props.close]);

	return (
		<form
			ref={formRef}
			target='_self'
			onKeyDown={handleKeyDown}
			onSubmit={(e) => {
				e.preventDefault();
				POST({
					url: px('tournament', props.id, 'manualPairing'),
					data: {
						idW: W.id,
						idB: B.id,
						round: props.round
					}
				}).then(() => props.handleSubmit(e));
			}}
			method='POST'
			action={url.resolve(server, px('tournament', props.id, 'manualPairing'))}
		>
			<div className={pairingStyles.manualResultsBridge}>
				<input
					onInput={handleInput}
					autoComplete='off'
					placeholder='White'
					className={['playerInfo', pairingStyles.playerInfo].join(' ')}
					name='idW'
					style={{ gridArea: 'white'}}
				/>
				<div className={pairingStyles.scoreSpacer} style={{ gridArea: 'wR' }} />
				<div className={pairingStyles.centre} style={{ gridArea: 'dash' }}>
					{hover ? <span className='tooltip'>{hover}</span> : null}
					{valid ?
						<button tabIndex={-1} className={componentStyles.resolve}>
							<FaCheck />
						</button> :
						<button tabIndex={-1} className={componentStyles.reject} onClick={props.close}>
							<FaTimes />
						</button>
					}
				</div>
				<div className={pairingStyles.scoreSpacer} style={{ gridArea: 'bR' }} />
				<input
					onInput={handleInput}
					autoComplete='off'
					placeholder='Black'
					className={['playerInfo', pairingStyles.playerInfo].join(' ')}
					name='idB'
					style={{ gridArea: 'black' }}
				/>
			</div>
		</form>
	);
}