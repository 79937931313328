import Axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import config from '../config.json';
import { isDev } from './electron';
import { AnyDictionary } from '../interfaces.js';
import Cookies from './cookies';

let errorCallback: (header: string, message: string) => void = () => {};

export let cookies = new Cookies();

export const server = !isDev || config.useProd ? config.prodServer : config.server;
export const resultsServer = !isDev || config.useProd ? config.prodResultsServer : config.resultsServer;

const axios = Axios.create({
	baseURL: server
});

// alter all requests to use cookies only from AsyncStorageCookieStore
axios.interceptors.request.use(async config => {
	
	// the device will have it's own cookie store, and attempt to
	// send cookies itself.
	// this has been documented to be unreliable, so we wipe these cookies
	// and implement the cookie store ourselves.

	//await AsyncStorage.clear()
	return {
		...config,
		headers: {
			...config.headers,
			Authorization: JSON.stringify(await cookies.getAll())
		}
	};

});

export { Axios, axios };

interface RequestConfig extends AxiosRequestConfig {
	noCatch?: boolean
}

export function GET(options: RequestConfig): Promise<any> {
	options = Object.assign(options, {
		method: 'GET'
	});
	return axios(options)
		.then((res: AxiosResponse<any>) => res.data)
		.catch((e: AxiosError) => {
			if (options.noCatch) throw e;
			console.error(e);
			if (e.response) errorCallback(`${e.response.status} ${e.response.statusText}: /${e.response.config.url.split('/').pop()}`, e.response.data);
			throw e;
		});
}

export function POST(options: RequestConfig): Promise<any> {
	options = Object.assign(options, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		}
	});
	return axios(options)
		.then((res: AxiosResponse<any>) => res.data)
		.catch((e: AxiosError) => {
			if (options.noCatch) throw e;
			if (e.response) errorCallback(`${e.response.status} ${e.response.statusText}: /${e.response.config.url.split('/').pop()}`, e.response.data);
			throw e;
		});
}

export function FORM(e: React.FormEvent<HTMLFormElement>): Promise<any> {
	if (e.preventDefault) e.preventDefault();
	if (e.persist) e.persist();
	let form = e.target as HTMLFormElement;
	while (form.constructor.name !== 'HTMLFormElement' && form.parentElement) {
		form = form.parentElement as HTMLFormElement;
	}
	if (form.constructor.name !== 'HTMLFormElement') throw new Error('Couldn\'t find valid form');
	let inputs = Array.from(form) as HTMLInputElement[];
	let data = inputs.reduce((acc, curr) => {
		if (!curr.name) return acc;
		if (curr.name === 'submit') return acc;
		if (curr.type === 'number') acc[curr.name] = Number(curr.value);
		else acc[curr.name] = curr.value;
		return acc;
	}, {} as AnyDictionary);
	let options = {
		data,
		url: form.action
	} as AxiosRequestConfig;
	return POST(options);
}

export function setErrorCallback(c: (header: string, message: string) => void) {
	errorCallback = c;
}

/*

class Requests {

	GET(options: AxiosRequestConfig): Promise<any> {
		options = Object.assign(options, {
			method: 'GET'
		});
		return axios(options)
			.then((res: AxiosResponse<any>) => res.data);
	}

	POST(options: AxiosRequestConfig): Promise<any> {
		options = Object.assign(options, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			}
		});
		return axios(options)
			.then((res: AxiosResponse<any>) => res.data);
	}

	FORM(e: React.FormEvent<HTMLFormElement>): Promise<any> {
		e.preventDefault();
		let form = e.target as HTMLFormElement;
		let inputs = Array.from(form) as HTMLInputElement[];
		let data = inputs.reduce((acc, curr) => {
			if (!curr.name) return acc;
			if (curr.name === 'submit') return acc;
			acc[curr.name] = curr.value;
			return acc;
		}, {} as AnyDictionary);
		let options = {
			data,
			url: form.action
		} as AxiosRequestConfig;
		return this.POST(options);
	}

}

let r = new Requests();
export const GET = r.GET;
export const POST = r.POST;
export const FORM = r.FORM;

*/