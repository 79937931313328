import React, { ReactElement, useState, useContext, RefObject, useMemo } from 'react';
import cx from 'classnames';

import styles from './css/core.module.css';
import { MdExitToApp } from 'react-icons/md';
import { SetModeContext, SettingsContext } from '../Contexts';
import { IconProps, TournamentModes } from '../interfaces';
import Dropdown from './components/Dropdown';
import { isDev } from 'utils/electron';
import { Link } from 'utils/link';
import useIcon from 'hooks/useIcon';

export interface Button extends IconProps {
	id?: string
	href?: string
	className?: string
}
interface DropdownProps {
	resolve: (v: string) => void
	dropdown: boolean
	current: string
	options: {[key: string]: string} | {[key: string]: { name: string }} | (string | null)[]
}
interface CoreProps {
	id: TournamentModes
	title?: string
	description?: string
	buttons?: (Button | DropdownProps)[]
	short?: boolean
	propRef?: RefObject<HTMLDivElement> | ((elem: HTMLDivElement) => void)
	childRef?: RefObject<HTMLDivElement>
	className?: string
	childClass?: string
	isChild?: boolean
	scrollable?: boolean
	children: ReactElement | ReactElement[]
}

export default function Core(props: CoreProps) {

	const setMode = useContext(SetModeContext);
	const settings = useContext(SettingsContext);

	const pages = useMemo(() => {
		return {
			pasteInput: {
				name: 'Smart Input mode',
			},
			export: {
				name: 'Export mode',
				hide: !isDev
			},
			settings: {
				name: 'Detailed settings view'
			},
			crossTable: {
				name: 'Cross-table'
			},
			announcements: {
				name: 'Announcements',
				hide: !settings.isPublic
			},
			knockout: {
				name: 'Knockout pairing view',
				hide: settings.pairingSystem !== 'knockout'
			},
			sort: {
				name: 'Quick Drag & Drop'
			}
		} as {[key in TournamentModes]: {
			name: string,
			hide?: boolean
		}};
	}, [settings]);

	const { Icon } = useIcon();

	return (
		<div className={cx(styles.stage, {[styles.short]: props.short}, props.className)} ref={props.propRef}>
			<div className={styles.titleContainer}>
				<div className={styles.titleWrapper}>
					<div className={styles.titles}>
						{props.isChild ? 
							props.title ?
								<div className={styles.title}>
									{props.title}
								</div> :
								null :
							<Dropdown
								current={props.id}
								options={pages as any}
								title={props.title}
								resolve={setMode}
								className={cx('button', styles.button)}
							/>
						}
						{props.buttons?.map((b, i) => {
							if ('dropdown' in b) return <Dropdown
								{...b}
								className={cx('button', styles.button)}
							/>;
							if (b.href) return <Link to={b.href} key={cx(b.name, i)} id={b.id} className={cx('button', styles.button, b.className)} >
								<Icon {...b} />
								{b.name}
							</Link>;
							return <div key={cx(b.name, i)} className={cx('button', styles.button, b.className)} id={b.id} onClick={b.inactive ? () => {} : b.onClick}>
								<Icon {...b} />
								{b.name}
							</div>;
						})}
						{props.isChild ? null : <div className={cx('button', styles.closeButton)} onClick={() => setMode('')}>
							<MdExitToApp style={{color: 'indianred'}} />Back to tournament
						</div>}
						{props.description ?
							<div className={styles.description}>
								{props.description}
							</div> :
							null
						}
					</div>
				</div>
			</div>
			<div ref={props.childRef} className={cx(styles.body, props.childClass, {
				scrollable: props.scrollable !== false,
				'scrollable-x': true
			})}>
				{props.children}
			</div>
		</div>
	);
}