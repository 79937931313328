import React, { ReactElement, useMemo, useCallback } from 'react';
import { TournamentSettings } from '../interfaces';

import styles from 'css/profile.module.css';
import crosstableStyles from './css/crosstable.module.css';
import { defaultSettings, TournamentSetting } from 'resources/settings';
import { capitalise } from 'utils/prototype';
import CollapsableSection from 'components/CollapsableSection';
import Draggable from 'components/DraggableRows';
import { TieBreaks } from 'resources/tiebreaks';
import EditableField, { useEditable } from 'components/EditableField';
import { SettingsProps } from 'tournament/Rightbar/Settings';
import { POST, server } from 'utils/requests';
import url from 'url';
import defaultProfile from 'assets/default_profile.jpg';
import { MdExitToApp } from 'react-icons/md';
import Core from './Core';

export default function FullScreen(props: SettingsProps & {
	leave: () => void
}): ReactElement {

	const { SettingsRow } = useEditable(props);

	const tieBreaks = useMemo(() => {
		let entries = Object.entries(TieBreaks);
		if (props.settings.tieBreaks) {
			let arr = props.settings.tieBreaks.split(',');
			entries = entries.sort((a, b) => arr.indexOf(a[0]) - arr.indexOf(b[0]));
		}
		return entries;
	}, [props.settings.tieBreaks]);
    
	const clearRound = useCallback((round: number): Promise<void> => {
		return POST({
			url: '/tournament/' + props.status.id + '/clearRound',
			data: { round }
		});
	}, [props.status.id]);

	const renderRound = useCallback((i: number) => {
		return (
			<div className={[styles.row, styles.roundRow].join(' ')} key={['row', i].join('.')}>
				<div className='fieldBox'>
					{`Round ${i}:`}
				</div>
				<EditableField
					handleClear={() => clearRound(i)}
					content={props.rounds[i] ? props.rounds[i].toString() : ''}
					defaultEditContent={new Date(Date.now()).toString()}
					url={url.resolve(server, ['tournament', props.status.id, 'updateRound'].join('/'))}
					inputType='datetime-local'
					onSubmitSuccess={props.updateRounds}
					classNames={{
						editableField: styles.editableField,
						input: styles.input,
					}}
					additionalInputProps={{}}
					name={'round.' + i.toString()}
					show={{
						submit: true,
						increment: false,
						clear: true
					}}
					handleSubmit={(k, v) => {
						if (isNaN(new Date(v).valueOf())) return Promise.reject();
						let number = k.split('.').pop() as string;
						return POST({
							url: url.resolve(server, ['tournament', props.status.id, 'updateRound'].join('/')),
							data: { [Number(number)]: new Date(v).toISOString() }
						});
					}}
					useHandleSubmit
				/>
			</div>
		);
	}, [props.updateSetting, props.rounds, props.status.id, props.updateRounds]);

	let roundEntries = [];
	let length = props.settings.totalRounds === Infinity || props.settings.totalRounds === 'Infinity' ? Math.max(props.rounds.length, 1) : props.settings.totalRounds;
	for (let i = 1; i <= length; i++) roundEntries.push(i);

	return (
		<Core id='settings'>
			<div className={[styles.profileContainer, 'scrollable-x'].join(' ')}>
				<div className={styles.fs}>
					{Object.entries(defaultSettings).map(([section, data]): ReactElement => {
						if (section.endsWith('hidden')) return null;
						return (
							<CollapsableSection
								key={section}
								value={section}
								header={capitalise(section.split('_').join(' '))}
								expanded
								useInternalState
								collapsable={false}
								assertHeight='100%'
							>
								{(Object.entries(data) as [keyof TournamentSettings, TournamentSetting<string | number | boolean>][])
									.map(([k, v], i) => <SettingsRow key={['k', i].join('.')} k={k} def={v} i={i} />)}
							</CollapsableSection>
						);
					})}
					{props.settings.roundTimes ? <CollapsableSection
						key={'rounds'}
						value={'rounds'}
						header={'Rounds'}
						useInternalState
						expanded={true}
						assertHeight='100%'
						collapsable={false}
					>
						{roundEntries.map(renderRound)}
					</CollapsableSection> : null}
					<CollapsableSection
						key={'profile'}
						value={'profile'}
						header={'Profile'}
						useInternalState
						expanded={true}
						assertHeight='100%'
						style={{
							width: '31.8vw'
						}}
						collapsable={false}
					>
						<div className={styles.baseContainer}>
							<div className={styles.profilePictureFrame}>
								<img className={styles.profilePicture} src={props.settings.logoURL || defaultProfile} alt='Invalid URL' />
							</div>
							<EditableField
								handleSubmit={(k, v) => props.updateSetting(k, v)}
								content={props.settings.description}
								url={url.resolve(server, ['tournament', props.status.id, 'updateSetting'].join('/'))}
								inputType='textarea'
								classNames={{
									form: styles.description,
									editableField: [styles.editableField, styles.descriptionDiv].join(' '),
									input: [styles.input, 'scrollable'].join(' ')
								}}
								name={'description'}
								additionalInputProps={{}}
								show={{
									submit: true,
									reset: true
								}}
								useHandleSubmit
							/>
						</div>
					</CollapsableSection>
					<CollapsableSection
						value='tiebreaks'
						header='Tie Breaks'
						expanded
						useInternalState
						collapsable={false}
						assertHeight='100%'
						style={{
							width: '31.8vw'
						}}
					>
						<Draggable
							className={styles.tieBreaks}
							onSubmit={(v: string[]) => props.updateSetting('tieBreaks', v.join(',')).then(props.updateState)}
							numbers
						>
							{tieBreaks.map(([k, v]) => {
								if (!v) return <div key={k} id={k} className={styles.divider} />;
								return <div key={k} id={k} className={['fieldBox', styles.tb].join(' ')}>
									{v.name}
								</div>;
							})}
						</Draggable>
					</CollapsableSection>
				</div>
			</div>
		</Core>
	);
}