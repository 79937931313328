import { Parser } from 'xml2js';

import { GET, axios } from 'utils/requests';
import { OSKey, LatestInfo } from '../interfaces';
import Axios from 'axios';
const parser = new Parser();

export async function getUpdates(): Promise<LatestInfo> {
	let result = await GetData();
	let data = Sort(result.ListBucketResult.Contents);
	let ubuntu = data.find(object => object.Key[0].split('/')[0] === 'ubuntu');
	let windows = data.find(object => object.Key[0].split('/')[0] === 'windows');
	let mac = data.find(object => object.Key[0].split('/')[0] === 'mac');
	let output = { windows, mac, ubuntu };
	return output;
}

export function Version(v: string) {
	let [x, y, z] = v.split('.');
	if (!x || !y || !z) return 1;
	let total = Number(x) * 10000 + Number(y) * 100 + Number(z);
	return total;
}

function Sort(rawData: OSKey[]): OSKey[] {
	return rawData.sort((a, b) => {
		let [, aName] = a.Key[0].split('/');
		if (!aName) return 1;
		let [, aVersion] = aName.split('_');
		if (!aVersion) return 1;
		let aTotal = Version(aVersion);

		let [, bName] = b.Key[0].split('/');
		if (!bName) return -1;
		let [,bVersion] = bName.split('_');
		if (!bVersion) return -1;
		let bTotal = Version(bVersion);
		
		return bTotal - aTotal;
	});
}

async function GetData () {
	let text = await Axios({
		url: 'http://repo.scorchapp.co.uk.s3.amazonaws.com?list-type=2&ContinuationToken=Simpsons&Delimiter=\\n&EncodingType=url',
		headers: {}
	}).then(r => r.data);
	let result = await parser.parseStringPromise(text);
	return result;
}