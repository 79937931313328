import React from 'react';
import { IconBaseProps } from 'react-icons/lib';
import { IconProps } from '../interfaces';

export default function useIcon({ baseColors, hasLoaded }: {
	baseColors?: string[], hasLoaded?: boolean
} & IconBaseProps = {}) {

	if (!baseColors) baseColors = [];

	function Icon(b: IconProps & IconBaseProps) {
		if (hasLoaded === false) return null;

		let color = baseColors[b.container ?? 0];
		if (b.color) color = b.color;

		if (!b.icon) return null;

		if (!React.isValidElement(b.icon)) return (
			<b.icon
				className={b.className}
				style={{
					...b.style,
					color,
				}}
				id={b.icon.name}
			/>
		);

		return React.cloneElement(b.icon, Object.assign({
			className: b.className,
			...b.icon.props,
			style: {
				...b.icon.props.style,
				...b.style,
				color,
			}
		}, !['img', 'svg'].includes(b.icon.type.toString()) ?
			{ id: b.icon.type } :
			undefined
		));
	}

	return { Icon };
}