import React, { ReactElement, RefObject, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import cx from 'classnames';
import Core from './Core';
import Spreadsheet from './components/Spreadsheet';
import ECFGrade from './components/ECFGrade';

import styles from './css/export.module.css';
import Player from 'models/player';
import { TournamentStatus } from '../interfaces';
import FIDEGrade from './components/FIDEGrade';
import { Result } from 'resources/result';
import { GET } from 'utils/requests';
import { px } from 'utils/prototype';

interface ExportProps {
	status: TournamentStatus
	players: Player[]
}

export default function ExportComponent(props: ExportProps): ReactElement {

	const reduceExpanded = useCallback((state: string, action: string) => {
		let value = '';
		let shouldExpand = state !== action;
		if (shouldExpand) value = action;
		return value;
	}, []);

	const [expanded, setExpanded] = useReducer(reduceExpanded, '');

	const [results, setResults] = useState([] as Result[]);
	const updateResults = useCallback(() => !props.status.id ? null : GET({
		url: px('tournament', props.status.id, 'getPairings'),
		params: {
			withResults: true
		}
	}).then(setResults), [props.status.id, setResults]);
	useEffect(() => {
		updateResults();
	}, [updateResults]);

	return (
		<Core className={cx(styles.core, {[styles.sole]: !!expanded})} childClass={styles.wrapper} id='export'>
			<Spreadsheet
				players={props.players}
				className={cx(styles.spreadsheet, {[styles.expanded]: expanded === 'spreadsheet'})}
				expanded={expanded === 'spreadsheet'}
				toggleExpand={() => setExpanded('spreadsheet')}
			/>
			<ECFGrade
				status={props.status}
				players={props.players}
				className={cx(styles.ecf, {[styles.expanded]: expanded === 'ecf'})}
				expanded={expanded === 'ecf'}
				toggleExpand={() => setExpanded('ecf')}
				results={results}
			/>
			<FIDEGrade
				status={props.status}
				players={props.players}
				className={cx(styles.fide, {[styles.expanded]: expanded === 'fide'})}
				expanded={expanded === 'fide'}
				toggleExpand={() => setExpanded('fide')}
				results={results}
			/>
		</Core>
	);
}