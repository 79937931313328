import { useEffect, useState } from 'react';
import { FaWindowMaximize, FaWindowRestore, FaWindowMinimize, FaWindowClose } from 'react-icons/fa';

import styles from './css/statusbar.module.css';
import { listen, send } from './utils/electron';

const Package = require('../package.json');

export default function StatusBar() {

	const initialName = ['ScorchApp', 'v' + Package.version].join(' ');
	
	const [maxed, setMaxed] = useState(false);
	useEffect(() => {
		listen('toggleMax', setMaxed);
	}, [setMaxed]);

	return (
		<div id='statusBar' className={styles.statusBar}>
			<div className={styles.title}>
				{initialName}
			</div>
			<div className={styles.menu}>
				<div className={styles.favicon}>
					<img
						alt='favicon'
						draggable={false}
						src={process.env.PUBLIC_URL + '/logo192.png'}
					/>
				</div>
			</div>
			<div className={styles.exec}>
				<div onClick={() => send('min')}>
					<FaWindowMinimize />
				</div> 
				<div onClick={() => send('toggleMax')}>
					{maxed ? 
						<FaWindowRestore /> :
						<FaWindowMaximize />
					}
				</div> 
				<div onClick={() => send('close')} className={styles.close}>
					<FaWindowClose />
				</div>
			</div>
		</div>
	);
}