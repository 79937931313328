import React, { ReactElement, useMemo, useCallback, useReducer, useEffect } from 'react';
import cx from 'classnames';
import { TournamentStatus, Player, TournamentSettings } from '../interfaces';

import styles from './css/crosstable.module.css';
import { MdClose } from 'react-icons/md';
import { IoIosPerson } from 'react-icons/io';
import Core from './Core';

interface TableProps {
	className?: string
	setDisplayPlayer: (id: string) => void
	status: TournamentStatus
	players: Player[]
	settings: TournamentSettings
	rightTab: boolean
	toggleRightTab: (set?: boolean) => void
	closeTabOnLoad?: boolean
}

interface ColumnData {
	id?: (entry: Player, i: number) => string
	name: string | ((key: string, i: number) => string)
	className: 'blank' | 'player' | 'locked' | 'rating' | 'unset' | 'edit' | ((v: string, i: number, settings: TournamentSettings) => string),
	sort?: false | ((a: any, b: any) => number),
	show?: (entry: Player, i: number, settings: TournamentSettings) => boolean
	value: (entry: Player, i: number, settings: TournamentSettings) => any
	convert?: (v: any, p: Player, s: TournamentSettings) => string
	postConvert?: (v: string, i: number) => string
}

const columns = {
	pre: {
		id: {
			name: '',
			className: 'blank',
			sort: false,
			value: (): number => 0,
			postConvert: (v: string, i: number): string => (1 + i).toString()
		},
		name: {
			name: 'Name',
			className: 'player',
			id: (p: Player): string => p.id,
			sort: (a: string, b: string) => {
				if (a > b) return 1;
				if (a < b) return -1;
				return 0;
			},
			value: (p: Player, i: number, s: TournamentSettings): string => s.competitors === 'individual' ? [p.firstName, p.lastName].join(' ') : p.firstName
		},
		rating: {
			name: 'Rating',
			className: 'locked',
			value: (p: Player): number => p.rating,
			show: (entry: Player, i: number, s: TournamentSettings) => s.showRatings
		}
	},
	roundColumn: {
		name: (key: string, i: number): string => key.slice(-1),
		className: (v: string, i: number, s: TournamentSettings) => {
			if (v === '*') return 'edit';
			if (v === '') return 'unset';
			else return 'locked';
		},
		value: () => {},
		convert: (v: string, p: Player, s: TournamentSettings) => {
			if (v === '') return '';
			else if (v === '*') return '*';
			let nb = parseInt(v);
			let output: string;
			if (nb === 0.5) output = '½';
			else output = nb.toString();
			if (v.endsWith('b')) output += 'b';
			return output;
		},
		sort: (a: string, b: string): number => {
			if (a === b) return 0;
			if (a === '') return -1;
			else if (b === '') return -1;
			if (a === '*') return 1;
			else if (b === '*') return -1;
			let [aN, bN] = [parseInt(a), parseInt(b)];
			if (a.endsWith('b')) aN -= 0.001;
			if (b.endsWith('b')) bN -= 0.001;
			return bN - aN;
		}
	},
	post: {
		score: {
			name: 'MP',
			className: 'locked',
			value: (p: Player) => parseFloat(p.score.toString()),
			convert: (v: number, p: Player): string => v.toFixed(1) + ' / ' + p.played
		},
		gamePoints: {
			name: 'GP',
			className: 'locked',
			value: (p: Player) => p.gamePoints,
			convert: (v: number): string => v.toFixed(1)
		},
		winPercentage: {
			name: 'Win %',
			className: 'rating',
			value: (p: Player): number => p.played === 0 ? -1 : p.score * 100 / p.played,
			convert: (v: number): string => {
				if (v < 0) return '-';
				return v.toFixed();
			}
		},
		performanceRating: {
			name: 'Perf',
			className: 'rating',
			value: (p: Player) => p.performanceRating,
			convert: (v: number): string => v.toFixed(),
			show: (entry: Player, i: number, s: TournamentSettings) => s.showRatings
		},
		ratingDifference: {
			name: 'P. +/-',
			className: 'rating',
			value: (p: Player): number => {
				if (!p.performanceRating) return 0;
				return p.performanceRating - p.rating;
			},
			convert: (v: number): string => {
				if (!v) return '';
				if (v > 0) return '+' + v.toString();
				else return v.toString();
			},
			show: (entry: Player, i: number, s: TournamentSettings) => s.showRatings
		},
		expectedScore: {
			name: 'Expected',
			className: 'rating',
			value: (p: Player): number => p.expectedScore,
			convert: (v: number, p: Player): string => v.toFixed(1) + ' / ' + p.played,
			show: (entry: Player, i: number, s: TournamentSettings) => s.showRatings
		},
		expectedDifference: {
			name: 'E. +/-',
			className: 'rating',
			value: (p: Player): number => p.score - p.expectedScore,
			convert: (v: number): string => {
				if (typeof v === 'undefined') return '';
				if (v > 0) return '+' + v.toFixed(2);
				else return v.toFixed(2);
			},
			show: (entry: Player, i: number, s: TournamentSettings) => s.showRatings
		},
	}
} as {
	pre: {
		[key: string]: ColumnData
	},
	roundColumn: ColumnData
	post: {
		[key: string]: ColumnData
	}

};

export default function CrossTable(props: TableProps): ReactElement {

	const totalRounds = useMemo((): number => {
		let totalRounds: number;
		if (props.status.round > props.settings.totalRounds) totalRounds = props.status.round;
		else if (props.settings.totalRounds === 'Infinity') totalRounds = props.status.round;
		else totalRounds = props.settings.totalRounds;
		return totalRounds;
	}, [props.status.round, props.settings.totalRounds]);

	const roundHeaders = useMemo(() => {
		let h = [] as string[];
		for (let i = 1; i <= totalRounds; i++) {
			h.push('round.' + i.toString());
		}
		return h;
	}, [totalRounds]);

	const headers: string[] = useMemo(() => [...Object.keys(columns.pre), ...roundHeaders, ...Object.keys(columns.post)], [roundHeaders])
		.filter((key, i) => {
			let meta = columns.pre[key] || columns.post[key];
			if (!meta) return true;
			if (meta.show && !meta.show(null as Player, i, props.settings)) return false;
			return true;
		});

	const setSortHeader = useCallback((state: [string, number], action: string): [string, number] => {
		if (!headers.includes(action)) return ['', 0];
		if (state[0] !== action) return [action, 0];
		else return [action, state[1] + 1];
	}, [headers]);
	const [sort, sortBy] = useReducer(setSortHeader, ['score', 0]);

	const renderRounds = useCallback((p: Player) => {
		let scores = [''] as string[];
		for (let i = 1; i <= totalRounds; i++) {
			if (!p.histories[i]) {
				if (i === props.status.round) scores[i] = '*';
				else scores[i] = '';
				continue;
			}
			if (p.histories[i].match === null) scores[i] = '*';
			else scores[i] = p.histories[i].match.toString();
			if (p.histories[i].id === 'bye') scores[i] += 'b';
		}
		return scores.slice(1);
	}, [totalRounds, props.status.round]);	

	const renderRows: JSX.Element[] = useMemo(() => {
		let rowsData = [] as [number, ColumnData, Player][][];
		for (let i = 0; i < props.players.length; i++) {
			let p = props.players[i];
			let pres = [] as [number, ColumnData, Player][];
			for (let [k, meta] of Object.entries(columns.pre)) {
				if (meta.show && !meta.show(p, i, props.settings)) continue;
				pres.push([meta.value(p, i, props.settings), meta, p]);
			}
			let rounds = renderRounds(p).map(entry => [entry as any as number, columns.roundColumn, p]) as [number, ColumnData, Player][];
			let post = [] as [number, ColumnData, Player][];
			for (let [k, meta] of Object.entries(columns.post)) {
				if (meta.show && !meta.show(p, i, props.settings)) continue;
				post.push([meta.value(p, i, props.settings), meta, p]);
			}
			let rowData = [...pres, ...rounds, ...post];
			rowsData.push(rowData);
		}

		let sortFunc = (a: number, b: number): number => b - a;
		let sortMeta = sort[0].startsWith('round') ? columns.roundColumn : (columns.pre[sort[0]] || columns.post[sort[0]]);
		if (sortMeta && sortMeta.sort !== false) {
			if (sortMeta.sort) sortFunc = sortMeta.sort;
			let sortCol = headers.indexOf(sort[0]);
			rowsData = rowsData.sort((a, b) => sort[1] % 2 ? sortFunc(b[sortCol][0], a[sortCol][0]) : sortFunc(a[sortCol][0], b[sortCol][0]));
		}

		let rows = rowsData.map((rowData, i) => {
			return (
				<tr key={'row.' + i} className={styles.tr}>
					{rowData.map(([value, meta, p]: [number, ColumnData, Player], j: number) => {
						let display = value ? value.toString() : '';
						if (meta.convert) display = meta.convert(value, p, props.settings);
						else if (meta.postConvert) display = meta.postConvert(display, i);
						if (typeof display === 'undefined' || display === '') display = '\u200b';
						let className = typeof meta.className === 'function' ? meta.className(display, j, props.settings) : meta.className;
						return (
							<th key={[value, j].join('.')}>
								<div
									id={meta.id ? meta.id(p, j) : undefined}									
									className={[styles.cell, styles[className]].join(' ')}
									onClick={meta.name === 'Name' && meta.id ? () => {
										props.setDisplayPlayer(meta.id(p, j));
										props.toggleRightTab(true);
									} : null}
								>
									{display}
								</div>
							</th>
						);
					})}
				</tr>
			);
		});
		return rows;
	}, [props.players, props.setDisplayPlayer, props.toggleRightTab, props.settings, renderRounds, sort]);

	useEffect(() => {
		if (!props.closeTabOnLoad) return;
		props.toggleRightTab(false);
	}, [props.closeTabOnLoad, props.toggleRightTab]);
		
	return (
		<Core id='crossTable' title={`Tournament '${props.status.name || props.status.id}' results after ${props.status.round} rounds`}
			short={props.rightTab}
			className={cx(styles.gridPage, props.className, 'scrollable', 'scrollable-x')}
			childClass={styles.wrapper}
			buttons={[
				props.rightTab ?
					{
						name: 'Close profile view',
						icon: <MdClose />,
						color: 'indianred',
						onClick: props.toggleRightTab
					} :
					{
						name: 'Show profile view',
						icon: <IoIosPerson />,
						color: '#385898',
						onClick: props.toggleRightTab
					}
			]}
		>
			<table className={styles.table}>
				<thead>
					<tr>
						{headers.map((key, i) => {
							let meta = key.startsWith('round') ? columns.roundColumn : (columns.pre[key] || columns.post[key]);
							return <th
								scope='column'
								key={['column', i].join('.')}
								className={styles.th}
								id={key}
								onClick={() => sortBy(key)}
							>
								<div className={[styles.cell, styles.headerCell].join(' ')}>
									{typeof meta.name === 'function' ? meta.name(key, i) : meta.name}
								</div>
							</th>;
						})}
					</tr>
				</thead>
				<tbody>						
					{renderRows}
				</tbody>
			</table>
		</Core>
	);
}