import React, { ReactElement, RefObject, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import Core from '../Core';

import styles from 'screen/css/export.module.css';
import Player from 'models/player';
import { FaCompressArrowsAlt, FaCopy, FaExpandArrowsAlt } from 'react-icons/fa';
import { TournamentStatus, Result } from '../../interfaces';
import { SettingsContext } from '../../Contexts';
import * as regexes from 'utils/regexes';
import { Package } from 'utils/electron';
import { pascalise } from 'utils/prototype';

interface ECFProps {
	players: Player[]
	status: TournamentStatus
	className?: string
	expanded?: boolean
	toggleExpand?: () => void
	results: Result[]
}

export default function ECFGrade(props: ECFProps): ReactElement {

	const settings = useContext(SettingsContext);
	const details = useMemo(() => {
		return formatObj({
			eventDetails: null,
			eventCode: '',
			eventName: props.status.name?.replace(regexes.stripPunctuation, ''),
			eventDate: convertFromDate(new Date(props.status.createdAt)),
			finalResultsDate: '', //TODO',
			resultsOfficer: '', //TODO',
			resultsOfficerAddress: '',
			treasurerOfficer: '', //TODO',
			treasurerOfficerAddress: '',
			format: pascalise(settings.pairingSystem),
			software: `ScorchChessv${Package.version}`,
			submissionIndex: '01',
			minutesPerGame: 0, //TODO
			secondsPerMove: 0 //TODO
		});
	}, [props.status, settings]);

	const ids = useMemo(() => props.players.map(p => p.id), [props.players]);
	const players = useMemo(() => {
		let headers = {
			pin: p => (ids.indexOf(p.id) + 1).toString(),
			ECFCode: p => p.ecf ?? '' as string,
			name: p => [p.lastName, p.firstName].join(', '),
			gender: p => ({M: 'Male', F: 'Female', O: 'Other' } as {[key: string]: string})[p.gender],
			dateOfBirth: p => p.bYear?.toString() ?? '',
			fideNo: () => '' // TODO
		} as {[key: string]: ((p: Player) => string)};
		return [
			'#PlayerList',
			...props.players.map(p => '' + Object.entries(headers).map(([k, v]) => {
				return `#${pascalise(k)}=${(v(p) || '')}`;
			}).join('')),
		].join('\n');
	}, [ids, props.players]);

	const { results } = props;
	const poolDistribution = results.reduce((acc, curr) => {
		let pool = curr.pool || 0;
		if (!acc[pool]) acc[pool] = 0;
		acc[pool]++;
		return acc;
	}, [] as number[]);
	const resultSection = useMemo(() => {
		let arr = [] as string[];
		for (let poolString of Object.keys(poolDistribution)) {
			let pool = parseInt(poolString);
			let headers = {
				pin1: r => ids.indexOf(r.idW) + 1,
				pin2: r => ids.indexOf(r.idB) + 1,
				score: r => convertToScore(r.matchW, r.matchB),
				round: r => r.round.toString(),
				colour: r => 'W', //Raise query
				gameDate: r => convertFromDate(new Date(r.finishedAt))
			} as {[key: string]: ((r: Result) => string | number)};
			arr.push([
				`#Pool=${pool || 'Open'}`,
				...results.map(p => '' + Object.entries(headers).map(([k, v]) => {
					return `#${pascalise(k)}=${(v(p) || '')}`;
				}).join('')),
			].join('\n'));
		}
		return arr.join('\n');
	}, [ids, results]);

	const ref = useRef() as RefObject<HTMLTextAreaElement>;

	const [value, setValue] = useState('');
	const updateValueFromComponents = useCallback(() => {
		setValue([
			details,
			players,
			resultSection,
			'#Finish#'
		].join('\n'));
	}, [setValue, details, players, resultSection]);
	useEffect(() => {
		updateValueFromComponents();
	}, [updateValueFromComponents]);
	const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setValue(e.target.value);
	}, [setValue]);

	return (
		<Core id='export' isChild className={props.className}
			title='ECF grading output'
			buttons={[
				{
					name: props.expanded ? 'Contract' : 'Expand',
					icon: props.expanded ? FaCompressArrowsAlt : FaExpandArrowsAlt,
					onClick: props.toggleExpand,
					hide: !props.toggleExpand
				},
				{
					name: 'Copy',
					icon: FaCopy,
					onClick: () => {
						if (!ref.current) return;
						ref.current.select();
						document.execCommand('copy');
					},
					hide: !props.players.length || !results.length
				}
			]}
		>
			<div className={styles.container}>
				<textarea ref={ref} className={styles.textarea + ' scrollable'} value={value} spellCheck={false} onChange={handleChange} />
			</div>
		</Core>
	);
}

function formatObj(obj: [string, string][] | {[key: string]: string | number}, isKey?: boolean) {
	let entries = [] as [string, string][];
	if (!Array.isArray(obj)) {
		entries = Object.entries(obj).map(([k, v]) => {
			return [pascalise(k), typeof v !== 'object' && v !== undefined ? v.toString() : v as any];
		});
	} else {
		entries = obj;
	}
	
	let output = '';
	for (let [k, value] of entries) {
		let v = isKey ? pascalise(value) : value;
		if (typeof v !== 'string') output += '#' + k + '\n';
		else output += `#${k}=${v || '?'}\n`;
	}
	return output.trim();
}

function convertFromDate(v: Date): string {
	if (!v) return '';
	if (isNaN(v.valueOf())) return '';
	return [v.getUTCDate(), v.getUTCMonth(), v.getUTCFullYear()].map(n => {
		let s = n.toString();
		return '0'.repeat(2 - Math.min(2, s.length)) + s;
	}).join('/');
}

function convertToScore(matchW: number, matchB: number): string {
	if (matchW === 1) return '10';
	if (matchB === 1) return '01';
	return '55';
}