import React, { ReactElement, FormEvent, useState, useCallback, useMemo, useEffect, useContext, useReducer } from 'react';
import cx from 'classnames';
import CSV from 'papaparse';
import { Player, ID } from '../../interfaces';
import { POST, server, FORM} from 'utils/requests';

import styles from 'css/sidebar.module.css';
import TabSection from 'components/TabSection';
import { EditableForm } from '.';
import Search from 'components/Search';
import { LoadedContext, SettingsContext } from '../../Contexts';
import { chessComColoured, ecf, fide, lichess as lichess_logo } from 'assets/icons';
import { FaPlusSquare } from 'react-icons/fa';
import url from 'url';
import useSearch, { sources } from './hooks/useSearch';

interface SidebarProps {
	id: string
	playerDict: Map<ID, Player>
	updatePlayerDict: (force?: boolean) => void
	stateKey: string
}

export default function NewPlayer(props: SidebarProps & {
	setPlayers: (refresh?: boolean) => Promise<void>
}): ReactElement {
	
	const hasLoaded = useContext(LoadedContext);
	const settings = useContext(SettingsContext);
	const requireLastName = useMemo(() => settings.competitors === 'individual', [settings.competitors]);

	/* RENDERING SECTION */

	const handlePaste = useCallback((e: React.ClipboardEvent<HTMLInputElement>) => {
		let text = e.clipboardData.getData('text').toString();
		if (!text.trim()) return;
		if (!text.split('\n').length && text.split(',').length && text.split('\t').length) return;
		e.preventDefault();
		let json = CSV.parse(text, {
			delimitersToGuess: [',', '\t'],
			dynamicTyping: true,
			fastMode: true
		} as CSV.ParseConfig) as CSV.ParseResult<(string | number)[]>;
		if (json.data.length > 100) return console.error('Can\'t paste more than 100 players at once!');
		let data = json.data.map((row) => {
			let [firstName, lastName, rating] = row as [string, string, number];
			if (!requireLastName) [firstName, rating, lastName] = [...row, ''] as [string, number, string];
			if (!firstName || (!lastName && requireLastName) || rating === null || rating === undefined) return null;
			try {
				if (typeof firstName !== 'string') throw [firstName, 'string'].join(',');
				if (typeof lastName !== 'string' && requireLastName) throw [lastName, 'string'].join(',');
				if (typeof rating !== 'number') throw [rating, 'number'].join(',');
				return {firstName, lastName, rating};
			} catch (error) {
				if (!text.split('\n').length) return null;
				let e = error.split(',');
				console.error(new TypeError('Bad input on row ' + row.join(',') + '\n' + e[0] + ' is not type ' + e[1]));
				return null;
			}
		})
			.filter(r => r);
		POST({
			url: 'tournament/' + props.id + '/appendPlayers',
			data
		})
			.then(() => Promise.all([props.updatePlayerDict(), props.setPlayers()]));
	}, [props.id, requireLastName, props.updatePlayerDict, props.setPlayers]);

	const [legitForms, setLegitForms] = useReducer((state: string[][], action: { values: string[], i: number }) => {
		let x = state.slice(0);
		x.splice(action.i, 1, action.values);
		return x;
	}, []);
	const [shouldForms, setShouldForms] = useState(1);
	useEffect(() => {
		let x = () => setShouldForms(legitForms.filter(arr => arr.some(v => v)).length + 1);
		let y = setTimeout(x, 1000);
		return () => clearTimeout(y);
	}, [legitForms]);
	
	const Forms = useMemo((): JSX.Element[] => {

		let forms = [] as JSX.Element[];
		
		for (let i = 0; i < shouldForms; i++) {
			forms.push(
				<EditableForm
					key={['newPlayerForm', i].join('.')}
					id={props.id}
					i={!hasLoaded || props.playerDict.size ? i + 1 : i}
					player={null}
					settings={settings}
					close={() => {}}
					handleInput={(values) => setLegitForms({ values, i })}
					onSubmit={(e: FormEvent<HTMLFormElement>): Promise<void> => FORM(e)
						.then(() => {
							let form = e.target as HTMLFormElement;
							if (!form.reset) {
								while (form.parentElement && !form.reset) {
									form = form.parentElement as HTMLFormElement;
								}
							}
							if (form.reset) form.reset();
							let input = form.firstName as HTMLInputElement;
							if (input.focus) input.focus();
						})
						.then(() => props.updatePlayerDict())
					}
					handlePaste={i ? undefined : handlePaste}
					action={url.resolve(server, ['tournament', props.id, 'appendPlayer'].join('/'))}
				/>
			);
		}
		return forms;
	}, [shouldForms, setLegitForms, props.updatePlayerDict, props.playerDict, hasLoaded, props.id, requireLastName, handlePaste, settings.checkForDuplicatePlayers]);

	const [mode, setMode] = useState('new' as keyof typeof sources | 'new');

	const { handleInput, parseUser, handleSubmit, placeholder } = useSearch({ id: props.id, source: mode });

	const tab = useMemo(() => {
		return (
			<TabSection
				name={mode}
				setName={setMode as (v: string) => void}
				nameDict={{
					new: {
						icon: FaPlusSquare
					},
					ecf: {
						name: 'ECF',
						icon: <img id='ecf' src={ecf} alt='' />,
						hide: settings.competitors !== 'individual'
					},
					fide: {
						name: 'FIDE',
						icon: <img id='fide' src={fide} alt='' />,
						hide: settings.competitors !== 'individual'
					},
					lichess: {
						name: 'Lichess',
						icon: <img id='lichess' src={lichess_logo} alt='' />,
						hide: settings.competitors !== 'individual' && settings.media !== 'online'
					},
					chessCom: {
						name: 'Chess.Com',
						icon: <img id='chessCom' src={chessComColoured} alt='' />,
						hide: settings.competitors !== 'individual' && settings.media !== 'online'
					}
				}}
			/>
		);
	}, [settings, mode, setMode]);

	return (
		<>	
			<div className={cx(styles.sidebar, styles.newPlayers)}>	
				<div className={styles.stage}>
					{tab}
					{mode === 'new' ?
						<div className={cx('scrollable', styles.playersSection, { [styles.skel]: !hasLoaded })}>
							<div className={styles.scoreSection}>
								{Forms}
							</div>
						</div> :
						<Search
							onInput={handleInput}
							onSubmit={(id: string) => handleSubmit(id).then(props.updatePlayerDict)}
							getName={parseUser as any}
							showHistory={false}
							styles={styles}
							placeholder={placeholder}
							limit={5}
							className={styles.infoBox}
						/>
					}		
				</div>
			</div>
		</>
	);
}